/*=======================================================
			Page Loader
========================================================*/
#loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(255,255,255,1);
	z-index: 999999;
	opacity: 1;
	text-align: center;
}
.hom-slie {
	position: absolute !important;
}
#preloader6 {
	position: relative;
	width: 42px;
	height: 42px;
	animation: preloader_6 5s infinite linear;
}
#preloader6 span {
	width: 20px;
	height: 20px;
	position: absolute;
	background: red;
	display: block;
	animation: preloader_6_span 1s infinite linear;
}
#preloader6 span:nth-child(1) {
	background: #5a65d1;
}
#preloader6 span:nth-child(2) {
	left: 22px;
	background: #5a65d1;
	animation-delay: .2s;
}
#preloader6 span:nth-child(3) {
	top: 22px;
	background: #5a65d1;
	animation-delay: .4s;
}
#preloader6 span:nth-child(4) {
	top: 22px;
	left: 22px;
	background: #5a65d1;
	animation-delay: .6s;
}
@keyframes preloader_6_span {
 0% {
transform:scale(1);
}
 50% {
transform:scale(0.5);
}
 100% {
transform:scale(1);
}
}
.ownmenu {
	padding: 0;
	margin: 0;
	list-style: none;
}
.ownmenu li {
	display: inline-block;
	float: left;
}
.ownmenu a {
	-o-transition: all .3s linear;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
	outline: none;
	z-index: 10;
}
.ownmenu li {
}
.ownmenu ul.dropdown, .ownmenu ul.dropdown li ul.dropdown {
	list-style: none;
	margin: 0;
	padding: 0;
	display: none;
	position: absolute;
	z-index: 99;
	padding-left: 0px;
	width: 250px;
	background: none;
}
.ownmenu ul.dropdown li ul.dropdown {
	left: 100%;
	top: inherit;
	background: #272727;
	border-radius: 0px;
}
.ownmenu ul.dropdown li ul.dropdown:before {
	display: none;
}
header nav .ownmenu ul.dropdown .indicator {
	display: block;
	left: 0px;
	margin-left: 0px;
}
.ownmenu ul.dropdown li {
	clear: both;
	width: 100%;
	text-align: left;
}
.ownmenu ul.dropdown li a {
	width: 100%;
	padding: 12px 24px 12px;
	display: inline-block;
	float: left;
	clear: both;
	text-decoration: none;
	color: #999;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.ownmenu ul.dropdown li:hover > a {
	color: #fff;
	background: #1193d4;
}
.ownmenu ul.dropdown li ul.dropdown.left {
	left: auto;
	right: 100%;
}
.ownmenu .indicator {
	position: relative;
	right: 5px;
	font-size: 14px;
	float: right;
}
.ownmenu ul li .indicator {
	font-size: 12px;
	top: 1px;
	left: 10px;
}
.ownmenu ul.dropdown {
	top: 76px;
}
.ownmenu > li > .megamenu {
	top: 54px;
}
.ownmenu > li > .megamenu {
	position: absolute;
	display: none;
	background: #fff;
	width: 25%;
	font-size: 12px;
	color: #999;
	z-index: 99;
	padding: 0 0px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.ownmenu li > .megamenu ul {
	margin: 0px;
	float: left;
	padding: 0;
	display: block;
	position: relative;
	width: 100%;
}
.ownmenu li > .megamenu ul li {
	width: 100%;
	padding: 0px;
}
.ownmenu li > .megamenu ul li.title {
	margin: 0 0 8px;
	padding: 0 0 5px;
	font-size: 14px;
	border-bottom: solid 1px #666;
}
.ownmenu li > .megamenu h5 {
	width: 100%;
	margin: 0;
	padding: 0;
}
.ownmenu li > .megamenu a {
	color: #999;
	text-decoration: none;
	font-size: 12px;
	-webkit-transition: color 0.3s linear;
	-moz-transition: color 0.3s linear;
	-o-transition: color 0.3s linear;
	transition: color 0.3s linear;
}
.ownmenu li > .megamenu a:hover {
	color: #dedede;
}
.ownmenu .megamenu img {
	width: 100%;
	-webkit-transition: border 0.3s linear;
	-moz-transition: border 0.3s linear;
	-o-transition: border 0.3s linear;
	transition: border 0.3s linear;
}
.ownmenu > li.fix-sub > .megamenu, .ownmenu > li.fix-sub > .megamenu.half-width, .ownmenu > li.fix-sub > .dropdown {
}
.ownmenu > li > .megamenu.half-width {
	width: 50%;
}
.ownmenu > li > .megamenu.full-width {
	width: 100%;
	left: 0;
	text-align: left;
}
.ownmenu li > .megamenu form {
	width: 100%;
}
.ownmenu li > .megamenu form input[type="text"], .ownmenu li > .megamenu form textarea {
	padding: 5px;
	color: #999;
	background: #444;
	font-size: 14px;
	border: solid 1px transparent;
	outline: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: border 0.3s linear;
	-moz-transition: border 0.3s linear;
	-o-transition: border 0.3s linear;
	transition: border 0.3s linear;
}
.ownmenu li > .megamenu form input[type="text"]:focus, .ownmenu li > .megamenu form textarea:focus {
	border-color: #e0e0e0;
}
.ownmenu li > .megamenu form input[type="text"] {
	width: 100%;
	margin-top: 10px;
}
.ownmenu li > .megamenu form textarea {
	width: 100%;
	margin-top: 10px;
}
.ownmenu li > .megamenu form input[type="submit"] {
	width: 25%;
	float: right;
	height: 30px;
	margin-top: 10px;
	border: none;
	cursor: pointer;
	background: #444;
	color: #777;
	-webkit-transition: background 0.3s linear;
	-moz-transition: background 0.3s linear;
	-o-transition: background 0.3s linear;
	transition: background 0.3s linear;
}
.ownmenu li > .megamenu form input[type="submit"]:hover {
	background: #e0e0e0;
	color: #555;
}
.megamenu .row {
}
.ownmenu > li.right {
	float: right;
}
.ownmenu > li.right > .megamenu, .ownmenu > li.right > .megamenu.half-width, .ownmenu > li.right > .dropdown {
	right: 0;
}
.ownmenu > li.jsright {
	float: right;
}
.ownmenu > li.jsright.last > .megamenu, .ownmenu > li.jsright.last > .megamenu.half-width, .ownmenu > li.jsright.last > .dropdown {
	right: 0;
}
.ownmenu > li > a > i {
	line-height: 23px !important;
	margin-right: 6px;
	font-size: 18px;
	float: left;
}
.ownmenu > li.showhide {
	display: none;
	width: 100%;
	height: 50px;
	cursor: pointer;
	color: #999;
}
.ownmenu > li.showhide span.title {
	margin: 15px 0 0 25px;
	float: left;
}
.ownmenu > li.showhide span.icon {
	margin: 17px 20px;
	float: right;
}
.ownmenu > li.showhide .icon em {
	margin-bottom: 3px;
	display: block;
	width: 20px;
	height: 2px;
	background: #999;
}
@media (max-width:767px) {
.ownmenu > li {
	display: block;
	width: 100%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.ownmenu > li > a {
	padding: 15px 25px;
}
.ownmenu a {
	width: 100%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.ownmenu ul.dropdown, .ownmenu ul.dropdown li ul.dropdown {
	width: 100% !important;
	left: 0;
	position: static !important;
	border: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	float: left;
}
.ownmenu ul.dropdown li {
	border: none;
}
.ownmenu ul.dropdown > li > a {
	padding-left: 40px !important;
}
.ownmenu > li > .megamenu {
	width: 100% !important;
	position: static;
	border-top: none;
}
.ownmenu > li > ul.dropdown > li > ul.dropdown > li > a {
	padding-left: 60px !important;
}
.ownmenu > li > ul.dropdown > li > ul.dropdown > li > ul.dropdown > li > a {
	padding-left: 80px !important;
}
}
/* Colio - Black Theme
================================================== */
.colio-theme-black .colio-close span {
	display: inline-block;
	width: 24px;
	height: 24px;
	text-indent: -9999px;
}
.colio-theme-black .colio-close {
	position: absolute;
	top: 30px;
	right: 30px;
}
.colio-theme-black .colio-close:before {
	content: "x";
	position: absolute;
	color: #fff;
	height: 34px;
	width: 34px;
	display: inline-block;
	background: #1193d4;
	font-style: normal;
	text-align: center;
	line-height: 32px;
}
.colio-theme-black .colio-close:hover {
	opacity: 0.8;
}
/* colio navigation */

.colio-theme-black .colio-navigation span {
	display: inline-block;
	width: 24px;
	height: 24px;
	text-indent: -9999px;
}
.colio-theme-black .colio-navigation {
	position: absolute;
	top: 60px;
	left: 60px;
}
.colio-theme-black .colio-navigation a {
	float: left;
	padding: 5px;
	margin-right: 5px;
	background: #1193d4;
	-webkit-transition: background .1s;
	-moz-transition: background .1s;
	-o-transition: background .1s;
	transition: background .1s;
}
.colio-theme-black .colio-navigation a:hover {
	background: #555;
}
.colio-theme-black .colio-prev span {
	background: url(../../../assets/images/controls.png) no-repeat 0 0;
}
.colio-theme-black .colio-next span {
	background: url(../../../assets/images/controls.png) no-repeat -24px 0;
}
.colio-theme-black .colio-no-prev, .colio-theme-black .colio-no-next {
	background: #555!important;
}
.colio-theme-black {
	color: #999;
}
.colio-theme-black h3 {
	padding: 0;
	margin: 0 0 35px 100px;
	color: #ccc;
}
.colio-theme-black a, .colio-theme-black a:visited {
}
.colio-theme-black a:hover {
}
.colio-theme-black a.visit-link {
	display: inline-block;
	padding: 5px 15px;
	border: 2px solid #ccc;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: bold;
	font-style: normal;
	line-height: 21px;
	font-size: 12px;
	letter-spacing: 0.05px;
}
/* photo feed */

.colio-theme-black .feed {
	margin: 0;
	list-style: none;
}
.colio-theme-black .feed li {
	float: left;
	margin: 0 5px 5px 0;
	width: 130px;
	height: 130px;
	overflow: hidden;
	background: #ff5452 url(images/plus.png) no-repeat center;
}
.colio-theme-black .feed img {
	height: 100%;
	width: auto;
	vertical-align: top;
	-webkit-transition: opacity .2s;
	-moz-transition: opacity .2s;
	-o-transition: opacity .2s;
	transition: opacity .2s;
}
.colio-theme-black .feed li:hover img {
	opacity: 0.2;
}
/* social icons */

.colio-theme-black .social {
	width: 100%;
	margin-bottom: 21px;
	overflow: hidden;
}
.colio-theme-black .social li {
	float: left;
	margin: 0 7px 0 0;
}
.colio-theme-black .social a {
	display: inline-block;
	width: 24px;
	height: 24px;
	text-indent: -9999px;
	opacity: 0.8;
	border-radius: 12px;
	-moz-border-radius: 12px;
	background-size: 24px 24px !important;
}
.colio-theme-black .social a:hover {
	opacity: 1;
	-webkit-transition: opacity .2s;
	-moz-transition: opacity .2s;
	-o-transition: opacity .2s;
	transition: opacity .2s;
}
.colio-theme-black .social .delicious {
	background: url(images/social/delicious.png) no-repeat center;
}
.colio-theme-black .social .digg {
	background: url(images/social/digg.png) no-repeat center;
}
.colio-theme-black .social .dribble {
	background: url(images/social/dribble.png) no-repeat center;
}
.colio-theme-black .social .ember {
	background: url(images/social/ember.png) no-repeat center;
}
.colio-theme-black .social .facebook {
	background: url(images/social/facebook.png) no-repeat center;
}
.colio-theme-black .social .flickr {
	background: url(images/social/flickr.png) no-repeat center;
}
.colio-theme-black .social .forrst {
	background: url(images/social/forrst.png) no-repeat center;
}
.colio-theme-black .social .google {
	background: url(images/social/google.png) no-repeat center;
}
.colio-theme-black .social .last_fm {
	background: url(images/social/last_fm.png) no-repeat center;
}
.colio-theme-black .social .linkedin {
	background: url(images/social/linkedin.png) no-repeat center;
}
.colio-theme-black .social .my_space {
	background: url(images/social/my_space.png) no-repeat center;
}
.colio-theme-black .social .quora {
	background: url(images/social/quora.png) no-repeat center;
}
.colio-theme-black .social .rss {
	background: url(images/social/rss.png) no-repeat center;
}
.colio-theme-black .social .sharethis {
	background: url(images/social/sharethis.png) no-repeat center;
}
.colio-theme-black .social .skype {
	background: url(images/social/skype.png) no-repeat center;
}
.colio-theme-black .social .stumbleupon {
	background: url(images/social/stumbleupon.png) no-repeat center;
}
.colio-theme-black .social .tumblr {
	background: url(images/social/tumblr.png) no-repeat center;
}
.colio-theme-black .social .twitter {
	background: url(images/social/twitter.png) no-repeat center;
}
.colio-theme-black .social .vimeo {
	background: url(images/social/vimeo.png) no-repeat center;
}
.colio-theme-black .social .youtube {
	background: url(images/social/you_tube.png) no-repeat center;
}


/* Retina
================================================== */

/*@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
.colio-theme-black .colio-prev span {
	background: url(images/controls@2x.png) no-repeat 0 0;
	background-size: 72px 24px;
}
.colio-theme-black .colio-next span {
	background: url(images/controls@2x.png) no-repeat -24px 0;
	background-size: 72px 24px;
}
.colio-theme-black .colio-close span {
	background: url(images/controls@2x.png) no-repeat -48px 0;
	background-size: 72px 24px;
}
}*/


/* Media queries
================================================== */

/* Smaller than standard 960 (devices and browsers) */
@media only screen and (max-width: 959px) {
}

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
.colio-theme-black .main, .colio-theme-black .side {
	clear: both;
	width: 100%;
	padding: 0;
	margin-bottom: 40px;
}
.colio-theme-black .side {
	margin-bottom: 0;
}
.colio-theme-black .colio-navigation {
	left: 40px;
	top: 40px;
}
.colio-theme-black .main h3 {
	float: none;
	margin: 60px 0 20px;
	text-align: left;
}
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {
.colio-theme-black .colio-container {
	padding: 30px;
}
.colio-theme-black .colio-navigation {
	left: 30px;
	top: 30px;
}
.colio-theme-black .feed li {
	width: 115px;
	height: 115px;
}
}
.checkbox {
}
.checkbox label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 25px;
	line-height: 19px;
}
.checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #272727;
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	-o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	left: 0;
	top: 0;
	margin-left: 0px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: #555555;
	line-height: 15px;
}
.checkbox input[type="checkbox"], .checkbox input[type="radio"] {
	opacity: 0;
	z-index: 1;
}
.checkbox input[type="checkbox"]:focus + label::before, .checkbox input[type="radio"]:focus + label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.checkbox input[type="checkbox"]:checked + label::after, .checkbox input[type="radio"]:checked + label::after {
	font-family: "FontAwesome";
	content: "\f00c";
}
.checkbox input[type="checkbox"]:disabled + label, .checkbox input[type="radio"]:disabled + label {
	opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before, .checkbox input[type="radio"]:disabled + label::before {
	background-color: #eeeeee;
	cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
	border-radius: 50%;
}
.checkbox.checkbox-inline {
	margin-top: 0;
}
.checkbox-primary input[type="checkbox"]:checked + label::before, .checkbox-primary input[type="radio"]:checked + label::before {
	background-color: #337ab7;
	border-color: #337ab7;
}
.checkbox-primary input[type="checkbox"]:checked + label::after, .checkbox-primary input[type="radio"]:checked + label::after {
	color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before, .checkbox-danger input[type="radio"]:checked + label::before {
	background-color: #d9534f;
	border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after, .checkbox-danger input[type="radio"]:checked + label::after {
	color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before, .checkbox-info input[type="radio"]:checked + label::before {
	background-color: #5bc0de;
	border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after, .checkbox-info input[type="radio"]:checked + label::after {
	color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before, .checkbox-warning input[type="radio"]:checked + label::before {
	background-color: #f0ad4e;
	border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:checked + label::after, .checkbox-warning input[type="radio"]:checked + label::after {
	color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::before, .checkbox-success input[type="radio"]:checked + label::before {
	background-color: #5cb85c;
	border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:checked + label::after, .checkbox-success input[type="radio"]:checked + label::after {
	color: #fff;
}
.radio {
	padding-left: 20px;
}
.radio label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px;
}
.radio label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #cccccc;
	border-radius: 50%;
	background-color: #fff;
	-webkit-transition: border 0.15s ease-in-out;
	-o-transition: border 0.15s ease-in-out;
	transition: border 0.15s ease-in-out;
}
.radio label::after {
	display: inline-block;
	position: absolute;
	content: " ";
	width: 11px;
	height: 11px;
	left: 3px;
	top: 3px;
	margin-left: -20px;
	border-radius: 50%;
	background-color: #555555;
	-webkit-transform: scale(0, 0);
	-ms-transform: scale(0, 0);
	-o-transform: scale(0, 0);
	transform: scale(0, 0);
	-webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
	opacity: 0;
	z-index: 1;
}
.radio input[type="radio"]:focus + label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label {
	opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
	cursor: not-allowed;
}
.radio.radio-inline {
	margin-top: 0;
}
.radio-primary input[type="radio"] + label::after {
	background-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::before {
	border-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::after {
	background-color: #337ab7;
}
.radio-danger input[type="radio"] + label::after {
	background-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::before {
	border-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::after {
	background-color: #d9534f;
}
.radio-info input[type="radio"] + label::after {
	background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
	border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
	background-color: #5bc0de;
}
.radio-warning input[type="radio"] + label::after {
	background-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::before {
	border-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::after {
	background-color: #f0ad4e;
}
.radio-success input[type="radio"] + label::after {
	background-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::before {
	border-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::after {
	background-color: #5cb85c;
}
input[type="checkbox"].styled:checked + label:after, input[type="radio"].styled:checked + label:after {
	font-family: 'FontAwesome';
	content: "\f00c";
}
input[type="checkbox"] .styled:checked + label::before, input[type="radio"] .styled:checked + label::before {
	color: #fff;
}
input[type="checkbox"] .styled:checked + label::after, input[type="radio"] .styled:checked + label::after {
	color: #fff;
}
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
	z-index: 0;
}
.owl-carousel .owl-animated-out {
	z-index: 1;
}
.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
0% {
opacity:1;
}
100% {
opacity:0;
}
}
@keyframes fadeOut {
0% {
opacity:1;
}
100% {
opacity:0;
}
}
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	z-index: 1;
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
	display: none;
}
.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel .owl-item img {
	display: block;
	-webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}
.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
	float: right;
}
.no-js .owl-carousel {
	display: block;
}
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
	transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transition: scale 100ms ease;
	-moz-transition: scale 100ms ease;
	-ms-transition: scale 100ms ease;
	-o-transition: scale 100ms ease;
	transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
	-webkit-transition: scale(1.3, 1.3);
	-moz-transition: scale(1.3, 1.3);
	-ms-transition: scale(1.3, 1.3);
	-o-transition: scale(1.3, 1.3);
	transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}
.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}
/*!
 * Bootstrap-select v1.7.2 (http://silviomoreto.github.io/bootstrap-select)
 *
 * Copyright 2013-2015 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */.bootstrap-select {
	width: 220px \0
}
.bootstrap-select>.dropdown-toggle {
	width: 100%;
	padding-right: 25px
}
.error .bootstrap-select .dropdown-toggle, .has-error .bootstrap-select .dropdown-toggle {
	border-color: #b94a48
}
.bootstrap-select.fit-width {
	width: auto!important
}
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: 220px
}
.bootstrap-select .dropdown-toggle:focus {
	outline: thin dotted #333!important;
	outline: 5px auto -webkit-focus-ring-color!important;
	outline-offset: -2px
}
.bootstrap-select.form-control {
	margin-bottom: 0;
	padding: 0;
	border: none
}
.bootstrap-select.form-control:not([class*=col-]) {
	width: 100%
}
.bootstrap-select.form-control.input-group-btn {
	z-index: auto
}
.bootstrap-select.btn-group:not(.input-group-btn), .bootstrap-select.btn-group[class*=col-] {
	float: none;
	display: inline-block;
	margin-left: 0
}
.bootstrap-select.btn-group.dropdown-menu-right, .bootstrap-select.btn-group[class*=col-].dropdown-menu-right, .row .bootstrap-select.btn-group[class*=col-].dropdown-menu-right {
	float: right
}
.form-group .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group, .form-inline .bootstrap-select.btn-group {
	margin-bottom: 0
}
.form-group-lg .bootstrap-select.btn-group.form-control, .form-group-sm .bootstrap-select.btn-group.form-control {
	padding: 0
}
.form-inline .bootstrap-select.btn-group .form-control {
	width: 100%
}
.bootstrap-select.btn-group.disabled, .bootstrap-select.btn-group>.disabled {
	cursor: not-allowed
}
.bootstrap-select.btn-group.disabled:focus, .bootstrap-select.btn-group>.disabled:focus {
	outline: 0!important
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	text-align: left
}
.bootstrap-select.btn-group .dropdown-toggle .caret {
	position: absolute;
	top: 50%;
	right: 12px;
	margin-top: -2px;
	vertical-align: middle
}
.bootstrap-select.btn-group[class*=col-] .dropdown-toggle {
	width: 100%
}
.bootstrap-select.btn-group .dropdown-menu {
	min-width: 100%;
	z-index: 1035;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.bootstrap-select.btn-group .dropdown-menu.inner {
	position: static;
	float: none;
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none
}
.bootstrap-select.btn-group .dropdown-menu li {
	position: relative
}
.bootstrap-select.btn-group .dropdown-menu li.active small {
	color: #fff
}
.bootstrap-select.btn-group .dropdown-menu li.disabled a {
	cursor: not-allowed
}
.bootstrap-select.btn-group .dropdown-menu li a {
	cursor: pointer
}
.bootstrap-select.btn-group .dropdown-menu li a.opt {
	position: relative;
	padding-left: 2.25em
}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
	display: none
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
	display: inline-block
}
.bootstrap-select.btn-group .dropdown-menu li small {
	padding-left: .5em
}
.bootstrap-select.btn-group .dropdown-menu .notify {
	position: absolute;
	bottom: 5px;
	width: 96%;
	margin: 0 2%;
	min-height: 26px;
	padding: 3px 5px;
	background: #f5f5f5;
	border: 1px solid #e3e3e3;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
	pointer-events: none;
	opacity: .9;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.bootstrap-select.btn-group .no-results {
	padding: 3px;
	background: #f5f5f5;
	margin: 0 5px;
	white-space: nowrap
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
	position: static
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
	position: static;
	top: auto;
	margin-top: -1px
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
	position: absolute;
	display: inline-block;
	right: 15px;
	margin-top: 5px
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
	margin-right: 34px
}
.bootstrap-select.show-menu-arrow.open>.dropdown-toggle {
	z-index: 1036
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
	content: '';
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid rgba(204,204,204,.2);
	position: absolute;
	bottom: -4px;
	left: 9px;
	display: none
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	position: absolute;
	bottom: -4px;
	left: 10px;
	display: none
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
	bottom: auto;
	top: -3px;
	border-top: 7px solid rgba(204,204,204,.2);
	border-bottom: 0
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
	bottom: auto;
	top: -3px;
	border-top: 6px solid #fff;
	border-bottom: 0
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
	right: 12px;
	left: auto
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
	right: 13px;
	left: auto
}
.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:after, .bootstrap-select.show-menu-arrow.open>.dropdown-toggle:before {
	display: block
}
.bs-actionsbox, .bs-donebutton, .bs-searchbox {
	padding: 4px 8px
}
.bs-actionsbox {
	float: left;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.bs-actionsbox .btn-group button {
	width: 50%
}
.bs-donebutton {
	float: left;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.bs-donebutton .btn-group button {
	width: 100%
}
.bs-searchbox+.bs-actionsbox {
	padding: 0 8px 4px
}
.bs-searchbox .form-control {
	margin-bottom: 0;
	width: 100%
}
select.bs-select-hidden, select.selectpicker {
	display: none!important
}
select.mobile-device {
	position: absolute!important;
	top: 0;
	left: 0;
	display: block!important;
	width: 100%;
	height: 100%!important;
	opacity: 0
}
/*
 * jQuery FlexSlider v2.6.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover, .flex-slider a:hover {
	outline: none;
}
.slides, .slides > li, .flex-control-nav, .flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}
.flex-pauseplay span {
	text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
	margin: 0;
	padding: 0;
}
.flexslider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}
.flexslider .slides img {
	width: 100%;
	display: block;
}
.flexslider .slides:after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
html[xmlns] .flexslider .slides {
	display: block;
}
* html .flexslider .slides {
	height: 1%;
}
.no-js .flexslider .slides > li:first-child {
	display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
	margin: 0 0 60px;
	background: #ffffff;
	border: 4px solid #ffffff;
	position: relative;
	zoom: 1;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	-o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
}
.flexslider .slides {
	zoom: 1;
}
.flexslider .slides img {
	height: auto;
	-moz-user-select: none;
}
.flex-viewport {
	max-height: 2000px;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}
.loading .flex-viewport {
	max-height: 300px;
}
.carousel li {
	margin-right: 5px;
}
.flex-direction-nav {
 *height: 0;
}
.flex-direction-nav a {
	text-decoration: none;
	display: block;
	width: 40px;
	height: 40px;
	margin: -20px 0 0;
	position: absolute;
	top: 50%;
	z-index: 10;
	overflow: hidden;
	opacity: 0;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.8);
	text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.flex-direction-nav a:before {
	font-family: "flexslider-icon";
	font-size: 40px;
	display: inline-block;
	content: '\f001';
	color: rgba(0, 0, 0, 0.8);
	text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}
.flex-direction-nav a.flex-next:before {
	content: '\f002';
}
.flex-direction-nav .flex-prev {
	left: -50px;
}
.flex-direction-nav .flex-next {
	right: -50px;
	text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
	opacity: 0.7;
	left: 10px;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
	opacity: 1;
}
.flexslider:hover .flex-direction-nav .flex-next {
	opacity: 0.7;
	right: 10px;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
	opacity: 1;
}
.flex-direction-nav .flex-disabled {
	opacity: 0!important;
	filter: alpha(opacity=0);
	cursor: default;
	z-index: -1;
}
.flex-pauseplay a {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 10px;
	opacity: 0.8;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #000;
}
.flex-pauseplay a:before {
	font-family: "flexslider-icon";
	font-size: 20px;
	display: inline-block;
	content: '\f004';
}
.flex-pauseplay a:hover {
	opacity: 1;
}
.flex-pauseplay a.flex-play:before {
	content: '\f003';
}
.flex-control-nav {
	width: 100%;
	position: absolute;
	bottom: -40px;
	text-align: center;
}
.flex-control-nav li {
	margin: 0 6px;
	display: inline-block;
	zoom: 1;
 *display: inline;
}
.flex-control-paging li a {
	width: 11px;
	height: 11px;
	display: block;
	border: 1px solid #fff;
	cursor: pointer;
	text-indent: -9999px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}
.flex-control-paging li a:hover {
	background: #fff;
}
.flex-control-paging li a.flex-active {
	background: #fff;
	cursor: default;
}
.flex-control-thumbs {
	margin: 5px 0 0;
	position: static;
	overflow: hidden;
}
.flex-control-thumbs li {
	width: 25%;
	float: left;
	margin: 0;
}
.flex-control-thumbs img {
	width: 100%;
	height: auto;
	display: block;
	opacity: .7;
	cursor: pointer;
	-moz-user-select: none;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-ms-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}
.flex-control-thumbs img:hover {
	opacity: 1;
}
.flex-control-thumbs .flex-active {
	opacity: 1;
	cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
.flex-direction-nav .flex-prev {
	opacity: 1;
	left: 10px;
}
.flex-direction-nav .flex-next {
	opacity: 1;
	right: 10px;
}
}
/*!
 * Cube Portfolio - Responsive jQuery Grid Plugin
 *
 * version: 3.4.0 (15 November, 2015)
 * require: jQuery v1.7+
 *
 * Copyright 2013-2015, Mihai Buricea (http://scriptpie.com/cubeportfolio/live-preview/)
 * Licensed under CodeCanyon License (http://codecanyon.net/licenses)
 *
 */.cbp, .cbp *, .cbp *:after, .cbp:after, .cbp-l-filters-alignCenter, .cbp-l-filters-alignCenter *, .cbp-l-filters-alignCenter .cbp-filter-counter:after, .cbp-l-filters-alignLeft, .cbp-l-filters-alignLeft *, .cbp-l-filters-alignRight, .cbp-l-filters-alignRight *, .cbp-l-filters-alignRight .cbp-filter-counter:after, .cbp-l-filters-button, .cbp-l-filters-button *, .cbp-l-filters-button .cbp-filter-counter:after, .cbp-l-filters-buttonCenter, .cbp-l-filters-buttonCenter *, .cbp-l-filters-buttonCenter .cbp-filter-counter:after, .cbp-l-filters-dropdown, .cbp-l-filters-dropdown *, .cbp-l-filters-dropdownHeader:after, .cbp-l-filters-list, .cbp-l-filters-list *, .cbp-l-filters-work, .cbp-l-filters-work *, .cbp-l-filters-big, .cbp-l-filters-big *, .cbp-l-filters-text, .cbp-l-filters-text *, .cbp-l-filters-text .cbp-filter-counter:after, .cbp-l-filters-underline, .cbp-l-filters-underline *, .cbp-popup-wrap, .cbp-popup-wrap *, .cbp-popup-wrap:before, .cbp-popup-loadingBox:after, .cbp-l-loadMore-bgbutton, .cbp-l-loadMore-bgbutton *, .cbp-l-loadMore-button, .cbp-l-loadMore-button *, .cbp-l-loadMore-text, .cbp-l-loadMore-text *, .cbp-search, .cbp-search *, .cbp-search-icon:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.cbp-l-grid-agency-title, .cbp-l-grid-agency-desc, .cbp-l-grid-work-title, .cbp-l-grid-work-desc, .cbp-l-grid-blog-title, .cbp-l-grid-projects-title, .cbp-l-grid-projects-desc, .cbp-l-grid-masonry-projects-title, .cbp-l-grid-masonry-projects-desc {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}
.cbp-l-filters-alignCenter, .cbp-l-filters-alignCenter *, .cbp-l-filters-alignLeft, .cbp-l-filters-alignLeft *, .cbp-l-filters-alignRight, .cbp-l-filters-alignRight *, .cbp-l-filters-button, .cbp-l-filters-button *, .cbp-l-filters-buttonCenter, .cbp-l-filters-buttonCenter *, .cbp-l-filters-dropdown, .cbp-l-filters-dropdown *, .cbp-l-filters-list, .cbp-l-filters-list *, .cbp-l-filters-work, .cbp-l-filters-work *, .cbp-l-filters-big, .cbp-l-filters-big *, .cbp-l-filters-text, .cbp-l-filters-text *, .cbp-l-filters-underline, .cbp-l-filters-underline * {
	-webkit-tap-highlight-color: transparent
}
.cbp:after, .cbp-lazyload:after, .cbp-popup-loadingBox:after, .cbp-popup-singlePageInline:after {
	content: '';
	position: absolute;
	width: 34px;
	height: 34px;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	-webkit-animation: cbp-rotation .8s infinite linear;
	animation: cbp-rotation .8s infinite linear;
	border-left: 3px solid rgba(114,144,182,0.15);
	border-right: 3px solid rgba(114,144,182,0.15);
	border-bottom: 3px solid rgba(114,144,182,0.15);
	border-top: 3px solid rgba(114,144,182,0.8);
	border-radius: 100%
}
.cbp-item {
	display: inline-block;
	margin: 0 10px 20px 0
}
.cbp {
	position: relative;
	margin: 0 auto;
	z-index: 1;
	height: 400px
}
.cbp>* {
	visibility: hidden
}
.cbp .cbp-item {
	position: absolute;
	top: 0;
	left: 0;
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden
}
.cbp img {
	display: block;
	border: 0;
	width: 100%;
	height: auto
}
.cbp a, .cbp a:hover, .cbp a:active {
	text-decoration: none;
	outline: 0
}
.cbp-ready>* {
	visibility: visible
}
.cbp-ready:after {
	display: none;
	visibility: hidden
}
.cbp-lazyload {
	position: relative;
	background: #fff;
	display: block
}
.cbp-lazyload img {
	opacity: 1
}
.cbp-lazyload img[data-cbp-src] {
	opacity: 0
}
.cbp-lazyload img:not([data-cbp-src]) {
	-webkit-transition: opacity .7s ease-in-out;
	transition: opacity .7s ease-in-out
}
.cbp-lazyload:after {
	z-index: 0
}
.cbp-wrapper-outer {
	overflow: hidden;
	position: relative;
	margin: 0 auto
}
.cbp-wrapper-outer, .cbp-wrapper, .cbp-wrapper-helper {
	list-style-type: none;
	padding: 0;
	width: 100%;
	height: 100%;
	z-index: 1
}
.cbp-wrapper, .cbp-wrapper-helper {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0
}
.cbp-item-off {
	z-index: -1;
	pointer-events: none;
	visibility: hidden
}
.cbp-item-on2off {
	z-index: 0
}
.cbp-item-off2on {
	z-index: 1
}
.cbp-item-on2on {
	z-index: 2
}
.cbp-item-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	left: 0
}
.cbp-l-project-related-wrap img, .cbp-l-inline img {
	display: block;
	width: 100%;
	height: auto;
	border: 0
}
.cbp-addItems {
	-webkit-transition: height .5s ease-in-out !important;
	transition: height .5s ease-in-out !important;
	will-change: height
}
.cbp-addItems .cbp-item {
	-webkit-transition: top .5s ease-in-out, left .5s ease-in-out;
	transition: top .5s ease-in-out, left .5s ease-in-out
}
.cbp-addItems .cbp-item-loading {
	-webkit-animation: fadeIn .5s ease-in-out;
	animation: fadeIn .5s ease-in-out;
	-webkit-transition: none;
	transition: none
}
.cbp-panel {
	width: 94%;
	max-width: 1170px;
	margin: 0 auto
}
.cbp-misc-video {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	background: #000;
	text-align: center
}
.cbp-misc-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}
@-webkit-keyframes cbp-rotation {
0 {
-webkit-transform:rotate(0deg)
}
100% {
-webkit-transform:rotate(360deg)
}
}
@keyframes cbp-rotation {
0 {
transform:rotate(0deg)
}
100% {
transform:rotate(360deg)
}
}
.clearfix:after {
	content: " ";
	display: block;
	height: 0;
	clear: both
}
.cbp-l-filters-left {
	float: left
}
.cbp-l-filters-right {
	float: right
}
.cbp-caption, .cbp-caption-defaultWrap, .cbp-caption-activeWrap {
	display: block
}
.cbp-caption-activeWrap {
	background-color: #282727
}
.cbp-caption-active .cbp-caption, .cbp-caption-active .cbp-caption-defaultWrap, .cbp-caption-active .cbp-caption-activeWrap {
	overflow: hidden;
	position: relative;
	z-index: 1
}
.cbp-caption-active .cbp-caption-defaultWrap {
	top: 0
}
.cbp-caption-active .cbp-caption-activeWrap {
	width: 100%;
	position: absolute;
	z-index: 2;
	height: 100%
}
.cbp-l-caption-title {
	color: #fff;
	font: 400 16px/21px "Open Sans", sans-serif
}
.cbp-l-caption-desc {
	color: #aaa;
	font: 400 12px/16px "Open Sans", sans-serif
}
.cbp-l-caption-text {
	font: 400 14px/21px "Open Sans", sans-serif;
	color: #fff;
	letter-spacing: 3px;
	padding: 0 6px
}
.cbp-l-caption-buttonLeft, .cbp-l-caption-buttonRight {
	background-color: #3288C4;
	color: #FFFFFF;
	display: inline-block;
	font: 400 12px/30px "Open Sans", sans-serif;
	min-width: 90px;
	text-align: center;
	margin: 4px;
	padding: 0 6px
}
.cbp-l-caption-buttonLeft:hover, .cbp-l-caption-buttonRight:hover {
	opacity: 0.9
}
.cbp-caption-none .cbp-caption-activeWrap {
	display: none
}
.cbp-l-caption-alignLeft .cbp-l-caption-body {
	padding: 12px 30px
}
.cbp-caption-fadeIn .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-minimal .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-moveRight .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-overlayRightAlong .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-pushDown .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-pushTop .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-revealBottom .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-revealLeft .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-revealTop .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-zoom .cbp-l-caption-alignLeft .cbp-l-caption-body, .cbp-caption-opacity .cbp-l-caption-alignLeft .cbp-l-caption-body {
	padding-top: 30px
}
.cbp-l-caption-alignCenter {
	display: table;
	width: 100%;
	height: 100%
}
.cbp-l-caption-alignCenter .cbp-l-caption-body {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	padding: 10px 0
}
.cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight {
	position: relative;
	-webkit-transition: left 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	transition: left 0.4s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-overlayBottom .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-caption-overlayBottomPush .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-caption-overlayBottomAlong .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft {
	left: -20px
}
.cbp-caption-overlayBottom .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight, .cbp-caption-overlayBottomPush .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight, .cbp-caption-overlayBottomAlong .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight {
	left: 20px
}
.cbp-caption:hover .cbp-l-caption-alignCenter .cbp-l-caption-buttonLeft, .cbp-caption:hover .cbp-l-caption-alignCenter .cbp-l-caption-buttonRight {
	left: 0
}
.cbp-caption-fadeIn .cbp-caption-activeWrap {
	opacity: 0;
	top: 0;
	background-color: rgba(0,0,0,0.85);
	-webkit-transition: opacity 0.9s cubic-bezier(0.19, 1, 0.22, 1);
	transition: opacity 0.9s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-fadeIn .cbp-caption:hover .cbp-caption-activeWrap {
	opacity: 1
}
.cbp-caption-minimal .cbp-l-caption-title, .cbp-caption-minimal .cbp-l-caption-desc {
	position: relative;
	left: 0;
	opacity: 0;
	-webkit-transition: -webkit-transform .35s ease-out;
	transition: transform .35s ease-out
}
.cbp-caption-minimal .cbp-l-caption-title {
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}
.cbp-caption-minimal .cbp-l-caption-desc {
	-webkit-transform: translateY(70%);
	transform: translateY(70%)
}
.cbp-caption-minimal .cbp-caption:hover .cbp-l-caption-title, .cbp-caption-minimal .cbp-caption:hover .cbp-l-caption-desc {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}
.cbp-caption-minimal .cbp-caption-activeWrap {
	top: 0;
	background-color: #000;
	background-color: rgba(0,0,0,0.8);
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"
}
.cbp-caption-minimal .cbp-caption:hover .cbp-caption-activeWrap {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"
}
.cbp-caption-moveRight .cbp-caption-activeWrap {
	left: -100%;
	top: 0;
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-moveRight .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}
.cbp-caption-overlayBottom .cbp-caption-activeWrap {
	height: 60px;
	background-color: #181616;
	background-color: rgba(24,22,22,0.7);
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-overlayBottom .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-overlayBottomAlong .cbp-caption-defaultWrap, .cbp-caption-overlayBottomAlong .cbp-caption-activeWrap {
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-overlayBottomAlong .cbp-caption-activeWrap {
	height: 60px
}
.cbp-caption-overlayBottomAlong .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(-30px);
	transform: translateY(-30px)
}
.cbp-caption-overlayBottomAlong .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-overlayBottomPush .cbp-caption-defaultWrap {
	-webkit-transition: top 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: top 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-overlayBottomPush .cbp-caption-activeWrap {
	bottom: -60px;
	height: 60px;
	-webkit-transition: bottom 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: bottom 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-overlayBottomPush .cbp-caption:hover .cbp-caption-defaultWrap {
	top: -60px
}
.cbp-caption-overlayBottomPush .cbp-caption:hover .cbp-caption-activeWrap {
	bottom: 0
}
.cbp-caption-overlayBottomReveal .cbp-caption-defaultWrap {
	z-index: 2;
	-webkit-transition: top 0.5s cubic-bezier(0.19, 1, 0.22, 1);
	transition: top 0.5s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-overlayBottomReveal .cbp-caption-activeWrap {
	bottom: 0;
	z-index: 1;
	height: 60px
}
.cbp-caption-overlayBottomReveal .cbp-caption:hover .cbp-caption-defaultWrap {
	top: -60px
}
.cbp-caption-overlayRightAlong .cbp-caption-defaultWrap, .cbp-caption-overlayRightAlong .cbp-caption-activeWrap {
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-overlayRightAlong .cbp-caption-activeWrap {
	top: 0;
	left: -50%;
	width: 50%
}
.cbp-caption-overlayRightAlong .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateX(25%);
	transform: translateX(25%)
}
.cbp-caption-overlayRightAlong .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateX(100%);
	transform: translateX(100%)
}
.cbp-caption-pushDown .cbp-caption-defaultWrap, .cbp-caption-pushDown .cbp-caption-activeWrap {
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-pushDown .cbp-caption-activeWrap {
	top: -102%;
	height: 102%
}
.cbp-caption-pushDown .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(100%);
	transform: translateY(100%)
}
.cbp-caption-pushDown .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(99%);
	transform: translateY(99%)
}
.cbp-caption-pushTop .cbp-caption-activeWrap, .cbp-caption-pushTop .cbp-caption-defaultWrap {
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-pushTop .cbp-caption-activeWrap {
	height: 102%
}
.cbp-caption-pushTop .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-pushTop .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateY(-99%);
	transform: translateY(-99%)
}
.cbp-caption-revealBottom .cbp-caption-defaultWrap {
	z-index: 2;
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-revealBottom .cbp-caption-activeWrap {
	top: 0;
	z-index: 1
}
.cbp-caption-revealBottom .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.cbp-caption-revealLeft .cbp-caption-activeWrap {
	left: 100%;
	top: 0;
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-revealLeft .cbp-caption:hover .cbp-caption-activeWrap {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%)
}
.cbp-caption-revealTop .cbp-caption-defaultWrap {
	z-index: 2;
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-revealTop .cbp-caption-activeWrap {
	top: 0;
	z-index: 1
}
.cbp-caption-revealTop .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: translateY(100%);
	transform: translateY(100%)
}
.cbp-caption-zoom .cbp-caption-defaultWrap {
	-webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: scale(1.25);
	transform: scale(1.25)
}
.cbp-caption-zoom .cbp-caption-activeWrap {
	opacity: 0;
	top: 0;
	background-color: rgba(0,0,0,0.9);
	-webkit-transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
	transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-zoom .cbp-caption:hover .cbp-caption-activeWrap {
	opacity: 1
}
.cbp-caption-opacity .cbp-item {
	padding: 1px
}
.cbp-caption-opacity .cbp-caption, .cbp-caption-opacity .cbp-caption-activeWrap, .cbp-caption-opacity .cbp-caption-defaultWrap {
	background-color: transparent
}
.cbp-caption-opacity .cbp-caption {
	border: 1px solid transparent
}
.cbp-caption-opacity .cbp-caption:hover {
	border-color: #EDEDED
}
.cbp-caption-opacity .cbp-caption-defaultWrap {
	opacity: 1;
	-webkit-transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
	transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.cbp-caption-opacity .cbp-caption:hover .cbp-caption-defaultWrap {
	opacity: .8
}
.cbp-caption-opacity .cbp-caption:hover .cbp-caption-activeWrap {
	top: 0
}
.cbp-caption-expand .cbp-caption-activeWrap {
	height: auto;
	background-color: transparent
}
.cbp-caption-expand .cbp-caption {
	border-bottom: 1px dotted #eaeaea
}
.cbp-caption-expand .cbp-caption-defaultWrap {
	cursor: pointer;
	font: 500 15px/23px "Roboto", sans-serif;
	color: #474747;
	padding: 12px 0 11px 26px
}
.cbp-caption-expand .cbp-caption-defaultWrap .fa {
	position: absolute;
	top: 16px;
	left: 0;
	width: 16px;
	text-align: center;
	font-size: 14px
}
.cbp-caption-expand .cbp-l-caption-body {
	font: 400 13px/21px "Roboto", sans-serif;
	color: #888;
	padding: 0 0 20px 26px
}
.cbp-caption-expand-active {
	-webkit-transition: height .4s !important;
	transition: height .4s !important
}
.cbp-caption-expand-active .cbp-item {
	-webkit-transition: left .4s, top .4s !important;
	transition: left .4s, top .4s !important
}
.cbp-caption-expand-open .cbp-caption-activeWrap {
	-webkit-transition: height .4s;
	transition: height .4s
}
.cbp-l-filters-alignCenter {
	margin-bottom: 30px;
	text-align: center;
	font: 400 12px/21px sans-serif;
	color: #DADADA
}
.cbp-l-filters-alignCenter .cbp-filter-item {
	color: #949494;
	cursor: pointer;
	font: 400 13px/21px "Open Sans", sans-serif;
	padding: 0 12px;
	transition: all .3s ease-in-out;
	position: relative;
	overflow: visible;
	margin: 0 0 10px;
	display: inline-block
}
.cbp-l-filters-alignCenter .cbp-filter-item:hover {
	color: #2D2C2C
}
.cbp-l-filters-alignCenter .cbp-filter-item.cbp-filter-item-active {
	color: #2D2C2C;
	cursor: default
}
.cbp-l-filters-alignCenter .cbp-filter-counter {
	background: none repeat scroll 0 0 #626161;
	border-radius: 3px;
	color: #FFFFFF;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity: 0;
	-webkit-transition: all .25s ease;
	transition: all .25s ease
}
.cbp-l-filters-alignCenter .cbp-filter-counter:after {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #626161;
	display: none
}
.cbp-l-filters-alignCenter .cbp-filter-item:hover .cbp-filter-counter:after {
	display: block
}
.cbp-l-filters-alignCenter .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 30px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-alignLeft {
	margin-bottom: 30px
}
.cbp-l-filters-alignLeft .cbp-filter-item {
	background-color: #fff;
	border: 1px solid #cdcdcd;
	cursor: pointer;
	font: 400 12px/30px "Open Sans", sans-serif;
	padding: 0 13px;
	position: relative;
	overflow: visible;
	margin: 0 8px 10px 0;
	display: inline-block;
	color: #888888;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}
.cbp-l-filters-alignLeft .cbp-filter-item:hover {
	color: #111
}
.cbp-l-filters-alignLeft .cbp-filter-item.cbp-filter-item-active {
	background-color: #6C7A89;
	border: 1px solid #6C7A89;
	color: #fff;
	cursor: default
}
.cbp-l-filters-alignLeft .cbp-filter-counter {
	display: inline
}
.cbp-l-filters-alignRight {
	margin-bottom: 30px;
	text-align: right
}
.cbp-l-filters-alignRight .cbp-filter-item {
	background-color: transparent;
	color: #8B8B8B;
	cursor: pointer;
	font: 400 11px/29px "Open Sans", sans-serif;
	padding: 0 12px;
	position: relative;
	overflow: visible;
	margin: 0 0 10px 5px;
	border: 1px solid #E4E2E2;
	text-transform: uppercase;
	display: inline-block;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.cbp-l-filters-alignRight .cbp-filter-item:hover {
	color: #2B3444
}
.cbp-l-filters-alignRight .cbp-filter-item:hover .cbp-filter-counter:after {
	display: block
}
.cbp-l-filters-alignRight .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 44px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-alignRight .cbp-filter-item.cbp-filter-item-active {
	color: #FFFFFF;
	background-color: #049372;
	border-color: #049372;
	cursor: default
}
.cbp-l-filters-alignRight .cbp-filter-counter {
	background: none repeat scroll 0 0 #049372;
	border-radius: 3px;
	color: #fff;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity: 0;
	-webkit-transition: opacity .25s ease, bottom .25s ease;
	transition: opacity .25s ease, bottom .25s ease
}
.cbp-l-filters-alignRight .cbp-filter-counter:after {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #049372;
	display: none
}
.cbp-l-filters-button {
	margin-bottom: 30px
}
.cbp-l-filters-button .cbp-filter-item {
	background-color: #FFFFFF;
	border: 1px solid #ECECEC;
	color: #888888;
	cursor: pointer;
	font: 400 12px/32px "Open Sans", sans-serif;
	margin: 0 10px 10px 0;
	overflow: visible;
	padding: 0 17px;
	position: relative;
	display: inline-block;
	-webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;
	transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out
}
.cbp-l-filters-button .cbp-filter-item:hover {
	color: #545454;
	border-color: #DADADA
}
.cbp-l-filters-button .cbp-filter-item.cbp-filter-item-active {
	background-color: #545454;
	color: #fff;
	border-color: #5d5d5d;
	cursor: default
}
.cbp-l-filters-button .cbp-filter-counter {
	background-color: #545454;
	border-radius: 3px;
	color: #fff;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: opacity .25s ease, bottom .25s ease;
	transition: opacity .25s ease, bottom .25s ease
}
.cbp-l-filters-button .cbp-filter-counter:after {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #545454;
	display: none
}
.cbp-l-filters-button .cbp-filter-item:hover .cbp-filter-counter:after {
	display: block
}
.cbp-l-filters-button .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 44px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-buttonCenter {
	margin-bottom: 30px;
	text-align: center
}
.cbp-l-filters-buttonCenter .cbp-filter-item {
	color: #888888;
	cursor: pointer;
	margin: 0 10px 10px 0;
	overflow: visible;
	padding: 0 17px;
	position: relative;
	display: inline-block;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.cbp-l-filters-buttonCenter .cbp-filter-item:hover {
	color: #5d5d5d
}
.cbp-l-filters-buttonCenter .cbp-filter-item.cbp-filter-item-active {
	color: #3B9CB3;
	border-color: #8CD2E5;
	cursor: default
}
.cbp-l-filters-buttonCenter .cbp-filter-counter {
	background-color: #1193d4;
	border-radius: 3px;
	color: #fff;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 34px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: opacity .25s ease, bottom .25s ease;
	transition: opacity .25s ease, bottom .25s ease
}
.cbp-l-filters-buttonCenter .cbp-filter-counter:after {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #1193d4;
	display: none
}
.cbp-l-filters-buttonCenter .cbp-filter-item:hover .cbp-filter-counter:after {
	display: block
}
.cbp-l-filters-buttonCenter .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 44px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-dropdown {
	margin-bottom: 40px;
	height: 38px;
	position: relative;
	z-index: 5
}
.cbp-l-filters-dropdownWrap {
	width: 200px;
	position: absolute;
	right: 0;
	background: #4d4c4d
}
.cbp-l-filters-dropdownHeader {
	font: 400 12px/38px "Open Sans", sans-serif;
	margin: 0 17px;
	color: #FFF;
	cursor: default;
	position: relative
}
.cbp-l-filters-dropdownHeader:after {
	border-color: #fff transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	content: "";
	height: 0;
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	margin-top: -1px
}
.cbp-l-filters-dropdownWrap.cbp-l-filters-dropdownWrap-open .cbp-l-filters-dropdownHeader:after {
	border-width: 0 5px 5px
}
.cbp-l-filters-dropdownList {
	display: none;
	list-style: none outside none;
	margin: 0;
	padding: 0
}
.cbp-l-filters-dropdownList>li {
	margin: 0;
	list-style: none
}
.cbp-l-filters-dropdownWrap.cbp-l-filters-dropdownWrap-open .cbp-l-filters-dropdownList {
	display: block;
	margin: 0
}
.cbp-l-filters-dropdownList .cbp-filter-item {
	background: transparent;
	color: #b3b3b3;
	width: 100%;
	text-align: left;
	font: 400 12px/40px "Open Sans", sans-serif;
	margin: 0;
	padding: 0 17px;
	cursor: pointer;
	border: none;
	border-top: 1px solid #595959
}
.cbp-l-filters-dropdownList .cbp-filter-item:hover {
	color: #e6e6e6
}
.cbp-l-filters-dropdownList .cbp-filter-item-active {
	color: #fff;
	cursor: default
}
.cbp-l-filters-dropdownWrap .cbp-filter-counter {
	display: inline
}
.cbp-l-filters-dropdown-floated {
	float: right;
	margin-top: -2px;
	margin-left: 20px;
	width: 200px
}
.cbp-l-filters-list {
	margin-bottom: 30px;
	content: "";
	display: table;
	clear: both
}
.cbp-l-filters-list .cbp-filter-item {
	background-color: transparent;
	color: #585252;
	cursor: pointer;
	font: 400 12px/35px "Open Sans", sans-serif;
	padding: 0 18px;
	position: relative;
	overflow: visible;
	margin: 0 0 10px;
	float: left;
	border: 1px solid #3288C4;
	border-right-width: 0;
	-webkit-transition: left .3s ease-in-out;
	transition: left .3s ease-in-out
}
.cbp-l-filters-list .cbp-filter-item:hover {
	color: #000
}
.cbp-l-filters-list .cbp-filter-item.cbp-filter-item-active {
	cursor: default;
	color: #FFFFFF;
	background-color: #3288C4
}
.cbp-l-filters-list-first {
	border-radius: 6px 0 0 6px
}
.cbp-l-filters-list-last {
	border-radius: 0 6px 6px 0;
	border-right-width: 1px !important
}
.cbp-l-filters-list .cbp-filter-counter {
	display: inline
}
@media only screen and (max-width: 600px) {
.cbp-l-filters-list .cbp-filter-item {
	margin-right: 5px;
	border-radius: 6px;
	border-right-width: 1px
}
}
.cbp-l-filters-work {
	margin-bottom: 30px;
	text-align: center
}
.cbp-l-filters-work .cbp-filter-item {
	background-color: #FFFFFF;
	color: #888;
	cursor: pointer;
	font: 600 11px/37px "Open Sans", sans-serif;
	margin: 0 5px 10px 0;
	overflow: visible;
	padding: 0 16px;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.cbp-l-filters-work .cbp-filter-item:hover {
	color: #fff;
	background: #607D8B
}
.cbp-l-filters-work .cbp-filter-item.cbp-filter-item-active {
	background: #607D8B;
	color: #fff;
	cursor: default
}
.cbp-l-filters-work .cbp-filter-counter {
	font: 600 11px/37px "Open Sans", sans-serif;
	text-align: center;
	display: inline-block;
	margin-left: 8px
}
.cbp-l-filters-work .cbp-filter-counter:before {
	content: '('
}
.cbp-l-filters-work .cbp-filter-counter:after {
	content: ')'
}
.cbp-l-filters-big {
	margin-bottom: 30px;
	text-align: center
}
.cbp-l-filters-big .cbp-filter-item {
	color: #444;
	cursor: pointer;
	font: 400 15px/22px "Roboto", sans-serif;
	margin: 0 15px 10px 0;
	padding: 10px 23px;
	position: relative;
	display: inline-block;
	border: 1px solid transparent;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.cbp-l-filters-big .cbp-filter-item:hover {
	color: #888
}
.cbp-l-filters-big .cbp-filter-item.cbp-filter-item-active {
	border-color: #d5d5d5;
	cursor: default;
	color: #444
}
.cbp-l-filters-text {
	margin-bottom: 30px;
	text-align: center;
	font: 400 12px/21px "Lato", sans-serif;
	color: #DADADA;
	padding: 0 15px
}
.cbp-l-filters-text .cbp-filter-item {
	color: #949494;
	cursor: pointer;
	font: 400 13px/21px "Lato", sans-serif;
	padding: 0 12px;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	position: relative;
	overflow: visible;
	margin: 0 0 10px;
	display: inline-block
}
.cbp-l-filters-text .cbp-filter-item:hover {
	color: #2D2C2C
}
.cbp-l-filters-text .cbp-filter-item.cbp-filter-item-active {
	color: #2D2C2C;
	cursor: default
}
.cbp-l-filters-text .cbp-filter-counter {
	background: none repeat scroll 0 0 #626161;
	border-radius: 3px;
	color: #FFFFFF;
	font: 400 11px/16px "Lato", sans-serif;
	margin: 0 auto;
	padding: 4px 0;
	text-align: center;
	width: 32px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity: 0;
	-webkit-transition: all .25s ease;
	transition: all .25s ease
}
.cbp-l-filters-text .cbp-filter-counter:after {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #626161;
	display: none
}
.cbp-l-filters-text .cbp-filter-item:hover .cbp-filter-counter:after {
	display: block
}
.cbp-l-filters-text .cbp-filter-item:hover .cbp-filter-counter {
	bottom: 30px;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1
}
.cbp-l-filters-text-sort {
	display: inline-block;
	font: 400 13px/21px "Lato", sans-serif;
	color: #949494;
	margin-right: 15px
}
.cbp-l-filters-underline {
	margin-bottom: 30px
}
.cbp-l-filters-underline .cbp-filter-item {
	border-bottom: 3px solid transparent;
	cursor: pointer;
	font: 600 14px/21px "Open Sans", sans-serif;
	padding: 8px 10px;
	position: relative;
	overflow: visible;
	margin: 0 20px 10px 0;
	display: inline-block;
	color: #787878;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}
.cbp-l-filters-underline .cbp-filter-item:hover {
	color: #111
}
.cbp-l-filters-underline .cbp-filter-item.cbp-filter-item-active {
	border-bottom-color: #666;
	color: #444;
	cursor: default
}
.cbp-l-filters-underline .cbp-filter-counter {
	display: inline
}
.cbp-animation-quicksand {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-quicksand .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-quicksand .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-quicksand .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-quicksand .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: quicksand-off .6s ease-out both;
	animation: quicksand-off .6s ease-out both
}
.cbp-animation-quicksand .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: quicksand-on .6s ease-out both;
	animation: quicksand-on .6s ease-out both
}
@-webkit-keyframes quicksand-off {
100% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0)
}
}
@keyframes quicksand-off {
100% {
opacity:0;
transform:scale3d(0, 0, 0)
}
}
@-webkit-keyframes quicksand-on {
0% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0)
}
}
@keyframes quicksand-on {
0% {
opacity:0;
transform:scale3d(0, 0, 0)
}
}
.cbp-animation-fadeOut, .cbp-animation-boxShadow {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-fadeOut .cbp-item, .cbp-animation-boxShadow .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-fadeOut .cbp-item, .cbp-animation-boxShadow .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-fadeOut .cbp-item-wrapper, .cbp-animation-boxShadow .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-fadeOut .cbp-item-on2off .cbp-item-wrapper, .cbp-animation-boxShadow .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: fadeOut-off .6s ease-in-out both;
	animation: fadeOut-off .6s ease-in-out both
}
.cbp-animation-fadeOut .cbp-item-off2on .cbp-item-wrapper, .cbp-animation-boxShadow .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: fadeOut-on .6s ease-in-out both;
	animation: fadeOut-on .6s ease-in-out both
}
@-webkit-keyframes fadeOut-off {
0% {
opacity:1
}
80%, 100% {
opacity:0
}
}
@keyframes fadeOut-off {
0% {
opacity:1
}
80%, 100% {
opacity:0
}
}
@-webkit-keyframes fadeOut-on {
0% {
opacity:0
}
100% {
opacity:1
}
}
@keyframes fadeOut-on {
0% {
opacity:0
}
100% {
opacity:1
}
}
.cbp-animation-flipOut {
	-webkit-transition: height .7s ease-in-out;
	transition: height .7s ease-in-out;
	will-change: height
}
.cbp-animation-flipOut .cbp-item {
	-webkit-transition: -webkit-transform .7s ease-in-out;
	transition: transform .7s ease-in-out
}
.cbp-animation-flipOut .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-flipOut .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-flipOut .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: flipOut-out .7s both ease-in;
	animation: flipOut-out .7s both ease-in
}
.cbp-animation-flipOut .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: flipOut-in .7s ease-out both;
	animation: flipOut-in .7s ease-out both
}
@-webkit-keyframes flipOut-out {
50%, 100% {
-webkit-transform:translateZ(-1000px) rotateY(-90deg);
opacity:0.2
}
}
@keyframes flipOut-out {
50%, 100% {
transform:translateZ(-1000px) rotateY(-90deg);
opacity:0.2
}
}
@-webkit-keyframes flipOut-in {
0%, 50% {
-webkit-transform:translateZ(-1000px) rotateY(90deg);
opacity:0.2
}
}
@keyframes flipOut-in {
0%, 50% {
transform:translateZ(-1000px) rotateY(90deg);
opacity:0.2
}
}
.cbp-animation-flipBottom {
	-webkit-transition: height .7s ease-in-out;
	transition: height .7s ease-in-out;
	will-change: height
}
.cbp-animation-flipBottom .cbp-item {
	-webkit-transition: -webkit-transform .7s ease-in-out;
	transition: transform .7s ease-in-out
}
.cbp-animation-flipBottom .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-flipBottom .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-flipBottom .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: flipBottom-out .7s both ease-in;
	animation: flipBottom-out .7s both ease-in
}
.cbp-animation-flipBottom .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: flipBottom-in .7s ease-out both;
	animation: flipBottom-in .7s ease-out both
}
@-webkit-keyframes flipBottom-out {
50%, 100% {
-webkit-transform:translateZ(-1000px) rotateX(-90deg);
opacity:0.2
}
}
@keyframes flipBottom-out {
50%, 100% {
transform:translateZ(-1000px) rotateX(-90deg);
opacity:0.2
}
}
@-webkit-keyframes flipBottom-in {
0%, 50% {
-webkit-transform:translateZ(-1000px) rotateX(90deg);
opacity:0.2
}
}
@keyframes flipBottom-in {
0%, 50% {
transform:translateZ(-1000px) rotateX(90deg);
opacity:0.2
}
}
.cbp-animation-scaleSides {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-scaleSides .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-scaleSides .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-scaleSides .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-scaleSides .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: scaleSides-out .9s ease both;
	animation: scaleSides-out .9s ease both
}
.cbp-animation-scaleSides .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: scaleSides-in .9s ease both;
	animation: scaleSides-in .9s ease both
}
@-webkit-keyframes scaleSides-out {
50%, 100% {
-webkit-transform:scale(0.6);
opacity:0
}
}
@keyframes scaleSides-out {
50%, 100% {
transform:scale(0.6);
opacity:0
}
}
@-webkit-keyframes scaleSides-in {
0%, 50% {
-webkit-transform:scale(0.6);
opacity:0
}
}
@keyframes scaleSides-in {
0%, 50% {
transform:scale(0.6);
opacity:0
}
}
.cbp-animation-skew {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-skew .cbp-item {
	-webkit-transition: -webkit-transform .6s ease-in-out;
	transition: transform .6s ease-in-out
}
.cbp-animation-skew .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-skew .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-skew .cbp-item-on2off .cbp-item-wrapper {
	-webkit-animation: skew-off .6s ease-out both;
	animation: skew-off .6s ease-out both
}
.cbp-animation-skew .cbp-item-off2on .cbp-item-wrapper {
	-webkit-animation: skew-on .6s ease-out both;
	animation: skew-on .6s ease-out both
}
@-webkit-keyframes skew-off {
100% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0) skew(20deg, 0)
}
}
@keyframes skew-off {
100% {
opacity:0;
transform:scale3d(0, 0, 0) skew(20deg, 0)
}
}
@-webkit-keyframes skew-on {
0% {
opacity:0;
-webkit-transform:scale3d(0, 0, 0) skew(0, 20deg)
}
}
@keyframes skew-on {
0% {
opacity:0;
transform:scale3d(0, 0, 0) skew(0, 20deg)
}
}
.cbp-animation-fadeOutTop {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-fadeOutTop .cbp-wrapper-outer {
	overflow: visible
}
.cbp-animation-fadeOutTop .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px;
	overflow: visible
}
.cbp-animation-fadeOutTop .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-fadeOutTop .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-out .6s both ease-in-out;
	animation: fadeOutTop-out .6s both ease-in-out
}
.cbp-animation-fadeOutTop .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-in .6s both ease-in-out;
	animation: fadeOutTop-in .6s both ease-in-out
}
@-webkit-keyframes fadeOutTop-out {
0% {
-webkit-transform:translateY(0);
opacity:1
}
50%, 100% {
-webkit-transform:translateY(-30px);
opacity:0
}
}
@keyframes fadeOutTop-out {
0% {
transform:translateY(0);
opacity:1
}
50%, 100% {
transform:translateY(-30px);
opacity:0
}
}
@-webkit-keyframes fadeOutTop-in {
0%, 50% {
-webkit-transform:translateY(-30px);
opacity:0
}
100% {
-webkit-transform:translateY(0);
opacity:1
}
}
@keyframes fadeOutTop-in {
0%, 50% {
transform:translateY(-30px);
opacity:0
}
100% {
transform:translateY(0);
opacity:1
}
}
.cbp-animation-slideLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-slideLeft .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-slideLeft .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-slideLeft .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: slideLeft-out .8s both ease-in-out;
	animation: slideLeft-out .8s both ease-in-out
}
.cbp-animation-slideLeft .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: slideLeft-in .8s both ease-in-out;
	animation: slideLeft-in .8s both ease-in-out
}
@-webkit-keyframes slideLeft-out {
0% {
opacity:1;
transform:scale(1)
}
25% {
opacity:.75;
-webkit-transform:scale(0.8)
}
75% {
opacity:.75;
-webkit-transform:scale(0.8) translateX(-200%)
}
100% {
opacity:.75;
-webkit-transform:scale(0.8) translateX(-200%)
}
}
@keyframes slideLeft-out {
0% {
opacity:1;
transform:scale(1)
}
25% {
opacity:.75;
transform:scale(0.8)
}
75% {
opacity:.75;
transform:scale(0.8) translateX(-200%)
}
100% {
opacity:.75;
transform:scale(0.8) translateX(-200%)
}
}
@-webkit-keyframes slideLeft-in {
0%, 25% {
opacity:.75;
-webkit-transform:scale(0.8) translateX(200%)
}
75% {
opacity:.75;
-webkit-transform:scale(0.8)
}
100% {
opacity:1;
-webkit-transform:scale(1) translateX(0)
}
}
@keyframes slideLeft-in {
0%, 25% {
opacity:.75;
transform:scale(0.8) translateX(200%)
}
75% {
opacity:.75;
transform:scale(0.8)
}
100% {
opacity:1;
transform:scale(1) translateX(0)
}
}
.cbp-animation-sequentially {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-sequentially .cbp-wrapper-outer {
	overflow: visible
}
.cbp-animation-sequentially .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px;
	overflow: visible
}
.cbp-animation-sequentially .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-sequentially .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-out .6s both ease;
	animation: fadeOutTop-out .6s both ease
}
.cbp-animation-sequentially .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: fadeOutTop-in .6s both ease-out;
	animation: fadeOutTop-in .6s both ease-out
}
.cbp-animation-3dflip {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-3dflip .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-3dflip .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-3dflip .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-animation: flip-out 0.6s both ease-in-out;
	animation: flip-out 0.6s both ease-in-out
}
.cbp-animation-3dflip .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-animation: flip-in 0.6s both ease-in-out;
	animation: flip-in 0.6s both ease-in-out
}
@-webkit-keyframes flip-out {
100% {
opacity:0;
-webkit-transform:rotateY(90deg)
}
}
@keyframes flip-out {
100% {
opacity:0;
transform:rotateY(90deg)
}
}
@-webkit-keyframes flip-in {
0% {
opacity:0;
-webkit-transform:rotateY(-90deg)
}
100% {
opacity:1;
-webkit-transform:rotateY(0deg)
}
}
@keyframes flip-in {
0% {
opacity:0;
transform:rotateY(-90deg)
}
100% {
opacity:1;
transform:rotateY(0deg)
}
}
.cbp-animation-flipOutDelay {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-flipOutDelay .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-flipOutDelay .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-flipOutDelay .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: flipOut-out 1s both ease-in;
	animation: flipOut-out 1s both ease-in
}
.cbp-animation-flipOutDelay .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: flipOut-in 1s both ease-out;
	animation: flipOut-in 1s both ease-out
}
.cbp-animation-slideDelay {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-slideDelay .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-slideDelay .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-slideDelay .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: slideDelay-out 0.5s both ease-in-out;
	animation: slideDelay-out 0.5s both ease-in-out
}
.cbp-animation-slideDelay .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: slideDelay-in 0.5s both ease-in-out;
	animation: slideDelay-in 0.5s both ease-in-out
}
@-webkit-keyframes slideDelay-out {
100% {
-webkit-transform:translateX(-100%)
}
}
@keyframes slideDelay-out {
100% {
transform:translateX(-100%)
}
}
@-webkit-keyframes slideDelay-in {
0% {
-webkit-transform:translateX(100%)
}
100% {
-webkit-transform:translateX(0)
}
}
@keyframes slideDelay-in {
0% {
transform:translateX(100%)
}
100% {
transform:translateX(0)
}
}
.cbp-animation-rotateSides {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-rotateSides .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-rotateSides .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-rotateSides .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: -50% 50%;
	-webkit-animation: rotateSides-out .5s both ease-in;
	transform-origin: -50% 50%;
	animation: rotateSides-out .5s both ease-in
}
.cbp-animation-rotateSides .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 150% 50%;
	-webkit-animation: rotateSides-in .6s both ease-out;
	transform-origin: 150% 50%;
	animation: rotateSides-in .6s both ease-out
}
@-webkit-keyframes rotateSides-out {
100% {
opacity:0;
-webkit-transform:translateZ(-500px) rotateY(90deg)
}
}
@keyframes rotateSides-out {
100% {
opacity:0;
transform:translateZ(-500px) rotateY(90deg)
}
}
@-webkit-keyframes rotateSides-in {
0% {
opacity:0;
-webkit-transform:translateZ(-500px) rotateY(-90deg)
}
40% {
opacity:0;
-webkit-transform:translateZ(-500px) rotateY(-90deg)
}
}
@keyframes rotateSides-in {
0% {
opacity:0;
transform:translateZ(-500px) rotateY(-90deg)
}
40% {
opacity:0;
transform:translateZ(-500px) rotateY(-90deg)
}
}
.cbp-animation-foldLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-foldLeft .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-foldLeft .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-foldLeft .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-animation: foldLeft-out .7s both ease;
	animation: foldLeft-out .7s both ease
}
.cbp-animation-foldLeft .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: foldLeft-in .7s both ease;
	animation: foldLeft-in .7s ease both
}
@-webkit-keyframes foldLeft-out {
100% {
opacity:0;
-webkit-transform:translateX(-100%) rotateY(-90deg)
}
}
@keyframes foldLeft-out {
100% {
opacity:0;
transform:translateX(-100%) rotateY(-90deg)
}
}
@-webkit-keyframes foldLeft-in {
0% {
opacity:0.3;
-webkit-transform:translateX(100%)
}
}
@keyframes foldLeft-in {
0% {
opacity:0.3;
transform:translateX(100%)
}
}
.cbp-animation-unfold {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-unfold .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-unfold .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-unfold .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: unfold-out .8s ease both;
	animation: unfold-out .8s ease both
}
.cbp-animation-unfold .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 0% 50%;
	-webkit-animation: unfold-in .8s both ease;
	transform-origin: 0% 50%;
	animation: unfold-in .8s both ease
}
@-webkit-keyframes unfold-out {
90% {
opacity:0.3
}
100% {
opacity:0;
-webkit-transform:translateX(-100%)
}
}
@keyframes unfold-out {
90% {
opacity:0.3
}
100% {
opacity:0;
transform:translateX(-100%)
}
}
@-webkit-keyframes unfold-in {
0% {
opacity:0;
-webkit-transform:translateX(100%) rotateY(90deg)
}
}
@keyframes unfold-in {
0% {
opacity:0;
transform:translateX(100%) rotateY(90deg)
}
}
.cbp-animation-scaleDown {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-scaleDown .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-scaleDown .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-scaleDown .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: scaleDown-out .7s ease both;
	animation: scaleDown-out .7s ease both
}
.cbp-animation-scaleDown .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: scaleDown-in .6s ease both;
	animation: scaleDown-in .6s ease both
}
@-webkit-keyframes scaleDown-out {
100% {
opacity:0;
-webkit-transform:scale(0.8)
}
}
@keyframes scaleDown-out {
100% {
opacity:0;
transform:scale(0.8)
}
}
@-webkit-keyframes scaleDown-in {
0% {
-webkit-transform:translateX(100%)
}
}
@keyframes scaleDown-in {
0% {
transform:translateX(100%)
}
}
.cbp-animation-frontRow {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-frontRow .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-frontRow .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-frontRow .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-animation: frontRow-out .7s both ease;
	animation: frontRow-out .7s both ease
}
.cbp-animation-frontRow .cbp-wrapper .cbp-item-wrapper {
	-webkit-animation: frontRow-in .6s both ease;
	animation: frontRow-in .6s both ease
}
@-webkit-keyframes frontRow-out {
100% {
-webkit-transform:translateX(-60%) scale(0.8);
opacity:0
}
}
@keyframes frontRow-out {
100% {
transform:translateX(-60%) scale(0.8);
opacity:0
}
}
@-webkit-keyframes frontRow-in {
0% {
-webkit-transform:translateX(100%) scale(0.8)
}
100% {
opacity:1;
-webkit-transform:translateX(0%) scale(1)
}
}
@keyframes frontRow-in {
0% {
transform:translateX(100%) scale(0.8)
}
100% {
opacity:1;
transform:translateX(0%) scale(1)
}
}
.cbp-animation-rotateRoom {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-rotateRoom .cbp-item {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-animation-rotateRoom .cbp-item-wrapper {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d
}
.cbp-animation-rotateRoom .cbp-wrapper-helper .cbp-item-wrapper {
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-animation: rotateRoom-out .8s both ease;
	animation: rotateRoom-out .8s both ease
}
.cbp-animation-rotateRoom .cbp-wrapper .cbp-item-wrapper {
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-animation: rotateRoom-in .8s both ease;
	animation: rotateRoom-in .8s both ease
}
@-webkit-keyframes rotateRoom-out {
90% {
opacity:.3
}
100% {
opacity:0;
-webkit-transform:translateX(-100%) rotateY(90deg)
}
}
@keyframes rotateRoom-out {
90% {
opacity:.3
}
100% {
opacity:0;
transform:translateX(-100%) rotateY(90deg)
}
}
@-webkit-keyframes rotateRoom-in {
0% {
opacity:.3;
-webkit-transform:translateX(100%) rotateY(-90deg)
}
}
@keyframes rotateRoom-in {
0% {
opacity:.3;
transform:translateX(100%) rotateY(-90deg)
}
}
.cbp-animation-bounceBottom {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-bounceBottom .cbp-wrapper-helper {
	-webkit-animation: bounceBottom-out .6s both ease-in-out;
	animation: bounceBottom-out .6s both ease-in-out
}
.cbp-animation-bounceBottom .cbp-wrapper {
	-webkit-animation: bounceBottom-in .6s both ease-in-out;
	animation: bounceBottom-in .6s both ease-in-out
}
@-webkit-keyframes bounceBottom-out {
100% {
-webkit-transform:translateY(100%);
opacity:0
}
}
@keyframes bounceBottom-out {
100% {
transform:translateY(100%);
opacity:0
}
}
@-webkit-keyframes bounceBottom-in {
0% {
-webkit-transform:translateY(100%);
opacity:0
}
100% {
-webkit-transform:translateY(0);
opacity:1
}
}
@keyframes bounceBottom-in {
0% {
transform:translateY(100%);
opacity:0
}
100% {
transform:translateY(0);
opacity:1
}
}
.cbp-animation-bounceLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-bounceLeft .cbp-wrapper-helper {
	-webkit-animation: bounceLeft-out .6s both ease-in-out;
	animation: bounceLeft-out .6s both ease-in-out
}
.cbp-animation-bounceLeft .cbp-wrapper {
	-webkit-animation: bounceLeft-in .6s both ease-in-out;
	animation: bounceLeft-in .6s both ease-in-out
}
@-webkit-keyframes bounceLeft-out {
100% {
-webkit-transform:translateX(-100%);
opacity:0
}
}
@keyframes bounceLeft-out {
100% {
transform:translateX(-100%);
opacity:0
}
}
@-webkit-keyframes bounceLeft-in {
0% {
-webkit-transform:translateX(-100%);
opacity:0
}
100% {
-webkit-transform:translateX(0);
opacity:1
}
}
@keyframes bounceLeft-in {
0% {
transform:translateX(-100%);
opacity:0
}
100% {
transform:translateX(0);
opacity:1
}
}
.cbp-animation-bounceTop {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-bounceTop .cbp-wrapper-helper {
	-webkit-animation: bounceTop-out .6s both ease-in-out;
	animation: bounceTop-out .6s both ease-in-out
}
.cbp-animation-bounceTop .cbp-wrapper {
	-webkit-animation: bounceTop-in .6s both ease-in-out;
	animation: bounceTop-in .6s both ease-in-out
}
@-webkit-keyframes bounceTop-out {
100% {
-webkit-transform:translateY(-100%);
opacity:0
}
}
@keyframes bounceTop-out {
100% {
transform:translateY(-100%);
opacity:0
}
}
@-webkit-keyframes bounceTop-in {
0% {
-webkit-transform:translateY(-100%);
opacity:0
}
100% {
-webkit-transform:translateY(0);
opacity:1
}
}
@keyframes bounceTop-in {
0% {
transform:translateY(-100%);
opacity:0
}
100% {
transform:translateY(0);
opacity:1
}
}
.cbp-animation-moveLeft {
	-webkit-transition: height .6s ease-in-out;
	transition: height .6s ease-in-out;
	will-change: height
}
.cbp-animation-moveLeft .cbp-wrapper-helper {
	-webkit-animation: moveLeft-out .6s both ease-in-out;
	animation: moveLeft-out .6s both ease-in-out
}
.cbp-animation-moveLeft .cbp-wrapper {
	-webkit-animation: moveLeft-in .6s both ease-in-out;
	animation: moveLeft-in .6s both ease-in-out
}
@-webkit-keyframes moveLeft-out {
100% {
-webkit-transform:translateX(-100%);
opacity:0
}
}
@keyframes moveLeft-out {
100% {
transform:translateX(-100%);
opacity:0
}
}
@-webkit-keyframes moveLeft-in {
0% {
-webkit-transform:translateX(100%);
opacity:0
}
100% {
-webkit-transform:translateX(0);
opacity:1
}
}
@keyframes moveLeft-in {
0% {
transform:translateX(100%);
opacity:0
}
100% {
transform:translateX(0);
opacity:1
}
}
.cbp-displayType-bottomToTop {
	-webkit-perspective: 1000px;
	perspective: 1000px
}
.cbp-displayType-bottomToTop .cbp-item {
	-webkit-animation: fadeInBottomToTop .3s both ease-in;
	animation: fadeInBottomToTop .3s both ease-in
}
@-webkit-keyframes fadeInBottomToTop {
0% {
opacity:0;
-webkit-transform:translateY(50px)
}
100% {
opacity:1;
-webkit-transform:translateY(0)
}
}
@keyframes fadeInBottomToTop {
0% {
opacity:0;
transform:translateY(50px)
}
100% {
opacity:1;
transform:translateY(0)
}
}
.cbp-displayType-fadeIn {
	-webkit-animation: fadeIn .5s both ease-in;
	animation: fadeIn .5s both ease-in
}
@-webkit-keyframes fadeIn {
0% {
opacity:0
}
100% {
opacity:1
}
}
@keyframes fadeIn {
0% {
opacity:0
}
100% {
opacity:1
}
}
.cbp-displayType-fadeInToTop {
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-animation: fadeInToTop .5s both ease-in;
	animation: fadeInToTop .5s both ease-in
}
@-webkit-keyframes fadeInToTop {
0% {
opacity:0;
-webkit-transform:translateY(30px)
}
100% {
opacity:1;
-webkit-transform:translateY(0)
}
}
@keyframes fadeInToTop {
0% {
opacity:0;
transform:translateY(30px)
}
100% {
opacity:1;
transform:translateY(0)
}
}
.cbp-displayType-sequentially .cbp-item {
	-webkit-animation: fadeIn .5s both ease-in;
	animation: fadeIn .5s both ease-in
}
.cbp-lightbox img {
	display: block;
	border: 0;
	width: 100%;
	height: auto
}
.cbp-popup-ie8bg {
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	background: #000;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)"
}
.cbp-popup-wrap {
	height: 100%;
	text-align: center;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	display: none;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 999999999;
	padding: 0 10px
}
.cbp-popup-lightbox {
	background: rgba(0,0,0,0.8)
}
.cbp-popup-singlePage {
	background: #fff;
	padding: 0
}
.cbp-popup-wrap:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle
}
.cbp-popup-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	max-width: 100%
}
.cbp-popup-singlePage .cbp-popup-content {
	position: relative;
	z-index: 1;
	margin-top: 145px;
	max-width: 100%;
	vertical-align: top;
	width: 100%;
	text-align: center;
}
.cbp-popup-singlePage .cbp-popup-content-basic {
	position: relative;
	z-index: 1;
	margin-top: 104px;
	vertical-align: top;
	width: 100%;
	display: inline-block;
	text-align: left
}
.cbp-popup-lightbox-figure {
	position: relative
}
.cbp-popup-lightbox-bottom {
	left: 0;
	position: absolute;
	top: 100%;
	width: 100%;
	margin-top: 3px
}
.cbp-popup-lightbox-title {
	padding-right: 70px;
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #eee
}
.cbp-popup-lightbox-counter {
	position: absolute;
	top: 0;
	right: 0;
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #eee
}
.cbp-popup-lightbox-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	margin: 40px 0 40px;
	box-shadow: 0 0 8px rgba(0,0,0,0.6)
}
.cbp-popup-lightbox-img[data-action] {
	cursor: pointer
}
.cbp-popup-lightbox-isIframe .cbp-popup-content {
	width: 75%
}
@media only screen and (max-width: 768px) {
.cbp-popup-lightbox-isIframe .cbp-popup-content {
	width: 95%
}
}
.cbp-popup-lightbox-isIframe .cbp-lightbox-bottom {
	left: 0;
	position: absolute;
	top: 100%;
	width: 100%;
	margin-top: 3px
}
.cbp-popup-lightbox-iframe {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	background: #000
}
.cbp-popup-lightbox-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0,0,0,0.6)
}
.cbp-popup-lightbox-iframe audio {
	margin-top: 27%
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9990;
	height: 70px;
	background-color: #34425C
}
.cbp-popup-singlePage .cbp-popup-navigation {
	position: relative;
	width: 100%;
	height: 100%
}
.cbp-popup-singlePage-sticky .cbp-popup-navigation-wrap {
	position: fixed;
	top: 0 !important
}
.cbp-popup-singlePage-counter {
	color: #bbc0df;
	position: absolute;
	margin: auto;
	right: 40px;
	top: 0;
	bottom: 0;
	font: 400 13px/30px "Open Sans", sans-serif;
	height: 30px
}
@media only screen and (max-width: 768px) {
.cbp-popup-singlePage-counter {
	right: 3%
}
}
.cbp-popup-next, .cbp-popup-prev, .cbp-popup-close {
	padding: 0;
	border: medium none;
	position: absolute;
	cursor: pointer;
	outline: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.cbp-popup-lightbox .cbp-popup-prev, .cbp-popup-lightbox .cbp-popup-next, .cbp-popup-lightbox .cbp-popup-close {
	visibility: hidden
}
.cbp-popup-ready.cbp-popup-lightbox .cbp-popup-next, .cbp-popup-ready.cbp-popup-lightbox .cbp-popup-prev, .cbp-popup-ready.cbp-popup-lightbox .cbp-popup-close {
	visibility: visible
}
.cbp-popup-lightbox .cbp-popup-prev {
	width: 44px;
	height: 44px;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto
}
.cbp-popup-lightbox .cbp-popup-prev:hover {
	background-position: 0 -46px
}
.cbp-popup-singlePage .cbp-popup-prev {
	background: url("../img/cbp-sprite.png") no-repeat scroll 0 -92px transparent;
	width: 44px;
	margin: auto;
	top: 0;
	right: 108px;
	bottom: 0;
	left: 0
}
.cbp-popup-singlePage .cbp-popup-prev:hover {
	background-position: 0 -138px
}
.cbp-popup-lightbox .cbp-popup-next {
	width: 40px;
	height: 40px;
	top: 0;
	bottom: 0;
	right: 20px;
	margin: auto
}
.cbp-popup-lightbox .cbp-popup-next:hover {
}
.cbp-popup-singlePage .cbp-popup-next {
	background: url("../img/cbp-sprite.png") no-repeat scroll -46px -92px transparent;
	width: 44px;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 108px
}
.cbp-popup-singlePage .cbp-popup-next:hover {
	background-position: -46px -138px
}
.cbp-popup-lightbox .cbp-popup-close {
	height: 40px;
	width: 40px;
	right: 20px;
	top: 20px
}
.cbp-popup-lightbox .cbp-popup-close:hover {
	background-position: -92px -46px
}
.cbp-popup-singlePage .cbp-popup-close {
	background: url("../img/cbp-sprite.png") no-repeat scroll -92px -92px transparent;
	width: 44px;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0
}
.cbp-popup-singlePage .cbp-popup-ie8bg {
	background-color: #fff
}
.cbp-popup-singlePage .cbp-popup-close:hover {
	background-position: -92px -138px
}
@media only screen and (max-width: 360px), (max-height: 600px) {
.cbp-popup-next, .cbp-popup-prev, .cbp-popup-close {
	-ms-transform: scale(0.8);
	-webkit-transform: scale(0.8);
	transform: scale(0.8)
}
.cbp-popup-lightbox .cbp-popup-close {
	right: 10px;
	top: 10px
}
.cbp-popup-lightbox .cbp-popup-next {
	right: 10px
}
.cbp-popup-lightbox .cbp-popup-prev {
	left: 10px
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
	height: 70px
}
.cbp-popup-singlePage .cbp-popup-content {
	margin-top: 120px
}
}
.cbp-popup-loadingBox {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0
}
.cbp-popup-lightbox .cbp-popup-loadingBox:after {
	border-left: 3px solid rgba(255,255,255,0.3);
	border-right: 3px solid rgba(255,255,255,0.3);
	border-bottom: 3px solid rgba(255,255,255,0.3);
	border-top: 3px solid rgba(255,255,255,0.85)
}
.cbp-popup-ready .cbp-popup-loadingBox {
	visibility: hidden;
	display: none
}
.cbp-popup-loading .cbp-popup-loadingBox {
	visibility: visible;
	display: block
}
.cbp-popup-singlePage {
	left: 100%;
	-webkit-transition: left .6s ease-in-out;
	transition: left .6s ease-in-out
}
.cbp-popup-singlePage.cbp-popup-loading .cbp-popup-content {
	opacity: 0
}
.cbp-popup-singlePage-open {
	left: 0
}
.cbp-popup-singlePage.cbp-popup-singlePage-fade {
	left: 0;
	opacity: 0;
	-webkit-transition: opacity .25s ease-in-out;
	transition: opacity .25s ease-in-out
}
.cbp-popup-singlePage-open.cbp-popup-singlePage-fade {
	opacity: 1
}
.cbp-popup-singlePage.cbp-popup-singlePage-right {
	left: -100%;
	-webkit-transition: left .6s ease-in-out;
	transition: left .6s ease-in-out
}
.cbp-popup-singlePage-open.cbp-popup-singlePage-right {
	left: 0
}
.cbp-l-project-title {
	color: #454444;
	font: 600 42px/46px "Open Sans", sans-serif;
	letter-spacing: 2px;
	margin-bottom: 15px;
	text-align: center;
	text-transform: uppercase
}
.cbp-l-project-subtitle {
	color: #787878;
	font: 400 14px/21px "Open Sans", sans-serif;
	margin: 0 auto 50px;
	max-width: 500px;
	text-align: center
}
.cbp-popup-singlePage .cbp-popup-content .cbp-l-project-img {
	display: block;
	margin: 0 auto;
	max-width: 100%
}
.cbp-l-project-container {
	overflow: hidden;
	margin: 40px auto 0;
	clear: both
}
.cbp-l-project-desc {
	float: left;
	width: 64%
}
.cbp-l-project-social-wrapper, .cbp-l-inline-social-wrapper {
	display: inline-block;
	width: 100px;
	height: 20px;
	overflow: hidden
}
.cbp-l-inline-social-wrapper {
	margin-top: 5px
}
.cbp-l-project-details {
	float: right;
	width: 36%;
	padding-left: 40px;
	margin-bottom: 15px
}
@media only screen and (max-width: 768px) {
.cbp-l-project-title {
	font-size: 30px;
	line-height: 34px
}
.cbp-l-project-desc {
	width: 100%
}
.cbp-l-project-details {
	width: 100%;
	margin-top: 20px;
	padding-left: 0
}
}
.cbp-l-project-desc-title {
	border-bottom: 1px solid #cdcdcd;
	margin-bottom: 22px;
	color: #444
}
.cbp-l-project-desc-title span, .cbp-l-project-details-title span {
	border-bottom: 1px solid #747474;
	display: inline-block;
	margin: 0 0 -1px 0;
	font: 400 16px/36px "Open Sans", sans-serif;
	padding: 0 5px 0 0
}
.cbp-l-project-desc-text {
	font: 400 13px/20px "Open Sans", sans-serif;
	color: #555;
	margin-bottom: 20px
}
.cbp-l-project-details-title {
	border-bottom: 1px solid #cdcdcd;
	margin-bottom: 19px;
	color: #444
}
.cbp-l-project-details-list {
	margin: 0;
	padding: 0;
	list-style: none
}
.cbp-l-project-details-list>li, .cbp-l-project-details-list>div {
	border-bottom: 1px dotted #DFDFDF;
	padding: inherit;
	color: #666;
	font: 400 12px/30px "Open Sans", sans-serif
}
.cbp-l-project-details-list strong {
	display: inline-block;
	color: #696969;
	font-weight: 600;
	min-width: 100px
}
.cbp-l-project-details-visit {
	color: #FFFFFF;
	float: right;
	clear: both;
	text-decoration: none;
	font: 400 11px/18px "Open Sans", sans-serif;
	margin-top: 10px;
	background-color: #53668A;
	padding: 8px 19px
}
.cbp-l-project-details-visit:hover {
	opacity: 0.9;
	color: #fff
}
.cbp-l-project-related-wrap {
	font-size: 0;
	margin: 0;
	padding: 0
}
.cbp-l-project-related-item {
	margin-left: 5%;
	max-width: 30%;
	float: left
}
.cbp-l-project-related-item:first-child {
	margin-left: 0
}
.cbp-l-project-related-title {
	font: 700 14px/18px "Open Sans", sans-serif;
	color: #474747;
	margin-top: 20px
}
.cbp-l-project-related-link {
	text-decoration: none
}
.cbp-l-project-related-link:hover {
	opacity: 0.9
}
.cbp-l-member-img {
	float: left;
	width: 40%;
	margin-top: 20px
}
.cbp-l-member-img img {
	border: 1px solid #e2e2e2;
	width: auto;
	max-width: 100%;
	height: auto;
	display: inline-block;
	border: 0
}
.cbp-l-member-info {
	margin-top: 20px;
	padding-left: 25px;
	float: left;
	width: 60%
}
@media only screen and (max-width: 768px) {
.cbp-l-member-img {
	width: 100%;
	text-align: center
}
.cbp-l-member-info {
	width: 100%;
	padding-left: 0
}
}
.cbp-l-member-name {
	font: 400 28px/28px "Open Sans", sans-serif;
	color: #474747
}
.cbp-l-member-position {
	font: 400 13px/21px "Open Sans", sans-serif;
	color: #888;
	margin-top: 6px
}
.cbp-l-member-desc {
	font: 400 12px/18px "Open Sans", sans-serif;
	margin-top: 25px;
	color: #474747
}
.cbp-popup-singlePageInline-open {
	-webkit-transition: height .5s ease 0s !important;
	transition: height .5s ease 0s !important
}
.cbp-popup-singlePageInline-open .cbp-item {
	-webkit-transition: -webkit-transform .5s ease 0s !important;
	transition: transform .5s ease 0s !important
}
.cbp-popup-singlePageInline-close .cbp-popup-singlePageInline:after {
	display: none;
	visibility: hidden
}
.cbp-popup-singlePageInline-close .cbp-popup-singlePageInline .cbp-popup-content, .cbp-popup-singlePageInline-close .cbp-popup-singlePageInline .cbp-popup-navigation {
	-webkit-transition-delay: 0;
	transition-delay: 0
}
.cbp-popup-singlePageInline {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	overflow: hidden
}
.cbp-popup-singlePageInline .cbp-popup-content {
	opacity: 0;
	width: 100%;
	z-index: 1;
	min-height: 300px
}
.cbp-popup-singlePageInline .cbp-popup-content, .cbp-popup-singlePageInline .cbp-popup-navigation {
	-webkit-transition: opacity .4s ease-in .2s;
	transition: opacity .4s ease-in .2s
}
.cbp-popup-singlePageInline .cbp-popup-navigation {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	width: 40px;
	height: 40px
}
.cbp-popup-singlePageInline .cbp-popup-close {
	background: url("../img/cbp-sprite.png") no-repeat scroll -92px 0 transparent;
	height: 40px;
	width: 40px;
	right: 20px;
	top: 30px
}
.cbp-popup-singlePageInline .cbp-popup-close:hover {
	opacity: 0.7
}
.cbp-popup-singlePageInline-ready {
	z-index: 4
}
.cbp-popup-singlePageInline-ready .cbp-popup-content, .cbp-popup-singlePageInline-ready .cbp-popup-navigation {
	opacity: 1
}
.cbp-popup-singlePageInline-ready:after {
	display: none;
	visibility: hidden
}
.cbp-singlePageInline-active {
	opacity: 0.6 !important
}
.cbp-l-inline {
	margin: 20px 0;
	overflow: hidden;
	background: #FAFAFA;
	padding: 30px
}
.cbp-l-inline-left {
	float: left;
	width: 44%
}
.cbp-l-project-img {
	max-width: 100%
}
.cbp-l-inline-right {
	float: right;
	width: 56%;
	padding-left: inherit
}
@media only screen and (max-width: 768px) {
.cbp-l-inline-left {
	width: 100%;
	text-align: center;
	margin-top: 40px
}
.cbp-l-inline-right {
	width: 100%;
	padding-left: 0;
	margin-top: 20px
}
}
.cbp-l-inline-title {
	font: 400 28px/30px "Open Sans", sans-serif;
	color: #474747
}
.cbp-l-inline-subtitle {
	font: 400 13px/21px "Open Sans", sans-serif;
	color: #888;
	margin-top: 7px
}
.cbp-l-inline-desc {
	font: 400 13px/20px "Open Sans", sans-serif;
	color: #474747;
	margin-top: 25px
}
.cbp-l-inline-view-wrap {
	text-align: right
}
.cbp-l-inline-view {
	font: 400 13px/35px "Open Sans", sans-serif;
	color: #9C9C9C;
	margin-top: 40px;
	display: inline-block;
	padding: 0 20px;
	border: 1px solid #ccc;
	text-decoration: none
}
.cbp-l-inline-view:hover {
	color: #757575
}
.cbp-l-inline-details {
	margin-top: 15px;
	font: 13px/22px "Open Sans", sans-serif
}
.cbp-l-loadMore-defaultText, .cbp-l-loadMore-button-defaultText {
	display: block
}
.cbp-l-loadMore-loadingText, .cbp-l-loadMore-noMoreLoading, .cbp-l-loadMore-button-loadingText, .cbp-l-loadMore-button-noMoreLoading {
	display: none
}
.cbp-l-loadMore-loading .cbp-l-loadMore-loadingText, .cbp-l-loadMore-loading .cbp-l-loadMore-button-loadingText {
	display: block
}
.cbp-l-loadMore-loading .cbp-l-loadMore-defaultText, .cbp-l-loadMore-loading .cbp-l-loadMore-noMoreLoading, .cbp-l-loadMore-loading .cbp-l-loadMore-button-defaultText, .cbp-l-loadMore-loading .cbp-l-loadMore-button-noMoreLoading {
	display: none
}
.cbp-l-loadMore-stop .cbp-l-loadMore-noMoreLoading, .cbp-l-loadMore-stop .cbp-l-loadMore-button-noMoreLoading {
	display: block
}
.cbp-l-loadMore-stop .cbp-l-loadMore-defaultText, .cbp-l-loadMore-stop .cbp-l-loadMore-loadingText, .cbp-l-loadMore-stop .cbp-l-loadMore-button-defaultText, .cbp-l-loadMore-stop .cbp-l-loadMore-button-loadingText {
	display: none
}
.cbp-l-loadMore-bgbutton {
	text-align: center
}
.cbp-l-loadMore-bgbutton .cbp-l-loadMore-link {
	border: 1px solid #DEDEDE;
	color: #7E7B7B;
	display: inline-block;
	font: 400 13px/40px "Lato", sans-serif;
	min-width: 80px;
	text-decoration: none;
	padding: 0 50px;
	margin-top: 50px;
	outline: 0;
	box-shadow: none;
	letter-spacing: 1px;
	-webkit-transition: color .25s;
	transition: color .25s
}
.cbp-l-loadMore-bgbutton .cbp-l-loadMore-link:hover, .cbp-l-loadMore-bgbutton .cbp-l-loadMore-link.cbp-l-loadMore-loading {
	color: #B0B0B0
}
.cbp-l-loadMore-bgbutton .cbp-l-loadMore-link.cbp-l-loadMore-stop {
	cursor: default;
	color: #B0B0B0
}
.cbp-l-loadMore-button {
	text-align: center
}
.cbp-l-loadMore-button .cbp-l-loadMore-link, .cbp-l-loadMore-button .cbp-l-loadMore-button-link {
	border: 1px solid #DEDEDE;
	color: #7E7B7B;
	display: inline-block;
	font: 400 12px/36px "Open Sans", sans-serif;
	min-width: 80px;
	text-decoration: none;
	padding: 0 30px;
	outline: 0;
	margin-top: 40px;
	box-shadow: none;
	-webkit-transition: color .25s;
	transition: color .25s
}
.cbp-l-loadMore-button .cbp-l-loadMore-link:hover, .cbp-l-loadMore-button .cbp-l-loadMore-button-link:hover, .cbp-l-loadMore-button .cbp-l-loadMore-link.cbp-l-loadMore-loading {
	color: #B0B0B0
}
.cbp-l-loadMore-button .cbp-l-loadMore-link.cbp-l-loadMore-stop, .cbp-l-loadMore-button .cbp-l-loadMore-button-link.cbp-l-loadMore-stop, .cbp-l-loadMore-button .cbp-l-loadMore-button-link.cbp-l-loadMore-button-stop {
	cursor: default;
	color: #B0B0B0
}
.cbp-l-loadMore-text {
	text-align: center
}
.cbp-l-loadMore-text .cbp-l-loadMore-link, .cbp-l-loadMore-text .cbp-l-loadMore-text-link {
	font: 400 15px "Open Sans", sans-serif;
	color: #7E7B7B;
	text-decoration: none;
	cursor: pointer;
	margin-top: 50px;
	display: block
}
.cbp-l-loadMore-text .cbp-l-loadMore-stop, .cbp-l-loadMore-text .cbp-l-loadMore-text-stop {
	color: #B0B0B0;
	cursor: default
}
.cbp-mode-slider {
	-webkit-transition: height 0.35s cubic-bezier(0.22, 0.6, 0.345, 1);
	transition: height 0.35s cubic-bezier(0.22, 0.6, 0.345, 1)
}
.cbp-mode-slider .cbp-wrapper, .cbp-mode-slider .cbp-item {
	-webkit-transition: -webkit-transform 0.35s cubic-bezier(0.22, 0.6, 0.345, 1);
	transition: transform 0.35s cubic-bezier(0.22, 0.6, 0.345, 1)
}
.cbp-mode-slider .cbp-wrapper {
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab
}
.cbp-mode-slider-dragStart * {
	cursor: move !important;
	cursor: -ms-grabbing !important;
	cursor: -webkit-grabbing !important;
	cursor: -moz-grabbing !important;
	cursor: grabbing !important
}
.cbp-mode-slider-dragStart .cbp-wrapper {
	-webkit-transition: none;
	transition: none
}
.cbp-nav-next, .cbp-nav-prev {
	position: relative;
	background: #7c8b90;
	cursor: pointer;
	display: inline-block;
	margin-left: 1px;
	height: 22px;
	width: 21px
}
.cbp-nav-next {
	border-radius: 0 2px 2px 0
}
.cbp-nav-prev {
	border-radius: 2px 0 0 2px
}
.cbp-nav-next:hover, .cbp-nav-prev:hover {
	opacity: 0.8
}
.cbp-nav-next:after, .cbp-nav-prev:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background: url("../img/cbp-sprite.png") no-repeat;
	height: 10px;
	width: 7px
}
.cbp-nav-next:after {
	background-position: -134px 0
}
.cbp-nav-prev:after {
	background-position: -134px -12px
}
.cbp-nav-stop {
	opacity: 0.5 !important;
	cursor: default !important
}
.cbp-nav {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.cbp-nav-controls {
	position: absolute;
	top: -51px;
	right: 0;
	z-index: 100
}
.cbp-nav-pagination {
	position: absolute;
	bottom: -30px;
	right: 0;
	z-index: 100;
	left: 0;
	text-align: center
}
.cbp-nav-pagination-item {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0 4px;
	display: inline-block;
	cursor: pointer;
	background: #c2c2c2;
	-webkit-transition: background 0.5s;
	transition: background 0.5s
}
.cbp-nav-pagination-active {
	background: #797979
}
.cbp-pagination-item {
	max-width: 100px;
	display: inline-block;
	cursor: pointer;
	margin-top: 10px;
	margin-right: 5px;
	position: relative
}
.cbp-pagination-item img {
	display: block;
	width: 100%;
	height: auto;
	border: 0
}
.cbp-pagination-item:after {
	content: '';
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	-webkit-transition: background .5s ease-in-out;
	transition: background .5s ease-in-out
}
.cbp-pagination-active:after {
	background: transparent
}
.cbp-slider-wrap, .cbp-slider-item {
	margin: 0;
	padding: 0;
	list-style-type: none
}
.cbp-slider .cbp-nav-controls {
	position: static
}
.cbp-slider .cbp-nav-next, .cbp-slider .cbp-nav-prev {
	background: transparent;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	z-index: 100;
	width: 44px;
	height: 44px
}
.cbp-slider .cbp-nav-next {
	right: 25px;
	left: auto
}
.cbp-slider .cbp-nav-prev {
	left: 25px;
	right: auto
}
.cbp-slider .cbp-nav-next:after, .cbp-slider .cbp-nav-prev:after {
	background: url("../img/cbp-sprite.png") no-repeat;
	width: 44px;
	height: 44px
}
.cbp-slider .cbp-nav-next:after {
	background-position: -46px -92px
}
.cbp-slider .cbp-nav-next:hover:after {
	background-position: -46px -46px
}
.cbp-slider .cbp-nav-prev:after {
	background-position: 0 -92px
}
.cbp-slider .cbp-nav-prev:hover:after {
	background-position: 0 -46px
}
.cbp-slider .cbp-nav-pagination {
	text-align: right;
	bottom: 20px;
	right: 25px;
	left: auto
}
.cbp-slider-edge .cbp-nav-controls {
	position: static
}
.cbp-slider-edge .cbp-nav-next, .cbp-slider-edge .cbp-nav-prev {
	background: transparent;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	z-index: 100;
	width: 44px;
	height: 44px
}
.cbp-slider-edge .cbp-nav-next {
	right: -14px;
	left: auto
}
.cbp-slider-edge .cbp-nav-prev {
	left: -14px;
	right: auto
}
.cbp-slider-edge .cbp-nav-next:after, .cbp-slider-edge .cbp-nav-prev:after {
	background: url("../img/cbp-sprite.png") no-repeat;
	width: 9px;
	height: 16px
}
.cbp-slider-edge .cbp-nav-next:after {
	background-position: -134px -24px
}
.cbp-slider-edge .cbp-nav-prev:after {
	background-position: -134px -42px
}
.cbp-slider-edge .cbp-nav-pagination {
	bottom: -50px
}
.cbp-slider-edge .cbp-nav-pagination-item {
	border: 2px solid #0f0f0f;
	opacity: 0.4;
	background: transparent
}
.cbp-slider-edge .cbp-nav-pagination-active {
	background: #000
}
.cbp-slider-inline {
	position: relative
}
.cbp-slider-inline .cbp-slider-item {
	position: absolute;
	width: 100%;
	top: 0;
	transition: left .5s ease
}
.cbp-slider-inline .cbp-slider-item--active {
	position: relative;
	z-index: 2
}
.cbp-slider-wrapper {
	position: relative;
	overflow: hidden
}
.cbp-slider-controls {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	opacity: 0;
	-webkit-transition: opacity .7s ease-in-out;
	transition: opacity .7s ease-in-out
}
.cbp-slider-inline-ready .cbp-slider-controls {
	opacity: 1
}
.cbp-slider-next, .cbp-slider-prev {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	float: left;
	cursor: pointer;
	position: relative;
	width: 36px;
	height: 36px;
	background: #3288C4
}
.cbp-slider-next {
	margin-left: 1px
}
.cbp-slider-next:after, .cbp-slider-prev:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background: url("../img/cbp-sprite.png") no-repeat;
	width: 9px;
	height: 16px
}
.cbp-slider-next:after {
	background-position: -134px -60px
}
.cbp-slider-prev:after {
	background-position: -134px -78px
}
.cbp-l-grid-agency .cbp-caption:after {
	position: absolute;
	content: '';
	width: 0;
	height: 0;
	border-bottom: 10px solid #fff;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	bottom: 0px;
	left: 50%;
	margin-left: -5px;
	z-index: 1
}
.cbp-l-grid-agency.cbp-caption-zoom .cbp-caption:hover .cbp-caption-defaultWrap {
	-webkit-transform: scale(1.15);
	transform: scale(1.15)
}
.cbp-l-grid-agency-title {
	margin-top: 18px;
	font: 700 17px/24px "Lato", sans-serif;
	color: #666;
	text-align: center
}
.cbp-item:hover .cbp-l-grid-agency-title {
	color: #222
}
.cbp-l-grid-agency-desc {
	font: 400 12px/21px "Open Sans", sans-serif;
	color: #aaa;
	text-align: center
}
.cbp-l-grid-work.cbp-caption-zoom .cbp-caption-activeWrap {
	background-color: rgba(0,0,0,0.7)
}
.cbp-l-grid-work .cbp-item {
	padding: 3px
}
.cbp-l-grid-work .cbp-item-wrapper {
	background-color: #fff;
	box-shadow: 0 1px 1px rgba(0,0,0,0.2);
	padding: 7px 7px 27px;
	border-top: 1px solid #F4F4F4
}
.cbp-l-grid-work-title {
	margin-top: 17px;
	font: 400 17px/25px "Roboto Condensed", sans-serif;
	color: #607D8B;
	text-align: center;
	text-transform: uppercase;
	display: block
}
.cbp-l-grid-work-title:hover {
	color: #365D67
}
.cbp-l-grid-work-desc {
	font: 400 11px/16px "Open Sans", sans-serif;
	color: #888888;
	text-align: center;
	text-transform: uppercase
}
.cbp-l-grid-blog-title {
	font: 400 18px/30px "Open Sans", sans-serif;
	color: #444;
	display: block;
	margin-top: 17px
}
.cbp-l-grid-blog-title:hover {
	color: #787878
}
.cbp-l-grid-blog-date {
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #787878;
	display: inline-block
}
.cbp-l-grid-blog-comments {
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #3C6FBB;
	display: inline-block
}
.cbp-l-grid-blog-comments:hover {
	opacity: .8
}
.cbp-l-grid-blog-desc {
	font: 400 13px/18px "Open Sans", sans-serif;
	color: #9B9B9B;
	margin-top: 9px
}
.cbp-l-grid-blog-split {
	margin: 0 4px;
	font: 400 13px/16px "Open Sans", sans-serif;
	color: #787878;
	display: inline-block
}
.cbp-l-grid-clients {
	height: 180px
}
.cbp-l-clients-title-block {
	font: 400 32px/53px "Roboto", sans-serif;
	color: #666464;
	text-align: center;
	margin-bottom: 40px
}
.cbp-l-grid-faq .cbp-item {
	width: 100%
}
.cbp-l-grid-projects-title {
	font: 700 14px/21px "Open Sans", sans-serif;
	color: #474747;
	margin-top: 15px
}
.cbp-l-grid-projects-desc {
	font: 400 12px/18px "Open Sans", sans-serif;
	color: #888888;
	margin-top: 5px
}
.cbp-l-grid-masonry-projects .cbp-caption-activeWrap {
	background-color: #59a3b6;
	background-color: rgba(89,163,182,0.95)
}
.cbp-l-grid-masonry-projects .cbp-l-caption-buttonLeft, .cbp-l-grid-masonry-projects .cbp-l-caption-buttonRight {
	background-color: #545454
}
.cbp-l-grid-masonry-projects-title {
	font: 500 15px/22px "Roboto", sans-serif;
	color: #59a3b6;
	text-align: center;
	display: block;
	margin-top: 12px
}
.cbp-l-grid-masonry-projects-title:hover {
	color: #457C8B
}
.cbp-l-grid-masonry-projects-desc {
	font: 400 12px/18px "Roboto", sans-serif;
	color: #b2b2b2;
	text-align: center
}
.cbp-l-grid-team-name {
	font: 400 17px/24px "Open Sans", sans-serif;
	color: #456297;
	display: block;
	text-align: center;
	margin-top: 18px
}
.cbp-l-grid-team-name:hover {
	color: #34425C
}
.cbp-l-grid-team-position {
	font: italic 400 13px/21px "Open Sans", sans-serif;
	color: #999;
	text-align: center
}
.cbp-l-grid-mosaic-flat .cbp-caption-activeWrap {
	background-color: #64C28E;
	background-color: rgba(101,199,150,0.9)
}
.cbp-l-grid-mosaic-flat .cbp-l-caption-title {
	color: #FFFFFF;
	font: 400 14px/21px "Lato", sans-serif;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block
}
.cbp-l-grid-mosaic-flat .cbp-l-caption-title:after {
	content: '';
	display: block;
	width: 40%;
	height: 1px;
	background-color: #fff;
	margin: 8px auto 0
}
.cbp-l-grid-mosaic-projects .cbp-caption-activeWrap {
	background-color: #59a3b6;
	background-color: rgba(89,163,182,0.97)
}
.cbp-l-grid-mosaic .cbp-caption-activeWrap {
	background-color: #FFEA71;
	background-color: rgba(255,234,113,0.95)
}
.cbp-l-grid-mosaic .cbp-l-caption-title {
	color: #5A5A5A;
	font: 500 18px/22px "Roboto", sans-serif;
	text-transform: uppercase;
	margin-bottom: 5px
}
.cbp-l-grid-mosaic .cbp-l-caption-desc {
	color: #585858;
	font: 400 13px/20px "Roboto", sans-serif
}
.cbp-l-slider-title-block {
	border-bottom: 1px solid #cdcdcd;
	margin-bottom: 22px
}
.cbp-l-slider-title-block div {
	padding: 0 2px 6px 0;
	display: inline-block;
	border-bottom: 1px solid #a9a5a5;
	color: #5e5e5e;
	margin-bottom: -1px;
	font: 15px/21px "Roboto", sans-serif
}
.cbp-l-grid-slider-team-name {
	float: left;
	font: 20px/30px "Roboto", sans-serif;
	color: #494949;
	margin-top: 16px
}
.cbp-l-grid-slider-team-position {
	clear: both;
	font: 14px/21px "Roboto", sans-serif;
	color: #A6A6A6
}
.cbp-l-grid-slider-team-desc {
	font: 13px/20px "Roboto", sans-serif;
	color: #969696;
	margin-top: 15px
}
.cbp-l-grid-slider-team-social {
	float: right;
	margin-top: 16px
}
.cbp-l-grid-slider-team-social a {
	color: #737373;
	line-height: 30px;
	margin-left: 6px
}
.cbp-l-grid-slider-team-social a:hover {
	opacity: 0.8
}
.cbp-l-slider-testimonials-wrap {
	background: #f8f9f9;
	padding: 80px 0 110px;
	border-width: 1px 0;
	border-style: solid;
	border-color: #dce1e2
}
.cbp-l-grid-slider-testimonials-body {
	color: #424242;
	max-width: 800px;
	margin: 0 auto;
	font: 20px/32px sans-serif;
	text-align: center;
	padding: 0 20px
}
.cbp-l-grid-slider-testimonials-footer {
	font: 12px/19px "Roboto", sans-serif;
	color: #777;
	text-align: center;
	margin-bottom: 10px;
	margin-top: 30px
}
.cbp-l-grid-tabs {
	height: 100px
}
.cbp-l-grid-tabs .cbp-item {
	font: 14px/24px "Lato", sans-serif;
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	right: 0;
	text-align: center;
	color: #5a5a5a
}
.cbp-l-testimonials-title-block {
	position: relative;
	text-align: center;
	font: 26px/36px "Roboto", sans-serif;
	color: #E7E7E7;
	margin-bottom: 60px
}
.cbp-l-testimonials-title-block:after {
	content: '';
	position: absolute;
	margin: 0 auto;
	width: 23px;
	height: 2px;
	bottom: -6px;
	background-color: #C2C2C2;
	left: 0;
	right: 0
}
.cbp-l-testimonials-wrap {
	background: #2D2D2D;
	padding: 60px 0 110px
}
.cbp-l-grid-testimonials-body {
	color: #e7e7e7;
	max-width: 800px;
	margin: 0 auto;
	font: 20px/32px "Roboto", sans-serif;
	text-align: center;
	padding: 0 20px
}
.cbp-l-grid-testimonials-footer {
	font: 12px/19px "Roboto", sans-serif;
	color: #C2C2C2;
	text-align: center;
	margin-bottom: 40px;
	margin-top: 35px
}
.cbp-search {
	position: relative;
	width: 220px;
	margin-bottom: 40px
}
.cbp-search .cbp-search-nothing {
	display: none
}
.cbp-search-icon {
	position: absolute;
	width: 32px;
	height: 100%;
	top: 0;
	right: 0;
	text-align: center;
	cursor: pointer;
	pointer-events: none
}
.cbp-search-icon:after {
	content: '\f002';
	font-family: 'FontAwesome';
	font-size: 14px;
	color: #797979;
	line-height: 36px;
	pointer-events: none
}
.cbp-search-input {
	height: 36px;
	padding: 0 32px 0 12px;
	margin: 0;
	border-radius: 1px;
	border: 1px solid #c6c3c4;
	font: 400 12px "Open Sans", sans-serif;
	width: 100%
}
.cbp-search-input[value]+.cbp-search-icon {
	pointer-events: auto
}
.cbp-search-input[value]+.cbp-search-icon:after {
	content: '\f00d'
}
.cbp-search-nothing {
	position: absolute;
	top: 0;
	padding: 0 0 30px;
	text-align: center;
	width: 100%;
	font: 13px "Open Sans", sans-serif
}
.noUi-target, .noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-base {
	width: 95%;
	height: 100%;
	position: relative;
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
	z-index: 10;
}
.noUi-stacking + .noUi-origin {
*z-index:-1;
}
.noUi-state-tap .noUi-origin {
	-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}
.noUi-horizontal {
	height: 4px;
	width: 100%;
	position: relative;
	background: #e2e2e2;
	height: 8px;
	border-radius: 4px;
}
.noUi-horizontal .noUi-handle {
	width: 34px;
	height: 28px;
	left: 0px;
	top: -5px;
}
.noUi-horizontal.noUi-extended {
	padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
	right: -15px;
}
.noUi-vertical {
	width: 18px;
}
.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px;
}
.noUi-vertical.noUi-extended {
	padding: 15px 0;
}
.noUi-vertical.noUi-extended .noUi-origin {
	bottom: -15px;
}
.noUi-background {
	background-color: #e2e2e2;
}
.noUi-base .noUi-background {
	background: none;
}
.noUi-dragable {
	cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
	cursor: n-resize;
}
.noUi-handle {
	cursor: pointer;
}
.noUi-handle:after {
	left: 17px;
}
.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px;
}
.noUi-vertical .noUi-handle:after {
	top: 17px;
}
[disabled].noUi-connect, [disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled] .noUi-handle {
	cursor: not-allowed;
}
.sidebar-nav.cost-price {
	margin-bottom: 70px;
}
.cost-price-content {
	display: inline-block;
	position: relative;
}
.cost-price-content .noUi-handle-lower, .cost-price-content .noUi-handle-upper {
	height: 21px;
	width: 21px;
	background-color: #f1f3f8;
	border: 1px solid #dde1eb;
}
.cost-price-content .noUi-base .noUi-connect, .cost-price-content .noUi-base .noUi-background {
	position: absolute;
	top: -2px;
}
.price-min, .price-max {
	position: absolute;
	margin-top: 15px;
	font-size: 14px;
	color: #515151;
}
.price-min {
	left: 0;
}
.price-max {
	right: 0;
}
.cost-price-content .noUi-handle-lower, .cost-price-content .noUi-handle-upper {
	background-color: #20a7ea;
	border: none;
	border-radius: 50%;
}
.ui-helper-hidden {
	display: none;
}
.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}
.ui-helper-clearfix:before, .ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}
.ui-helper-clearfix:after {
	clear: both;
}
.ui-helper-clearfix {
	min-height: 0;
}
.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
}
.ui-front {
	z-index: 100;
}
.ui-state-disabled {
	cursor: default !important;
}
.ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
}
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ui-resizable {
	position: relative;
}
.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
}
.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
	display: none;
}
.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	left: 0;
}
.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	left: 0;
}
.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	right: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px;
}
.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px;
}
.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px;
}
.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px;
}
.ui-button {
	display: inline-block;
	position: relative;
	padding: 0;
	line-height: normal;
	margin-right: .1em;
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	overflow: visible;
}
.ui-button, .ui-button:link, .ui-button:visited, .ui-button:hover, .ui-button:active {
	text-decoration: none;
}
.ui-button-icon-only {
	width: 2.2em;
}
button.ui-button-icon-only {
	width: 2.4em;
}
.ui-button-icons-only {
	width: 3.4em;
}
button.ui-button-icons-only {
	width: 3.7em;
}
.ui-button .ui-button-text {
	display: block;
	line-height: normal;
}
.ui-button-text-only .ui-button-text {
	padding: .4em 1em;
}
.ui-button-icon-only .ui-button-text, .ui-button-icons-only .ui-button-text {
	padding: .4em;
	text-indent: -9999999px;
}
.ui-button-text-icon-primary .ui-button-text, .ui-button-text-icons .ui-button-text {
	padding: .4em 1em .4em 2.1em;
}
.ui-button-text-icon-secondary .ui-button-text, .ui-button-text-icons .ui-button-text {
	padding: .4em 2.1em .4em 1em;
}
.ui-button-text-icons .ui-button-text {
	padding-left: 2.1em;
	padding-right: 2.1em;
}
input.ui-button {
	padding: .4em 1em;
}
.ui-button-icon-only .ui-icon, .ui-button-text-icon-primary .ui-icon, .ui-button-text-icon-secondary .ui-icon, .ui-button-text-icons .ui-icon, .ui-button-icons-only .ui-icon {
	position: absolute;
	top: 50%;
	margin-top: -8px;
}
.ui-button-icon-only .ui-icon {
	left: 50%;
	margin-left: -8px;
}
.ui-button-text-icon-primary .ui-button-icon-primary, .ui-button-text-icons .ui-button-icon-primary, .ui-button-icons-only .ui-button-icon-primary {
	left: .5em;
}
.ui-button-text-icon-secondary .ui-button-icon-secondary, .ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary {
	right: .5em;
}
.ui-buttonset {
	margin-right: 7px;
}
.ui-buttonset .ui-button {
	margin-left: 0;
	margin-right: -.3em;
}
input.ui-button::-moz-focus-inner, button.ui-button::-moz-focus-inner {
border:0;
padding:0;
}
.ui-datepicker {
	width: 17em;
	padding: .2em .2em 0;
	display: none;
}
.ui-datepicker .ui-datepicker-header {
	position: relative;
	padding: .2em 0;
	background: #3dc5df;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: 0px;
	width: 1.8em;
	height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
	top: 0px;
}
.ui-datepicker .ui-datepicker-prev:before {
	content: '\f104';
	font-family: 'FontAwesome';
	font-size: 20px;
	left: 8px;
	top: 3px !important;
	color: #fff;
	position: absolute;
}
.ui-datepicker .ui-datepicker-next {
	position: absolute;
	right: 0px;
}
.ui-datepicker .ui-datepicker-next:before {
	content: '\f105';
	font-family: 'FontAwesome';
	font-size: 20px;
	right: 8px;
	top: 3px;
	color: #fff;
	position: absolute;
}
.ui-datepicker .ui-datepicker-next .ui-icon {
	position: absolute;
	right: 0px;
	top: 0px !important;
}
.ui-datepicker .ui-datepicker-prev-hover {
}
.ui-datepicker .ui-datepicker-next-hover {
}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
}
.ui-datepicker .ui-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
	font-size: 1em;
}
.ui-datepicker select.ui-datepicker-month-year {
	width: 100%;
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
	width: 49%;
}
.ui-datepicker table {
	width: 100%;
	font-size: .9em;
	border-collapse: collapse;
	margin: 0 0 .4em;
}
.ui-datepicker th {
	padding: .7em .3em;
	text-align: center;
	font-weight: bold;
	border: 0;
}
.ui-datepicker td {
	border: 0;
	padding: 1px;
}
.ui-datepicker td span, .ui-datepicker td a {
	display: block;
	padding: .2em;
	text-align: right;
	text-decoration: none;
}
.ui-datepicker .ui-datepicker-buttonpane {
	background-image: none;
	margin: .7em 0 0 0;
	padding: 0 .2em;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
	float: right;
	margin: .5em .2em .4em;
	cursor: pointer;
	padding: .2em .6em .3em .6em;
	width: auto;
	overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: left;
}
.ui-datepicker.ui-datepicker-multi {
	width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
	float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
	width: 95%;
	margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
	border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}
.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}
.ui-datepicker-rtl {
	direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}
.ui-widget {
	font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
	font-size: 1.1em;
}
.ui-widget .ui-widget {
	font-size: 1em;
}
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
	font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
	font-size: 1em;
}
.ui-widget-content {
	border: 1px solid #647382;
	background: #fff;
	color: #333333;
}
.ui-widget-content a {
	color: #333333;
}
.ui-widget-header {
	color: #ffffff;
	font-weight: bold;
}
.ui-widget-header a {
	color: #ffffff;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	border: 1px solid #cccccc;
	background: #fff;
	font-weight: normal;
	color: #647382;
	text-align: center;
}
.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
	color: #1c94c4;
	text-decoration: none;
}
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
	background-position: 0 0;
}
.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited {
	color: #c77405;
	text-decoration: none;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
	border: 1px solid #333333;
	background: #333333;
	font-weight: bold;
	color: #fff;
}
.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
	color: #eb8f00;
	text-decoration: none;
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
	border: 1px solid #3dc5df;
	background: #3dc5df;
	color: #fff;
}
.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
	color: #363636;
}
.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
	border: 1px solid #cd0a0a;
	background: #b81900 url(images/ui-bg_diagonals-thick_18_b81900_40x40.png) 50% 50% repeat;
	color: #ffffff;
}
.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
	color: #ffffff;
}
.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
	color: #ffffff;
}
.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
	font-weight: bold;
}
.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
	opacity: .7;
	filter: Alpha(Opacity=70);
	font-weight: normal;
}
.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
	opacity: .35;
	filter: Alpha(Opacity=35);
	background-image: none;
}
.ui-state-disabled .ui-icon {
	filter: Alpha(Opacity=35);
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
	border-top-left-radius: 4px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
	border-top-right-radius: 4px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
	border-bottom-left-radius: 4px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
	border-bottom-right-radius: 4px;
}
.ui-widget-overlay {
	background: #666666 url(images/ui-bg_diagonals-thick_20_666666_40x40.png) 50% 50% repeat;
	opacity: .5;
	filter: Alpha(Opacity=50);
}
.ui-widget-shadow {
	margin: -5px 0 0 -5px;
	padding: 5px;
	background: #000000 url(images/ui-bg_flat_10_000000_40x100.png) 50% 50% repeat-x;
	opacity: .2;
	filter: Alpha(Opacity=20);
	border-radius: 5px;
}
.no-margin {
	margin: 0 !important;
}
.no-margin-top {
	margin-top: 0 !important;
}
.no-margin-right {
	margin-right: 0 !important;
}
.no-margin-bottom {
	margin-bottom: 0 !important;
}
.no-margin-left {
	margin-left: 0 !important;
}
.no-padding {
	padding: 0 !important;
}
.no-padding-top {
	padding-top: 0 !important;
}
.no-padding-right {
	padding-right: 0 !important;
}
.no-padding-bottom {
	padding-bottom: 0 !important;
}
.no-padding-left {
	padding-left: 0 !important;
}
.margin-0 {
	margin: 0px !important;
}
.margin-left-0 {
	margin-left: 0px !important;
}
.margin-right-0 {
	margin-right: 0px !important;
}
.margin-top-0 {
	margin-top: 0px !important;
}
.margin-bottom-0 {
	margin-bottom: 0px !important;
}
.padding-0 {
	padding: 0px !important;
}
.padding-left-0 {
	padding-left: 0px !important;
}
.padding-right-0 {
	padding-right: 0px !important;
}
.padding-top-0 {
	padding-top: 0px !important;
}
.padding-bottom-0 {
	padding-bottom: 0px !important;
}
.margin-1 {
	margin: 1px !important;
}
.margin-left-1 {
	margin-left: 1px !important;
}
.margin-right-1 {
	margin-right: 1px !important;
}
.margin-top-1 {
	margin-top: 1px !important;
}
.margin-bottom-1 {
	margin-bottom: 1px !important;
}
.padding-1 {
	padding: 1px !important;
}
.padding-left-1 {
	padding-left: 1px !important;
}
.padding-right-1 {
	padding-right: 1px !important;
}
.padding-top-1 {
	padding-top: 1px !important;
}
.padding-bottom-1 {
	padding-bottom: 1px !important;
}
.margin-2 {
	margin: 2px !important;
}
.margin-left-2 {
	margin-left: 2px !important;
}
.margin-right-2 {
	margin-right: 2px !important;
}
.margin-top-2 {
	margin-top: 2px !important;
}
.margin-bottom-2 {
	margin-bottom: 2px !important;
}
.padding-2 {
	padding: 2px !important;
}
.padding-left-2 {
	padding-left: 2px !important;
}
.padding-right-2 {
	padding-right: 2px !important;
}
.padding-top-2 {
	padding-top: 2px !important;
}
.padding-bottom-2 {
	padding-bottom: 2px !important;
}
.margin-3 {
	margin: 3px !important;
}
.margin-left-3 {
	margin-left: 3px !important;
}
.margin-right-3 {
	margin-right: 3px !important;
}
.margin-top-3 {
	margin-top: 3px !important;
}
.margin-bottom-3 {
	margin-bottom: 3px !important;
}
.padding-3 {
	padding: 3px !important;
}
.padding-left-3 {
	padding-left: 3px !important;
}
.padding-right-3 {
	padding-right: 3px !important;
}
.padding-top-3 {
	padding-top: 3px !important;
}
.padding-bottom-3 {
	padding-bottom: 3px !important;
}
.margin-4 {
	margin: 4px !important;
}
.margin-left-4 {
	margin-left: 4px !important;
}
.margin-right-4 {
	margin-right: 4px !important;
}
.margin-top-4 {
	margin-top: 4px !important;
}
.margin-bottom-4 {
	margin-bottom: 4px !important;
}
.padding-4 {
	padding: 4px !important;
}
.padding-left-4 {
	padding-left: 4px !important;
}
.padding-right-4 {
	padding-right: 4px !important;
}
.padding-top-4 {
	padding-top: 4px !important;
}
.padding-bottom-4 {
	padding-bottom: 4px !important;
}
.margin-5 {
	margin: 5px !important;
}
.margin-left-5 {
	margin-left: 5px !important;
}
.margin-right-5 {
	margin-right: 5px !important;
}
.margin-top-5 {
	margin-top: 5px !important;
}
.margin-bottom-5 {
	margin-bottom: 5px !important;
}
.padding-5 {
	padding: 5px !important;
}
.padding-left-5 {
	padding-left: 5px !important;
}
.padding-right-5 {
	padding-right: 5px !important;
}
.padding-top-5 {
	padding-top: 5px !important;
}
.padding-bottom-5 {
	padding-bottom: 5px !important;
}
.margin-6 {
	margin: 6px !important;
}
.margin-left-6 {
	margin-left: 6px !important;
}
.margin-right-6 {
	margin-right: 6px !important;
}
.margin-top-6 {
	margin-top: 6px !important;
}
.margin-bottom-6 {
	margin-bottom: 6px !important;
}
.padding-6 {
	padding: 6px !important;
}
.padding-left-6 {
	padding-left: 6px !important;
}
.padding-right-6 {
	padding-right: 6px !important;
}
.padding-top-6 {
	padding-top: 6px !important;
}
.padding-bottom-6 {
	padding-bottom: 6px !important;
}
.margin-7 {
	margin: 7px !important;
}
.margin-left-7 {
	margin-left: 7px !important;
}
.margin-right-7 {
	margin-right: 7px !important;
}
.margin-top-7 {
	margin-top: 7px !important;
}
.margin-bottom-7 {
	margin-bottom: 7px !important;
}
.padding-7 {
	padding: 7px !important;
}
.padding-left-7 {
	padding-left: 7px !important;
}
.padding-right-7 {
	padding-right: 7px !important;
}
.padding-top-7 {
	padding-top: 7px !important;
}
.padding-bottom-7 {
	padding-bottom: 7px !important;
}
.margin-8 {
	margin: 8px !important;
}
.margin-left-8 {
	margin-left: 8px !important;
}
.margin-right-8 {
	margin-right: 8px !important;
}
.margin-top-8 {
	margin-top: 8px !important;
}
.margin-bottom-8 {
	margin-bottom: 8px !important;
}
.padding-8 {
	padding: 8px !important;
}
.padding-left-8 {
	padding-left: 8px !important;
}
.padding-right-8 {
	padding-right: 8px !important;
}
.padding-top-8 {
	padding-top: 8px !important;
}
.padding-bottom-8 {
	padding-bottom: 8px !important;
}
.margin-9 {
	margin: 9px !important;
}
.margin-left-9 {
	margin-left: 9px !important;
}
.margin-right-9 {
	margin-right: 9px !important;
}
.margin-top-9 {
	margin-top: 9px !important;
}
.margin-bottom-9 {
	margin-bottom: 9px !important;
}
.padding-9 {
	padding: 9px !important;
}
.padding-left-9 {
	padding-left: 9px !important;
}
.padding-right-9 {
	padding-right: 9px !important;
}
.padding-top-9 {
	padding-top: 9px !important;
}
.padding-bottom-9 {
	padding-bottom: 9px !important;
}
.margin-10 {
	margin: 10px !important;
}
.margin-left-10 {
	margin-left: 10px !important;
}
.margin-right-10 {
	margin-right: 10px !important;
}
.margin-top-10 {
	margin-top: 10px !important;
}
.margin-bottom-10 {
	margin-bottom: 10px !important;
}
.padding-10 {
	padding: 10px !important;
}
.padding-left-10 {
	padding-left: 10px !important;
}
.padding-right-10 {
	padding-right: 10px !important;
}
.padding-top-10 {
	padding-top: 10px !important;
}
.padding-bottom-10 {
	padding-bottom: 10px !important;
}
.margin-11 {
	margin: 11px !important;
}
.margin-left-11 {
	margin-left: 11px !important;
}
.margin-right-11 {
	margin-right: 11px !important;
}
.margin-top-11 {
	margin-top: 11px !important;
}
.margin-bottom-11 {
	margin-bottom: 11px !important;
}
.padding-11 {
	padding: 11px !important;
}
.padding-left-11 {
	padding-left: 11px !important;
}
.padding-right-11 {
	padding-right: 11px !important;
}
.padding-top-11 {
	padding-top: 11px !important;
}
.padding-bottom-11 {
	padding-bottom: 11px !important;
}
.margin-12 {
	margin: 12px !important;
}
.margin-left-12 {
	margin-left: 12px !important;
}
.margin-right-12 {
	margin-right: 12px !important;
}
.margin-top-12 {
	margin-top: 12px !important;
}
.margin-bottom-12 {
	margin-bottom: 12px !important;
}
.padding-12 {
	padding: 12px !important;
}
.padding-left-12 {
	padding-left: 12px !important;
}
.padding-right-12 {
	padding-right: 12px !important;
}
.padding-top-12 {
	padding-top: 12px !important;
}
.padding-bottom-12 {
	padding-bottom: 12px !important;
}
.margin-13 {
	margin: 13px !important;
}
.margin-left-13 {
	margin-left: 13px !important;
}
.margin-right-13 {
	margin-right: 13px !important;
}
.margin-top-13 {
	margin-top: 13px !important;
}
.margin-bottom-13 {
	margin-bottom: 13px !important;
}
.padding-13 {
	padding: 13px !important;
}
.padding-left-13 {
	padding-left: 13px !important;
}
.padding-right-13 {
	padding-right: 13px !important;
}
.padding-top-13 {
	padding-top: 13px !important;
}
.padding-bottom-13 {
	padding-bottom: 13px !important;
}
.margin-14 {
	margin: 14px !important;
}
.margin-left-14 {
	margin-left: 14px !important;
}
.margin-right-14 {
	margin-right: 14px !important;
}
.margin-top-14 {
	margin-top: 14px !important;
}
.margin-bottom-14 {
	margin-bottom: 14px !important;
}
.padding-14 {
	padding: 14px !important;
}
.padding-left-14 {
	padding-left: 14px !important;
}
.padding-right-14 {
	padding-right: 14px !important;
}
.padding-top-14 {
	padding-top: 14px !important;
}
.padding-bottom-14 {
	padding-bottom: 14px !important;
}
.margin-15 {
	margin: 15px !important;
}
.margin-left-15 {
	margin-left: 15px !important;
}
.margin-right-15 {
	margin-right: 15px !important;
}
.margin-top-15 {
	margin-top: 15px !important;
}
.margin-bottom-15 {
	margin-bottom: 15px !important;
}
.padding-15 {
	padding: 15px !important;
}
.padding-left-15 {
	padding-left: 15px !important;
}
.padding-right-15 {
	padding-right: 15px !important;
}
.padding-top-15 {
	padding-top: 15px !important;
}
.padding-bottom-15 {
	padding-bottom: 15px !important;
}
.margin-16 {
	margin: 16px !important;
}
.margin-left-16 {
	margin-left: 16px !important;
}
.margin-right-16 {
	margin-right: 16px !important;
}
.margin-top-16 {
	margin-top: 16px !important;
}
.margin-bottom-16 {
	margin-bottom: 16px !important;
}
.padding-16 {
	padding: 16px !important;
}
.padding-left-16 {
	padding-left: 16px !important;
}
.padding-right-16 {
	padding-right: 16px !important;
}
.padding-top-16 {
	padding-top: 16px !important;
}
.padding-bottom-16 {
	padding-bottom: 16px !important;
}
.margin-17 {
	margin: 17px !important;
}
.margin-left-17 {
	margin-left: 17px !important;
}
.margin-right-17 {
	margin-right: 17px !important;
}
.margin-top-17 {
	margin-top: 17px !important;
}
.margin-bottom-17 {
	margin-bottom: 17px !important;
}
.padding-17 {
	padding: 17px !important;
}
.padding-left-17 {
	padding-left: 17px !important;
}
.padding-right-17 {
	padding-right: 17px !important;
}
.padding-top-17 {
	padding-top: 17px !important;
}
.padding-bottom-17 {
	padding-bottom: 17px !important;
}
.margin-18 {
	margin: 18px !important;
}
.margin-left-18 {
	margin-left: 18px !important;
}
.margin-right-18 {
	margin-right: 18px !important;
}
.margin-top-18 {
	margin-top: 18px !important;
}
.margin-bottom-18 {
	margin-bottom: 18px !important;
}
.padding-18 {
	padding: 18px !important;
}
.padding-left-18 {
	padding-left: 18px !important;
}
.padding-right-18 {
	padding-right: 18px !important;
}
.padding-top-18 {
	padding-top: 18px !important;
}
.padding-bottom-18 {
	padding-bottom: 18px !important;
}
.margin-19 {
	margin: 19px !important;
}
.margin-left-19 {
	margin-left: 19px !important;
}
.margin-right-19 {
	margin-right: 19px !important;
}
.margin-top-19 {
	margin-top: 19px !important;
}
.margin-bottom-19 {
	margin-bottom: 19px !important;
}
.padding-19 {
	padding: 19px !important;
}
.padding-left-19 {
	padding-left: 19px !important;
}
.padding-right-19 {
	padding-right: 19px !important;
}
.padding-top-19 {
	padding-top: 19px !important;
}
.padding-bottom-19 {
	padding-bottom: 19px !important;
}
.margin-20 {
	margin: 20px !important;
}
.margin-left-20 {
	margin-left: 20px !important;
}
.margin-right-20 {
	margin-right: 20px !important;
}
.margin-top-20 {
	margin-top: 20px !important;
}
.margin-bottom-20 {
	margin-bottom: 20px !important;
}
.padding-20 {
	padding: 20px !important;
}
.padding-left-20 {
	padding-left: 20px !important;
}
.padding-right-20 {
	padding-right: 20px !important;
}
.padding-top-20 {
	padding-top: 20px !important;
}
.padding-bottom-20 {
	padding-bottom: 20px !important;
}
.margin-21 {
	margin: 21px !important;
}
.margin-left-21 {
	margin-left: 21px !important;
}
.margin-right-21 {
	margin-right: 21px !important;
}
.margin-top-21 {
	margin-top: 21px !important;
}
.margin-bottom-21 {
	margin-bottom: 21px !important;
}
.padding-21 {
	padding: 21px !important;
}
.padding-left-21 {
	padding-left: 21px !important;
}
.padding-right-21 {
	padding-right: 21px !important;
}
.padding-top-21 {
	padding-top: 21px !important;
}
.padding-bottom-21 {
	padding-bottom: 21px !important;
}
.margin-22 {
	margin: 22px !important;
}
.margin-left-22 {
	margin-left: 22px !important;
}
.margin-right-22 {
	margin-right: 22px !important;
}
.margin-top-22 {
	margin-top: 22px !important;
}
.margin-bottom-22 {
	margin-bottom: 22px !important;
}
.padding-22 {
	padding: 22px !important;
}
.padding-left-22 {
	padding-left: 22px !important;
}
.padding-right-22 {
	padding-right: 22px !important;
}
.padding-top-22 {
	padding-top: 22px !important;
}
.padding-bottom-22 {
	padding-bottom: 22px !important;
}
.margin-23 {
	margin: 23px !important;
}
.margin-left-23 {
	margin-left: 23px !important;
}
.margin-right-23 {
	margin-right: 23px !important;
}
.margin-top-23 {
	margin-top: 23px !important;
}
.margin-bottom-23 {
	margin-bottom: 23px !important;
}
.padding-23 {
	padding: 23px !important;
}
.padding-left-23 {
	padding-left: 23px !important;
}
.padding-right-23 {
	padding-right: 23px !important;
}
.padding-top-23 {
	padding-top: 23px !important;
}
.padding-bottom-23 {
	padding-bottom: 23px !important;
}
.margin-24 {
	margin: 24px !important;
}
.margin-left-24 {
	margin-left: 24px !important;
}
.margin-right-24 {
	margin-right: 24px !important;
}
.margin-top-24 {
	margin-top: 24px !important;
}
.margin-bottom-24 {
	margin-bottom: 24px !important;
}
.padding-24 {
	padding: 24px !important;
}
.padding-left-24 {
	padding-left: 24px !important;
}
.padding-right-24 {
	padding-right: 24px !important;
}
.padding-top-24 {
	padding-top: 24px !important;
}
.padding-bottom-24 {
	padding-bottom: 24px !important;
}
.margin-25 {
	margin: 25px !important;
}
.margin-left-25 {
	margin-left: 25px !important;
}
.margin-right-25 {
	margin-right: 25px !important;
}
.margin-top-25 {
	margin-top: 25px !important;
}
.margin-bottom-25 {
	margin-bottom: 25px !important;
}
.padding-25 {
	padding: 25px !important;
}
.padding-left-25 {
	padding-left: 25px !important;
}
.padding-right-25 {
	padding-right: 25px !important;
}
.padding-top-25 {
	padding-top: 25px !important;
}
.padding-bottom-25 {
	padding-bottom: 25px !important;
}
.margin-26 {
	margin: 26px !important;
}
.margin-left-26 {
	margin-left: 26px !important;
}
.margin-right-26 {
	margin-right: 26px !important;
}
.margin-top-26 {
	margin-top: 26px !important;
}
.margin-bottom-26 {
	margin-bottom: 26px !important;
}
.padding-26 {
	padding: 26px !important;
}
.padding-left-26 {
	padding-left: 26px !important;
}
.padding-right-26 {
	padding-right: 26px !important;
}
.padding-top-26 {
	padding-top: 26px !important;
}
.padding-bottom-26 {
	padding-bottom: 26px !important;
}
.margin-27 {
	margin: 27px !important;
}
.margin-left-27 {
	margin-left: 27px !important;
}
.margin-right-27 {
	margin-right: 27px !important;
}
.margin-top-27 {
	margin-top: 27px !important;
}
.margin-bottom-27 {
	margin-bottom: 27px !important;
}
.padding-27 {
	padding: 27px !important;
}
.padding-left-27 {
	padding-left: 27px !important;
}
.padding-right-27 {
	padding-right: 27px !important;
}
.padding-top-27 {
	padding-top: 27px !important;
}
.padding-bottom-27 {
	padding-bottom: 27px !important;
}
.margin-28 {
	margin: 28px !important;
}
.margin-left-28 {
	margin-left: 28px !important;
}
.margin-right-28 {
	margin-right: 28px !important;
}
.margin-top-28 {
	margin-top: 28px !important;
}
.margin-bottom-28 {
	margin-bottom: 28px !important;
}
.padding-28 {
	padding: 28px !important;
}
.padding-left-28 {
	padding-left: 28px !important;
}
.padding-right-28 {
	padding-right: 28px !important;
}
.padding-top-28 {
	padding-top: 28px !important;
}
.padding-bottom-28 {
	padding-bottom: 28px !important;
}
.margin-29 {
	margin: 29px !important;
}
.margin-left-29 {
	margin-left: 29px !important;
}
.margin-right-29 {
	margin-right: 29px !important;
}
.margin-top-29 {
	margin-top: 29px !important;
}
.margin-bottom-29 {
	margin-bottom: 29px !important;
}
.padding-29 {
	padding: 29px !important;
}
.padding-left-29 {
	padding-left: 29px !important;
}
.padding-right-29 {
	padding-right: 29px !important;
}
.padding-top-29 {
	padding-top: 29px !important;
}
.padding-bottom-29 {
	padding-bottom: 29px !important;
}
.margin-30 {
	margin: 30px !important;
}
.margin-left-30 {
	margin-left: 30px !important;
}
.margin-right-30 {
	margin-right: 30px !important;
}
.margin-top-30 {
	margin-top: 30px !important;
}
.margin-bottom-30 {
	margin-bottom: 30px !important;
}
.padding-30 {
	padding: 30px !important;
}
.padding-left-30 {
	padding-left: 30px !important;
}
.padding-right-30 {
	padding-right: 30px !important;
}
.padding-top-30 {
	padding-top: 30px !important;
}
.padding-bottom-30 {
	padding-bottom: 30px !important;
}
.margin-31 {
	margin: 31px !important;
}
.margin-left-31 {
	margin-left: 31px !important;
}
.margin-right-31 {
	margin-right: 31px !important;
}
.margin-top-31 {
	margin-top: 31px !important;
}
.margin-bottom-31 {
	margin-bottom: 31px !important;
}
.padding-31 {
	padding: 31px !important;
}
.padding-left-31 {
	padding-left: 31px !important;
}
.padding-right-31 {
	padding-right: 31px !important;
}
.padding-top-31 {
	padding-top: 31px !important;
}
.padding-bottom-31 {
	padding-bottom: 31px !important;
}
.margin-32 {
	margin: 32px !important;
}
.margin-left-32 {
	margin-left: 32px !important;
}
.margin-right-32 {
	margin-right: 32px !important;
}
.margin-top-32 {
	margin-top: 32px !important;
}
.margin-bottom-32 {
	margin-bottom: 32px !important;
}
.padding-32 {
	padding: 32px !important;
}
.padding-left-32 {
	padding-left: 32px !important;
}
.padding-right-32 {
	padding-right: 32px !important;
}
.padding-top-32 {
	padding-top: 32px !important;
}
.padding-bottom-32 {
	padding-bottom: 32px !important;
}
.margin-33 {
	margin: 33px !important;
}
.margin-left-33 {
	margin-left: 33px !important;
}
.margin-right-33 {
	margin-right: 33px !important;
}
.margin-top-33 {
	margin-top: 33px !important;
}
.margin-bottom-33 {
	margin-bottom: 33px !important;
}
.padding-33 {
	padding: 33px !important;
}
.padding-left-33 {
	padding-left: 33px !important;
}
.padding-right-33 {
	padding-right: 33px !important;
}
.padding-top-33 {
	padding-top: 33px !important;
}
.padding-bottom-33 {
	padding-bottom: 33px !important;
}
.margin-34 {
	margin: 34px !important;
}
.margin-left-34 {
	margin-left: 34px !important;
}
.margin-right-34 {
	margin-right: 34px !important;
}
.margin-top-34 {
	margin-top: 34px !important;
}
.margin-bottom-34 {
	margin-bottom: 34px !important;
}
.padding-34 {
	padding: 34px !important;
}
.padding-left-34 {
	padding-left: 34px !important;
}
.padding-right-34 {
	padding-right: 34px !important;
}
.padding-top-34 {
	padding-top: 34px !important;
}
.padding-bottom-34 {
	padding-bottom: 34px !important;
}
.margin-35 {
	margin: 35px !important;
}
.margin-left-35 {
	margin-left: 35px !important;
}
.margin-right-35 {
	margin-right: 35px !important;
}
.margin-top-35 {
	margin-top: 35px !important;
}
.margin-bottom-35 {
	margin-bottom: 35px !important;
}
.padding-35 {
	padding: 35px !important;
}
.padding-left-35 {
	padding-left: 35px !important;
}
.padding-right-35 {
	padding-right: 35px !important;
}
.padding-top-35 {
	padding-top: 35px !important;
}
.padding-bottom-35 {
	padding-bottom: 35px !important;
}
.margin-36 {
	margin: 36px !important;
}
.margin-left-36 {
	margin-left: 36px !important;
}
.margin-right-36 {
	margin-right: 36px !important;
}
.margin-top-36 {
	margin-top: 36px !important;
}
.margin-bottom-36 {
	margin-bottom: 36px !important;
}
.padding-36 {
	padding: 36px !important;
}
.padding-left-36 {
	padding-left: 36px !important;
}
.padding-right-36 {
	padding-right: 36px !important;
}
.padding-top-36 {
	padding-top: 36px !important;
}
.padding-bottom-36 {
	padding-bottom: 36px !important;
}
.margin-37 {
	margin: 37px !important;
}
.margin-left-37 {
	margin-left: 37px !important;
}
.margin-right-37 {
	margin-right: 37px !important;
}
.margin-top-37 {
	margin-top: 37px !important;
}
.margin-bottom-37 {
	margin-bottom: 37px !important;
}
.padding-37 {
	padding: 37px !important;
}
.padding-left-37 {
	padding-left: 37px !important;
}
.padding-right-37 {
	padding-right: 37px !important;
}
.padding-top-37 {
	padding-top: 37px !important;
}
.padding-bottom-37 {
	padding-bottom: 37px !important;
}
.margin-38 {
	margin: 38px !important;
}
.margin-left-38 {
	margin-left: 38px !important;
}
.margin-right-38 {
	margin-right: 38px !important;
}
.margin-top-38 {
	margin-top: 38px !important;
}
.margin-bottom-38 {
	margin-bottom: 38px !important;
}
.padding-38 {
	padding: 38px !important;
}
.padding-left-38 {
	padding-left: 38px !important;
}
.padding-right-38 {
	padding-right: 38px !important;
}
.padding-top-38 {
	padding-top: 38px !important;
}
.padding-bottom-38 {
	padding-bottom: 38px !important;
}
.margin-39 {
	margin: 39px !important;
}
.margin-left-39 {
	margin-left: 39px !important;
}
.margin-right-39 {
	margin-right: 39px !important;
}
.margin-top-39 {
	margin-top: 39px !important;
}
.margin-bottom-39 {
	margin-bottom: 39px !important;
}
.padding-39 {
	padding: 39px !important;
}
.padding-left-39 {
	padding-left: 39px !important;
}
.padding-right-39 {
	padding-right: 39px !important;
}
.padding-top-39 {
	padding-top: 39px !important;
}
.padding-bottom-39 {
	padding-bottom: 39px !important;
}
.margin-40 {
	margin: 40px !important;
}
.margin-left-40 {
	margin-left: 40px !important;
}
.margin-right-40 {
	margin-right: 40px !important;
}
.margin-top-40 {
	margin-top: 40px !important;
}
.margin-bottom-40 {
	margin-bottom: 40px !important;
}
.padding-40 {
	padding: 40px !important;
}
.padding-left-40 {
	padding-left: 40px !important;
}
.padding-right-40 {
	padding-right: 40px !important;
}
.padding-top-40 {
	padding-top: 40px !important;
}
.padding-bottom-40 {
	padding-bottom: 40px !important;
}
.margin-41 {
	margin: 41px !important;
}
.margin-left-41 {
	margin-left: 41px !important;
}
.margin-right-41 {
	margin-right: 41px !important;
}
.margin-top-41 {
	margin-top: 41px !important;
}
.margin-bottom-41 {
	margin-bottom: 41px !important;
}
.padding-41 {
	padding: 41px !important;
}
.padding-left-41 {
	padding-left: 41px !important;
}
.padding-right-41 {
	padding-right: 41px !important;
}
.padding-top-41 {
	padding-top: 41px !important;
}
.padding-bottom-41 {
	padding-bottom: 41px !important;
}
.margin-42 {
	margin: 42px !important;
}
.margin-left-42 {
	margin-left: 42px !important;
}
.margin-right-42 {
	margin-right: 42px !important;
}
.margin-top-42 {
	margin-top: 42px !important;
}
.margin-bottom-42 {
	margin-bottom: 42px !important;
}
.padding-42 {
	padding: 42px !important;
}
.padding-left-42 {
	padding-left: 42px !important;
}
.padding-right-42 {
	padding-right: 42px !important;
}
.padding-top-42 {
	padding-top: 42px !important;
}
.padding-bottom-42 {
	padding-bottom: 42px !important;
}
.margin-43 {
	margin: 43px !important;
}
.margin-left-43 {
	margin-left: 43px !important;
}
.margin-right-43 {
	margin-right: 43px !important;
}
.margin-top-43 {
	margin-top: 43px !important;
}
.margin-bottom-43 {
	margin-bottom: 43px !important;
}
.padding-43 {
	padding: 43px !important;
}
.padding-left-43 {
	padding-left: 43px !important;
}
.padding-right-43 {
	padding-right: 43px !important;
}
.padding-top-43 {
	padding-top: 43px !important;
}
.padding-bottom-43 {
	padding-bottom: 43px !important;
}
.margin-44 {
	margin: 44px !important;
}
.margin-left-44 {
	margin-left: 44px !important;
}
.margin-right-44 {
	margin-right: 44px !important;
}
.margin-top-44 {
	margin-top: 44px !important;
}
.margin-bottom-44 {
	margin-bottom: 44px !important;
}
.padding-44 {
	padding: 44px !important;
}
.padding-left-44 {
	padding-left: 44px !important;
}
.padding-right-44 {
	padding-right: 44px !important;
}
.padding-top-44 {
	padding-top: 44px !important;
}
.padding-bottom-44 {
	padding-bottom: 44px !important;
}
.margin-45 {
	margin: 45px !important;
}
.margin-left-45 {
	margin-left: 45px !important;
}
.margin-right-45 {
	margin-right: 45px !important;
}
.margin-top-45 {
	margin-top: 45px !important;
}
.margin-bottom-45 {
	margin-bottom: 45px !important;
}
.padding-45 {
	padding: 45px !important;
}
.padding-left-45 {
	padding-left: 45px !important;
}
.padding-right-45 {
	padding-right: 45px !important;
}
.padding-top-45 {
	padding-top: 45px !important;
}
.padding-bottom-45 {
	padding-bottom: 45px !important;
}
.margin-46 {
	margin: 46px !important;
}
.margin-left-46 {
	margin-left: 46px !important;
}
.margin-right-46 {
	margin-right: 46px !important;
}
.margin-top-46 {
	margin-top: 46px !important;
}
.margin-bottom-46 {
	margin-bottom: 46px !important;
}
.padding-46 {
	padding: 46px !important;
}
.padding-left-46 {
	padding-left: 46px !important;
}
.padding-right-46 {
	padding-right: 46px !important;
}
.padding-top-46 {
	padding-top: 46px !important;
}
.padding-bottom-46 {
	padding-bottom: 46px !important;
}
.margin-47 {
	margin: 47px !important;
}
.margin-left-47 {
	margin-left: 47px !important;
}
.margin-right-47 {
	margin-right: 47px !important;
}
.margin-top-47 {
	margin-top: 47px !important;
}
.margin-bottom-47 {
	margin-bottom: 47px !important;
}
.padding-47 {
	padding: 47px !important;
}
.padding-left-47 {
	padding-left: 47px !important;
}
.padding-right-47 {
	padding-right: 47px !important;
}
.padding-top-47 {
	padding-top: 47px !important;
}
.padding-bottom-47 {
	padding-bottom: 47px !important;
}
.margin-48 {
	margin: 48px !important;
}
.margin-left-48 {
	margin-left: 48px !important;
}
.margin-right-48 {
	margin-right: 48px !important;
}
.margin-top-48 {
	margin-top: 48px !important;
}
.margin-bottom-48 {
	margin-bottom: 48px !important;
}
.padding-48 {
	padding: 48px !important;
}
.padding-left-48 {
	padding-left: 48px !important;
}
.padding-right-48 {
	padding-right: 48px !important;
}
.padding-top-48 {
	padding-top: 48px !important;
}
.padding-bottom-48 {
	padding-bottom: 48px !important;
}
.margin-49 {
	margin: 49px !important;
}
.margin-left-49 {
	margin-left: 49px !important;
}
.margin-right-49 {
	margin-right: 49px !important;
}
.margin-top-49 {
	margin-top: 49px !important;
}
.margin-bottom-49 {
	margin-bottom: 49px !important;
}
.padding-49 {
	padding: 49px !important;
}
.padding-left-49 {
	padding-left: 49px !important;
}
.padding-right-49 {
	padding-right: 49px !important;
}
.padding-top-49 {
	padding-top: 49px !important;
}
.padding-bottom-49 {
	padding-bottom: 49px !important;
}
.margin-50 {
	margin: 50px !important;
}
.margin-left-50 {
	margin-left: 50px !important;
}
.margin-right-50 {
	margin-right: 50px !important;
}
.margin-top-50 {
	margin-top: 50px !important;
}
.margin-bottom-50 {
	margin-bottom: 50px !important;
}
.padding-50 {
	padding: 50px !important;
}
.padding-left-50 {
	padding-left: 50px !important;
}
.padding-right-50 {
	padding-right: 50px !important;
}
.padding-top-50 {
	padding-top: 50px !important;
}
.padding-bottom-50 {
	padding-bottom: 50px !important;
}
.margin-51 {
	margin: 51px !important;
}
.margin-left-51 {
	margin-left: 51px !important;
}
.margin-right-51 {
	margin-right: 51px !important;
}
.margin-top-51 {
	margin-top: 51px !important;
}
.margin-bottom-51 {
	margin-bottom: 51px !important;
}
.padding-51 {
	padding: 51px !important;
}
.padding-left-51 {
	padding-left: 51px !important;
}
.padding-right-51 {
	padding-right: 51px !important;
}
.padding-top-51 {
	padding-top: 51px !important;
}
.padding-bottom-51 {
	padding-bottom: 51px !important;
}
.margin-52 {
	margin: 52px !important;
}
.margin-left-52 {
	margin-left: 52px !important;
}
.margin-right-52 {
	margin-right: 52px !important;
}
.margin-top-52 {
	margin-top: 52px !important;
}
.margin-bottom-52 {
	margin-bottom: 52px !important;
}
.padding-52 {
	padding: 52px !important;
}
.padding-left-52 {
	padding-left: 52px !important;
}
.padding-right-52 {
	padding-right: 52px !important;
}
.padding-top-52 {
	padding-top: 52px !important;
}
.padding-bottom-52 {
	padding-bottom: 52px !important;
}
.margin-53 {
	margin: 53px !important;
}
.margin-left-53 {
	margin-left: 53px !important;
}
.margin-right-53 {
	margin-right: 53px !important;
}
.margin-top-53 {
	margin-top: 53px !important;
}
.margin-bottom-53 {
	margin-bottom: 53px !important;
}
.padding-53 {
	padding: 53px !important;
}
.padding-left-53 {
	padding-left: 53px !important;
}
.padding-right-53 {
	padding-right: 53px !important;
}
.padding-top-53 {
	padding-top: 53px !important;
}
.padding-bottom-53 {
	padding-bottom: 53px !important;
}
.margin-54 {
	margin: 54px !important;
}
.margin-left-54 {
	margin-left: 54px !important;
}
.margin-right-54 {
	margin-right: 54px !important;
}
.margin-top-54 {
	margin-top: 54px !important;
}
.margin-bottom-54 {
	margin-bottom: 54px !important;
}
.padding-54 {
	padding: 54px !important;
}
.padding-left-54 {
	padding-left: 54px !important;
}
.padding-right-54 {
	padding-right: 54px !important;
}
.padding-top-54 {
	padding-top: 54px !important;
}
.padding-bottom-54 {
	padding-bottom: 54px !important;
}
.margin-55 {
	margin: 55px !important;
}
.margin-left-55 {
	margin-left: 55px !important;
}
.margin-right-55 {
	margin-right: 55px !important;
}
.margin-top-55 {
	margin-top: 55px !important;
}
.margin-bottom-55 {
	margin-bottom: 55px !important;
}
.padding-55 {
	padding: 55px !important;
}
.padding-left-55 {
	padding-left: 55px !important;
}
.padding-right-55 {
	padding-right: 55px !important;
}
.padding-top-55 {
	padding-top: 55px !important;
}
.padding-bottom-55 {
	padding-bottom: 55px !important;
}
.margin-56 {
	margin: 56px !important;
}
.margin-left-56 {
	margin-left: 56px !important;
}
.margin-right-56 {
	margin-right: 56px !important;
}
.margin-top-56 {
	margin-top: 56px !important;
}
.margin-bottom-56 {
	margin-bottom: 56px !important;
}
.padding-56 {
	padding: 56px !important;
}
.padding-left-56 {
	padding-left: 56px !important;
}
.padding-right-56 {
	padding-right: 56px !important;
}
.padding-top-56 {
	padding-top: 56px !important;
}
.padding-bottom-56 {
	padding-bottom: 56px !important;
}
.margin-57 {
	margin: 57px !important;
}
.margin-left-57 {
	margin-left: 57px !important;
}
.margin-right-57 {
	margin-right: 57px !important;
}
.margin-top-57 {
	margin-top: 57px !important;
}
.margin-bottom-57 {
	margin-bottom: 57px !important;
}
.padding-57 {
	padding: 57px !important;
}
.padding-left-57 {
	padding-left: 57px !important;
}
.padding-right-57 {
	padding-right: 57px !important;
}
.padding-top-57 {
	padding-top: 57px !important;
}
.padding-bottom-57 {
	padding-bottom: 57px !important;
}
.margin-58 {
	margin: 58px !important;
}
.margin-left-58 {
	margin-left: 58px !important;
}
.margin-right-58 {
	margin-right: 58px !important;
}
.margin-top-58 {
	margin-top: 58px !important;
}
.margin-bottom-58 {
	margin-bottom: 58px !important;
}
.padding-58 {
	padding: 58px !important;
}
.padding-left-58 {
	padding-left: 58px !important;
}
.padding-right-58 {
	padding-right: 58px !important;
}
.padding-top-58 {
	padding-top: 58px !important;
}
.padding-bottom-58 {
	padding-bottom: 58px !important;
}
.margin-59 {
	margin: 59px !important;
}
.margin-left-59 {
	margin-left: 59px !important;
}
.margin-right-59 {
	margin-right: 59px !important;
}
.margin-top-59 {
	margin-top: 59px !important;
}
.margin-bottom-59 {
	margin-bottom: 59px !important;
}
.padding-59 {
	padding: 59px !important;
}
.padding-left-59 {
	padding-left: 59px !important;
}
.padding-right-59 {
	padding-right: 59px !important;
}
.padding-top-59 {
	padding-top: 59px !important;
}
.padding-bottom-59 {
	padding-bottom: 59px !important;
}
.margin-60 {
	margin: 60px !important;
}
.margin-left-60 {
	margin-left: 60px !important;
}
.margin-right-60 {
	margin-right: 60px !important;
}
.margin-top-60 {
	margin-top: 60px !important;
}
.margin-bottom-60 {
	margin-bottom: 60px !important;
}
.padding-60 {
	padding: 60px !important;
}
.padding-left-60 {
	padding-left: 60px !important;
}
.padding-right-60 {
	padding-right: 60px !important;
}
.padding-top-60 {
	padding-top: 60px !important;
}
.padding-bottom-60 {
	padding-bottom: 60px !important;
}
.margin-61 {
	margin: 61px !important;
}
.margin-left-61 {
	margin-left: 61px !important;
}
.margin-right-61 {
	margin-right: 61px !important;
}
.margin-top-61 {
	margin-top: 61px !important;
}
.margin-bottom-61 {
	margin-bottom: 61px !important;
}
.padding-61 {
	padding: 61px !important;
}
.padding-left-61 {
	padding-left: 61px !important;
}
.padding-right-61 {
	padding-right: 61px !important;
}
.padding-top-61 {
	padding-top: 61px !important;
}
.padding-bottom-61 {
	padding-bottom: 61px !important;
}
.margin-62 {
	margin: 62px !important;
}
.margin-left-62 {
	margin-left: 62px !important;
}
.margin-right-62 {
	margin-right: 62px !important;
}
.margin-top-62 {
	margin-top: 62px !important;
}
.margin-bottom-62 {
	margin-bottom: 62px !important;
}
.padding-62 {
	padding: 62px !important;
}
.padding-left-62 {
	padding-left: 62px !important;
}
.padding-right-62 {
	padding-right: 62px !important;
}
.padding-top-62 {
	padding-top: 62px !important;
}
.padding-bottom-62 {
	padding-bottom: 62px !important;
}
.margin-63 {
	margin: 63px !important;
}
.margin-left-63 {
	margin-left: 63px !important;
}
.margin-right-63 {
	margin-right: 63px !important;
}
.margin-top-63 {
	margin-top: 63px !important;
}
.margin-bottom-63 {
	margin-bottom: 63px !important;
}
.padding-63 {
	padding: 63px !important;
}
.padding-left-63 {
	padding-left: 63px !important;
}
.padding-right-63 {
	padding-right: 63px !important;
}
.padding-top-63 {
	padding-top: 63px !important;
}
.padding-bottom-63 {
	padding-bottom: 63px !important;
}
.margin-64 {
	margin: 64px !important;
}
.margin-left-64 {
	margin-left: 64px !important;
}
.margin-right-64 {
	margin-right: 64px !important;
}
.margin-top-64 {
	margin-top: 64px !important;
}
.margin-bottom-64 {
	margin-bottom: 64px !important;
}
.padding-64 {
	padding: 64px !important;
}
.padding-left-64 {
	padding-left: 64px !important;
}
.padding-right-64 {
	padding-right: 64px !important;
}
.padding-top-64 {
	padding-top: 64px !important;
}
.padding-bottom-64 {
	padding-bottom: 64px !important;
}
.margin-65 {
	margin: 65px !important;
}
.margin-left-65 {
	margin-left: 65px !important;
}
.margin-right-65 {
	margin-right: 65px !important;
}
.margin-top-65 {
	margin-top: 65px !important;
}
.margin-bottom-65 {
	margin-bottom: 65px !important;
}
.padding-65 {
	padding: 65px !important;
}
.padding-left-65 {
	padding-left: 65px !important;
}
.padding-right-65 {
	padding-right: 65px !important;
}
.padding-top-65 {
	padding-top: 65px !important;
}
.padding-bottom-65 {
	padding-bottom: 65px !important;
}
.margin-66 {
	margin: 66px !important;
}
.margin-left-66 {
	margin-left: 66px !important;
}
.margin-right-66 {
	margin-right: 66px !important;
}
.margin-top-66 {
	margin-top: 66px !important;
}
.margin-bottom-66 {
	margin-bottom: 66px !important;
}
.padding-66 {
	padding: 66px !important;
}
.padding-left-66 {
	padding-left: 66px !important;
}
.padding-right-66 {
	padding-right: 66px !important;
}
.padding-top-66 {
	padding-top: 66px !important;
}
.padding-bottom-66 {
	padding-bottom: 66px !important;
}
.margin-67 {
	margin: 67px !important;
}
.margin-left-67 {
	margin-left: 67px !important;
}
.margin-right-67 {
	margin-right: 67px !important;
}
.margin-top-67 {
	margin-top: 67px !important;
}
.margin-bottom-67 {
	margin-bottom: 67px !important;
}
.padding-67 {
	padding: 67px !important;
}
.padding-left-67 {
	padding-left: 67px !important;
}
.padding-right-67 {
	padding-right: 67px !important;
}
.padding-top-67 {
	padding-top: 67px !important;
}
.padding-bottom-67 {
	padding-bottom: 67px !important;
}
.margin-68 {
	margin: 68px !important;
}
.margin-left-68 {
	margin-left: 68px !important;
}
.margin-right-68 {
	margin-right: 68px !important;
}
.margin-top-68 {
	margin-top: 68px !important;
}
.margin-bottom-68 {
	margin-bottom: 68px !important;
}
.padding-68 {
	padding: 68px !important;
}
.padding-left-68 {
	padding-left: 68px !important;
}
.padding-right-68 {
	padding-right: 68px !important;
}
.padding-top-68 {
	padding-top: 68px !important;
}
.padding-bottom-68 {
	padding-bottom: 68px !important;
}
.margin-69 {
	margin: 69px !important;
}
.margin-left-69 {
	margin-left: 69px !important;
}
.margin-right-69 {
	margin-right: 69px !important;
}
.margin-top-69 {
	margin-top: 69px !important;
}
.margin-bottom-69 {
	margin-bottom: 69px !important;
}
.padding-69 {
	padding: 69px !important;
}
.padding-left-69 {
	padding-left: 69px !important;
}
.padding-right-69 {
	padding-right: 69px !important;
}
.padding-top-69 {
	padding-top: 69px !important;
}
.padding-bottom-69 {
	padding-bottom: 69px !important;
}
.margin-70 {
	margin: 70px !important;
}
.margin-left-70 {
	margin-left: 70px !important;
}
.margin-right-70 {
	margin-right: 70px !important;
}
.margin-top-70 {
	margin-top: 70px !important;
}
.margin-bottom-70 {
	margin-bottom: 70px !important;
}
.padding-70 {
	padding: 70px !important;
}
.padding-left-70 {
	padding-left: 70px !important;
}
.padding-right-70 {
	padding-right: 70px !important;
}
.padding-top-70 {
	padding-top: 70px !important;
}
.padding-bottom-70 {
	padding-bottom: 70px !important;
}
.margin-71 {
	margin: 71px !important;
}
.margin-left-71 {
	margin-left: 71px !important;
}
.margin-right-71 {
	margin-right: 71px !important;
}
.margin-top-71 {
	margin-top: 71px !important;
}
.margin-bottom-71 {
	margin-bottom: 71px !important;
}
.padding-71 {
	padding: 71px !important;
}
.padding-left-71 {
	padding-left: 71px !important;
}
.padding-right-71 {
	padding-right: 71px !important;
}
.padding-top-71 {
	padding-top: 71px !important;
}
.padding-bottom-71 {
	padding-bottom: 71px !important;
}
.margin-72 {
	margin: 72px !important;
}
.margin-left-72 {
	margin-left: 72px !important;
}
.margin-right-72 {
	margin-right: 72px !important;
}
.margin-top-72 {
	margin-top: 72px !important;
}
.margin-bottom-72 {
	margin-bottom: 72px !important;
}
.padding-72 {
	padding: 72px !important;
}
.padding-left-72 {
	padding-left: 72px !important;
}
.padding-right-72 {
	padding-right: 72px !important;
}
.padding-top-72 {
	padding-top: 72px !important;
}
.padding-bottom-72 {
	padding-bottom: 72px !important;
}
.margin-73 {
	margin: 73px !important;
}
.margin-left-73 {
	margin-left: 73px !important;
}
.margin-right-73 {
	margin-right: 73px !important;
}
.margin-top-73 {
	margin-top: 73px !important;
}
.margin-bottom-73 {
	margin-bottom: 73px !important;
}
.padding-73 {
	padding: 73px !important;
}
.padding-left-73 {
	padding-left: 73px !important;
}
.padding-right-73 {
	padding-right: 73px !important;
}
.padding-top-73 {
	padding-top: 73px !important;
}
.padding-bottom-73 {
	padding-bottom: 73px !important;
}
.margin-74 {
	margin: 74px !important;
}
.margin-left-74 {
	margin-left: 74px !important;
}
.margin-right-74 {
	margin-right: 74px !important;
}
.margin-top-74 {
	margin-top: 74px !important;
}
.margin-bottom-74 {
	margin-bottom: 74px !important;
}
.padding-74 {
	padding: 74px !important;
}
.padding-left-74 {
	padding-left: 74px !important;
}
.padding-right-74 {
	padding-right: 74px !important;
}
.padding-top-74 {
	padding-top: 74px !important;
}
.padding-bottom-74 {
	padding-bottom: 74px !important;
}
.margin-75 {
	margin: 75px !important;
}
.margin-left-75 {
	margin-left: 75px !important;
}
.margin-right-75 {
	margin-right: 75px !important;
}
.margin-top-75 {
	margin-top: 75px !important;
}
.margin-bottom-75 {
	margin-bottom: 75px !important;
}
.padding-75 {
	padding: 75px !important;
}
.padding-left-75 {
	padding-left: 75px !important;
}
.padding-right-75 {
	padding-right: 75px !important;
}
.padding-top-75 {
	padding-top: 75px !important;
}
.padding-bottom-75 {
	padding-bottom: 75px !important;
}
.margin-76 {
	margin: 76px !important;
}
.margin-left-76 {
	margin-left: 76px !important;
}
.margin-right-76 {
	margin-right: 76px !important;
}
.margin-top-76 {
	margin-top: 76px !important;
}
.margin-bottom-76 {
	margin-bottom: 76px !important;
}
.padding-76 {
	padding: 76px !important;
}
.padding-left-76 {
	padding-left: 76px !important;
}
.padding-right-76 {
	padding-right: 76px !important;
}
.padding-top-76 {
	padding-top: 76px !important;
}
.padding-bottom-76 {
	padding-bottom: 76px !important;
}
.margin-77 {
	margin: 77px !important;
}
.margin-left-77 {
	margin-left: 77px !important;
}
.margin-right-77 {
	margin-right: 77px !important;
}
.margin-top-77 {
	margin-top: 77px !important;
}
.margin-bottom-77 {
	margin-bottom: 77px !important;
}
.padding-77 {
	padding: 77px !important;
}
.padding-left-77 {
	padding-left: 77px !important;
}
.padding-right-77 {
	padding-right: 77px !important;
}
.padding-top-77 {
	padding-top: 77px !important;
}
.padding-bottom-77 {
	padding-bottom: 77px !important;
}
.margin-78 {
	margin: 78px !important;
}
.margin-left-78 {
	margin-left: 78px !important;
}
.margin-right-78 {
	margin-right: 78px !important;
}
.margin-top-78 {
	margin-top: 78px !important;
}
.margin-bottom-78 {
	margin-bottom: 78px !important;
}
.padding-78 {
	padding: 78px !important;
}
.padding-left-78 {
	padding-left: 78px !important;
}
.padding-right-78 {
	padding-right: 78px !important;
}
.padding-top-78 {
	padding-top: 78px !important;
}
.padding-bottom-78 {
	padding-bottom: 78px !important;
}
.margin-79 {
	margin: 79px !important;
}
.margin-left-79 {
	margin-left: 79px !important;
}
.margin-right-79 {
	margin-right: 79px !important;
}
.margin-top-79 {
	margin-top: 79px !important;
}
.margin-bottom-79 {
	margin-bottom: 79px !important;
}
.padding-79 {
	padding: 79px !important;
}
.padding-left-79 {
	padding-left: 79px !important;
}
.padding-right-79 {
	padding-right: 79px !important;
}
.padding-top-79 {
	padding-top: 79px !important;
}
.padding-bottom-79 {
	padding-bottom: 79px !important;
}
.margin-80 {
	margin: 80px !important;
}
.margin-left-80 {
	margin-left: 80px !important;
}
.margin-right-80 {
	margin-right: 80px !important;
}
.margin-top-80 {
	margin-top: 80px !important;
}
.margin-bottom-80 {
	margin-bottom: 80px !important;
}
.padding-80 {
	padding: 80px !important;
}
.padding-left-80 {
	padding-left: 80px !important;
}
.padding-right-80 {
	padding-right: 80px !important;
}
.padding-top-80 {
	padding-top: 80px !important;
}
.padding-bottom-80 {
	padding-bottom: 80px !important;
}
.margin-81 {
	margin: 81px !important;
}
.margin-left-81 {
	margin-left: 81px !important;
}
.margin-right-81 {
	margin-right: 81px !important;
}
.margin-top-81 {
	margin-top: 81px !important;
}
.margin-bottom-81 {
	margin-bottom: 81px !important;
}
.padding-81 {
	padding: 81px !important;
}
.padding-left-81 {
	padding-left: 81px !important;
}
.padding-right-81 {
	padding-right: 81px !important;
}
.padding-top-81 {
	padding-top: 81px !important;
}
.padding-bottom-81 {
	padding-bottom: 81px !important;
}
.margin-82 {
	margin: 82px !important;
}
.margin-left-82 {
	margin-left: 82px !important;
}
.margin-right-82 {
	margin-right: 82px !important;
}
.margin-top-82 {
	margin-top: 82px !important;
}
.margin-bottom-82 {
	margin-bottom: 82px !important;
}
.padding-82 {
	padding: 82px !important;
}
.padding-left-82 {
	padding-left: 82px !important;
}
.padding-right-82 {
	padding-right: 82px !important;
}
.padding-top-82 {
	padding-top: 82px !important;
}
.padding-bottom-82 {
	padding-bottom: 82px !important;
}
.margin-83 {
	margin: 83px !important;
}
.margin-left-83 {
	margin-left: 83px !important;
}
.margin-right-83 {
	margin-right: 83px !important;
}
.margin-top-83 {
	margin-top: 83px !important;
}
.margin-bottom-83 {
	margin-bottom: 83px !important;
}
.padding-83 {
	padding: 83px !important;
}
.padding-left-83 {
	padding-left: 83px !important;
}
.padding-right-83 {
	padding-right: 83px !important;
}
.padding-top-83 {
	padding-top: 83px !important;
}
.padding-bottom-83 {
	padding-bottom: 83px !important;
}
.margin-84 {
	margin: 84px !important;
}
.margin-left-84 {
	margin-left: 84px !important;
}
.margin-right-84 {
	margin-right: 84px !important;
}
.margin-top-84 {
	margin-top: 84px !important;
}
.margin-bottom-84 {
	margin-bottom: 84px !important;
}
.padding-84 {
	padding: 84px !important;
}
.padding-left-84 {
	padding-left: 84px !important;
}
.padding-right-84 {
	padding-right: 84px !important;
}
.padding-top-84 {
	padding-top: 84px !important;
}
.padding-bottom-84 {
	padding-bottom: 84px !important;
}
.margin-85 {
	margin: 85px !important;
}
.margin-left-85 {
	margin-left: 85px !important;
}
.margin-right-85 {
	margin-right: 85px !important;
}
.margin-top-85 {
	margin-top: 85px !important;
}
.margin-bottom-85 {
	margin-bottom: 85px !important;
}
.padding-85 {
	padding: 85px !important;
}
.padding-left-85 {
	padding-left: 85px !important;
}
.padding-right-85 {
	padding-right: 85px !important;
}
.padding-top-85 {
	padding-top: 85px !important;
}
.padding-bottom-85 {
	padding-bottom: 85px !important;
}
.margin-86 {
	margin: 86px !important;
}
.margin-left-86 {
	margin-left: 86px !important;
}
.margin-right-86 {
	margin-right: 86px !important;
}
.margin-top-86 {
	margin-top: 86px !important;
}
.margin-bottom-86 {
	margin-bottom: 86px !important;
}
.padding-86 {
	padding: 86px !important;
}
.padding-left-86 {
	padding-left: 86px !important;
}
.padding-right-86 {
	padding-right: 86px !important;
}
.padding-top-86 {
	padding-top: 86px !important;
}
.padding-bottom-86 {
	padding-bottom: 86px !important;
}
.margin-87 {
	margin: 87px !important;
}
.margin-left-87 {
	margin-left: 87px !important;
}
.margin-right-87 {
	margin-right: 87px !important;
}
.margin-top-87 {
	margin-top: 87px !important;
}
.margin-bottom-87 {
	margin-bottom: 87px !important;
}
.padding-87 {
	padding: 87px !important;
}
.padding-left-87 {
	padding-left: 87px !important;
}
.padding-right-87 {
	padding-right: 87px !important;
}
.padding-top-87 {
	padding-top: 87px !important;
}
.padding-bottom-87 {
	padding-bottom: 87px !important;
}
.margin-88 {
	margin: 88px !important;
}
.margin-left-88 {
	margin-left: 88px !important;
}
.margin-right-88 {
	margin-right: 88px !important;
}
.margin-top-88 {
	margin-top: 88px !important;
}
.margin-bottom-88 {
	margin-bottom: 88px !important;
}
.padding-88 {
	padding: 88px !important;
}
.padding-left-88 {
	padding-left: 88px !important;
}
.padding-right-88 {
	padding-right: 88px !important;
}
.padding-top-88 {
	padding-top: 88px !important;
}
.padding-bottom-88 {
	padding-bottom: 88px !important;
}
.margin-89 {
	margin: 89px !important;
}
.margin-left-89 {
	margin-left: 89px !important;
}
.margin-right-89 {
	margin-right: 89px !important;
}
.margin-top-89 {
	margin-top: 89px !important;
}
.margin-bottom-89 {
	margin-bottom: 89px !important;
}
.padding-89 {
	padding: 89px !important;
}
.padding-left-89 {
	padding-left: 89px !important;
}
.padding-right-89 {
	padding-right: 89px !important;
}
.padding-top-89 {
	padding-top: 89px !important;
}
.padding-bottom-89 {
	padding-bottom: 89px !important;
}
.margin-90 {
	margin: 90px !important;
}
.margin-left-90 {
	margin-left: 90px !important;
}
.margin-right-90 {
	margin-right: 90px !important;
}
.margin-top-90 {
	margin-top: 90px !important;
}
.margin-bottom-90 {
	margin-bottom: 90px !important;
}
.padding-90 {
	padding: 90px !important;
}
.padding-left-90 {
	padding-left: 90px !important;
}
.padding-right-90 {
	padding-right: 90px !important;
}
.padding-top-90 {
	padding-top: 90px !important;
}
.padding-bottom-90 {
	padding-bottom: 90px !important;
}
.margin-91 {
	margin: 91px !important;
}
.margin-left-91 {
	margin-left: 91px !important;
}
.margin-right-91 {
	margin-right: 91px !important;
}
.margin-top-91 {
	margin-top: 91px !important;
}
.margin-bottom-91 {
	margin-bottom: 91px !important;
}
.padding-91 {
	padding: 91px !important;
}
.padding-left-91 {
	padding-left: 91px !important;
}
.padding-right-91 {
	padding-right: 91px !important;
}
.padding-top-91 {
	padding-top: 91px !important;
}
.padding-bottom-91 {
	padding-bottom: 91px !important;
}
.margin-92 {
	margin: 92px !important;
}
.margin-left-92 {
	margin-left: 92px !important;
}
.margin-right-92 {
	margin-right: 92px !important;
}
.margin-top-92 {
	margin-top: 92px !important;
}
.margin-bottom-92 {
	margin-bottom: 92px !important;
}
.padding-92 {
	padding: 92px !important;
}
.padding-left-92 {
	padding-left: 92px !important;
}
.padding-right-92 {
	padding-right: 92px !important;
}
.padding-top-92 {
	padding-top: 92px !important;
}
.padding-bottom-92 {
	padding-bottom: 92px !important;
}
.margin-93 {
	margin: 93px !important;
}
.margin-left-93 {
	margin-left: 93px !important;
}
.margin-right-93 {
	margin-right: 93px !important;
}
.margin-top-93 {
	margin-top: 93px !important;
}
.margin-bottom-93 {
	margin-bottom: 93px !important;
}
.padding-93 {
	padding: 93px !important;
}
.padding-left-93 {
	padding-left: 93px !important;
}
.padding-right-93 {
	padding-right: 93px !important;
}
.padding-top-93 {
	padding-top: 93px !important;
}
.padding-bottom-93 {
	padding-bottom: 93px !important;
}
.margin-94 {
	margin: 94px !important;
}
.margin-left-94 {
	margin-left: 94px !important;
}
.margin-right-94 {
	margin-right: 94px !important;
}
.margin-top-94 {
	margin-top: 94px !important;
}
.margin-bottom-94 {
	margin-bottom: 94px !important;
}
.padding-94 {
	padding: 94px !important;
}
.padding-left-94 {
	padding-left: 94px !important;
}
.padding-right-94 {
	padding-right: 94px !important;
}
.padding-top-94 {
	padding-top: 94px !important;
}
.padding-bottom-94 {
	padding-bottom: 94px !important;
}
.margin-95 {
	margin: 95px !important;
}
.margin-left-95 {
	margin-left: 95px !important;
}
.margin-right-95 {
	margin-right: 95px !important;
}
.margin-top-95 {
	margin-top: 95px !important;
}
.margin-bottom-95 {
	margin-bottom: 95px !important;
}
.padding-95 {
	padding: 95px !important;
}
.padding-left-95 {
	padding-left: 95px !important;
}
.padding-right-95 {
	padding-right: 95px !important;
}
.padding-top-95 {
	padding-top: 95px !important;
}
.padding-bottom-95 {
	padding-bottom: 95px !important;
}
.margin-96 {
	margin: 96px !important;
}
.margin-left-96 {
	margin-left: 96px !important;
}
.margin-right-96 {
	margin-right: 96px !important;
}
.margin-top-96 {
	margin-top: 96px !important;
}
.margin-bottom-96 {
	margin-bottom: 96px !important;
}
.padding-96 {
	padding: 96px !important;
}
.padding-left-96 {
	padding-left: 96px !important;
}
.padding-right-96 {
	padding-right: 96px !important;
}
.padding-top-96 {
	padding-top: 96px !important;
}
.padding-bottom-96 {
	padding-bottom: 96px !important;
}
.margin-97 {
	margin: 97px !important;
}
.margin-left-97 {
	margin-left: 97px !important;
}
.margin-right-97 {
	margin-right: 97px !important;
}
.margin-top-97 {
	margin-top: 97px !important;
}
.margin-bottom-97 {
	margin-bottom: 97px !important;
}
.padding-97 {
	padding: 97px !important;
}
.padding-left-97 {
	padding-left: 97px !important;
}
.padding-right-97 {
	padding-right: 97px !important;
}
.padding-top-97 {
	padding-top: 97px !important;
}
.padding-bottom-97 {
	padding-bottom: 97px !important;
}
.margin-98 {
	margin: 98px !important;
}
.margin-left-98 {
	margin-left: 98px !important;
}
.margin-right-98 {
	margin-right: 98px !important;
}
.margin-top-98 {
	margin-top: 98px !important;
}
.margin-bottom-98 {
	margin-bottom: 98px !important;
}
.padding-98 {
	padding: 98px !important;
}
.padding-left-98 {
	padding-left: 98px !important;
}
.padding-right-98 {
	padding-right: 98px !important;
}
.padding-top-98 {
	padding-top: 98px !important;
}
.padding-bottom-98 {
	padding-bottom: 98px !important;
}
.margin-99 {
	margin: 99px !important;
}
.margin-left-99 {
	margin-left: 99px !important;
}
.margin-right-99 {
	margin-right: 99px !important;
}
.margin-top-99 {
	margin-top: 99px !important;
}
.margin-bottom-99 {
	margin-bottom: 99px !important;
}
.padding-99 {
	padding: 99px !important;
}
.padding-left-99 {
	padding-left: 99px !important;
}
.padding-right-99 {
	padding-right: 99px !important;
}
.padding-top-99 {
	padding-top: 99px !important;
}
.padding-bottom-99 {
	padding-bottom: 99px !important;
}
.margin-100 {
	margin: 100px !important;
}
.margin-left-100 {
	margin-left: 100px !important;
}
.margin-right-100 {
	margin-right: 100px !important;
}
.margin-top-100 {
	margin-top: 100px !important;
}
.margin-bottom-100 {
	margin-bottom: 100px !important;
}
.padding-100 {
	padding: 100px !important;
}
.padding-left-100 {
	padding-left: 100px !important;
}
.padding-right-100 {
	padding-right: 100px !important;
}
.padding-top-100 {
	padding-top: 100px !important;
}
.padding-bottom-100 {
	padding-bottom: 100px !important;
}
.padding-top-150 {
	padding-top: 150px !important;
}
.padding-bottom-150 {
	padding-bottom: 150px !important;
}
.padding-top-200 {
	padding-top: 200px !important;
}
.padding-bottom-200 {
	padding-bottom: 200px !important;
}
.margin-right-150 {
	margin-right: 150px !important;
}
.margin-left-150 {
	margin-left: 150px !important;
}
.letter-space-0 {
	letter-spacing: 0px !important;
}
.letter-space-1 {
	letter-spacing: 1px !important;
}
.letter-space-2 {
	letter-spacing: 2px !important;
}
.letter-space-3 {
	letter-spacing: 3px !important;
}
.letter-space-4 {
	letter-spacing: 4px !important;
}
.letter-space-5 {
	letter-spacing: 5px !important;
}
.letter-space-6 {
	letter-spacing: 6px !important;
}
.letter-space-7 {
	letter-spacing: 7px !important;
}
.letter-space-8 {
	letter-spacing: 8px !important;
}
.letter-space-9 {
	letter-spacing: 9px !important;
}
.letter-space-10 {
	letter-spacing: 10px !important;
}
/*=======================================================
			Style Switcher
========================================================*/
.color-switcher {
	width: 266px;
	position: fixed;
	right: -266px;
	top: 150px;
	z-index: 999;
	padding: 0px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	border-bottom-right-radius: 4px;
}
.color-switcher h5 {
	font-size: 15px;
	margin-top: 0;
	padding: 0 20px 0;
}
.color-switcher p {
	font-size: 16px;
	color: #000;
	line-height: 50px;
	margin: 0px;
	margin-top: 0 !important;
}
.color-switcher ul {
	list-style: none;
	padding: 0;
}
.color-switcher ul li {
	float: left;
}
.color-switcher ul li a {
	display: block;
	width: 44px;
	height: 44px;
	outline: none;
	text-align: center;
	line-height: 44px;
	font-size: 14px;
	color: #333;
}
.color-switcher ul li a.blue {
	background: #428bca;
}
.color-switcher ul li a.green {
	background: #88c136;
}
.color-switcher ul li a.red {
	background: #e54242;
}
.color-switcher ul li a.yellow {
	background: #ff9c00;
}
.color-switcher ul li a.brown {
	background: #987654;
}
.color-switcher ul li a.cyan {
	background: #1ABC9C;
}
.color-switcher ul li a.purple {
	background: #c74a73;
}
.color-switcher ul li a.sky-blue {
	background: #00cdcd;
}
.color-switcher .gray {
	background: #656565;
}
/*=======================================================
			FLAT COLORS
========================================================*/
.color-switcher .peter-river {
	background: #3498DB;
}
.color-switcher .nephritis {
	background: #27AE60;
}
.color-switcher .amethyst {
	background: #9B59B6;
}
.color-switcher .wet-asphalt {
	background: #34495E;
}
.color-switcher .night-shade {
	background: #2C3E50;
}
.color-switcher .sun-flowers {
	background: #F1C40F;
}
.color-switcher .orange {
	background: #F39C12;
}
.color-switcher .carrot {
	background: #E67E22;
}
.color-switcher .alizarin {
	background: #E74C3C;
}
.color-switcher .sliver {
	background: #BDC3C7;
}
.color-switcher .green-sea {
	background: #16A085;
}
.color-switcher .black {
	background: #000;
	display: none;
}
.color-switcher .white {
	background: #fff;
	display: none;
}
.color-switcher .light {
	background: #fff;
	width: 60px;
	color: #000;
	text-align: center;
	float: left;
	border: 1px solid #666;
	margin-top: 15px;
	margin-right: 22px;
}
.color-switcher .dark {
	background: #272727;
	width: 60px;
	float: right;
	color: #fff;
	border: 1px solid #666;
	margin-top: 15px;
}
.picker_close {
	width: 60px;
	height: 60px;
	position: absolute;
	left: -60px;
	top: 0px;
	z-index: -1;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	text-align: center;
	background: #fff;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}
.picker_close i {
	font-size: 30px;
	line-height: 60px;
}
.position {
	right: 0;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.theme-colours, .layouts, .backgrounds, .choose-header, .choose-footer {
	display: table;
	text-align: left;
	padding-top: 0px;
	width: 100%;
	padding: 18px;
	background: #fff;
	padding-top: 0px;
}
.layouts a {
	display: inline-block;
	text-align: center;
	width: 50%;
	float: left;
	font-size: 10px;
	color: #000;
	margin-bottom: 20px;
	border: 1px solid #e7e7e7;
	padding: 10px 15px;
}
.layouts a:hover {
	background: #000;
	color: #fff;
	border-color: #000;
}
.layouts {
}
.styleswitcher {
	position: fixed;
	width: 120px;
	background: #ffffff;
	color: #595959;
	top: 150px;
	right: -123px;
	z-index: 999;
}
.styleswitcher p {
	color: #000 !important;
}
.styleswitcher.ackapa {
	left: 0;
}
.styleswitcher .switch {
	padding-left: 15px;
	padding-bottom: 10px;
}
.styleswitcher .switch h5 {
	margin-top: 20px;
}
.styleswitcher .switch p {
	margin-top: 10px;
}
.stylebutton {
	cursor: pointer;
	position: absolute;
	text-align: center;
	line-height: 40px;
	background: #fff;
	font-size: 20px;
	width: 40px;
	height: 40px;
	top: 0px;
	right: -40px;
}
.styleswitcher a {
	width: 24px;
	height: 24px;
	float: left;
	margin-right: 7px;
	margin-bottom: 10px;
	position: relative;
	z-index: 99999999;
}
.color-switcher ul li {
	margin: 1px;
}
.color-switcher.position .theme-colours {
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
