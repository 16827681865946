/*------------------------------------------------------------------
    Main Style Stylesheet
    Project:        IOD - Corporate HTML5 Template
    Version:        1.0
    Author:         M_Adnan
    Last change:    22/11/2015
    Primary use:    Creative, Coporate, Business, Portfolio, Personal
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
body 
 + WRAP
 + HEADER
 + NAVIGATION
 + BANNER
 + SERVICES
 + OUR FEATURED FOUNDERS
 + BLOG UPDATES 
 + APPOINMENT
 + NEWS FROM BLOG
 + PARTNERS / CLIENTS
 + FOOTER
 + SOCIAL ICONS
 + MEGA MENU
 + 3 MENU LEVEL 
-------------------------------------------------------------------*/
/*=======================================================
      IMPORT FILE
========================================================*/
@import url("animate.css");
/*=======================================================
      THEME STYLING START
========================================================*/
* {
	margin: 0px;
	padding: 0px;
}
body {
	background: #FFF;
	font-weight: normal;
	position: relative;
	font-size: 14px;
	font-family: 'Montserrat', sans-serif;
}
/*=======================================================
      WRAPPER
========================================================*/
#wrap {
	position: relative;
	width: 100%;
	overflow: hidden;
}
/*=======================================================
      HEADINGS
========================================================*/
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	color: #252525;
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}
h1 {
	font-size: 50px;
}
h2 {
	font-size: 42px;
}
h3 {
	font-size: 36px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
	font-weight: bold;
}
p {
	font-family: inherit;
	font-weight: 400;
	color: #6f6f6f;
	line-height: 24px;
	text-rendering: optimizeLegibility;
	font-family: 'Open Sans', sans-serif;
}
a {
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	text-decoration: none !important;
}
img {
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
	display: inline-block !important;
}
ul {
	margin-bottom: 0px;
}
li {
	list-style: none;
}
a {
	text-decoration: none;
	color: #141414;
}
.font-open-sans {
	font-family: 'Open Sans', sans-serif;
}
.font-montserrat {
	font-family: 'Montserrat', sans-serif;
}
.font-hair-line {
	font-family: 'montserrathairline';
}
.font-light {
	font-family: 'montserratlight';
}
.font-regular {
	font-family: 'montserratregular';
}
.font-extra-bold {
	font-family: 'montserratbold';
}
.font-libre {
	font-family: 'Libre Baskerville', serif;
}
p.intro-small {
	font-style: italic;
	color: #f5f5f5;
	width: 80%;
	margin: 0 auto;
}
.text-transform-none {
	text-transform: none !important;
}
.text-white {
	color: #fff !important;
}
.text-bold {
	font-weight: bold !important;
}
.text-normal {
	font-weight: normal !important;
}
.font-bold {
	font-weight: bold !important;
}
.font-normal {
	font-weight: normal !important;
}
.white-text {
	color: #fff !important;
}
.font-italic {
	font-style: italic;
}
.font-bold {
	font-weight: bold !important;
}
a:hover, a:focus {
	color: #1193d4;
}
.primary-color {
	color: #1193d4 !important;
}
.position-center-center {
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.white-bg {
	background: #fff !important;
}
.relative {
	position: relative;
}
.line-height-24 {
	line-height: 24px !important;
}
.line-height-22 {
	line-height: 22px !important;
}
.line-height-26 {
	line-height: 26px !important;
}
.dark-text p {
	color: #141414;
}
.light-gray-bg {
	background: #f7f7f7 !important;
}
/*=======================================================
      BTN STYLE
========================================================*/
.btn {
	border: none;
	color: #fff;
	display: inline-block;
	border-radius: 4px;
	height: 40px;
	position: relative;
	overflow: hidden;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	padding: 0px;
	text-align:left;
	padding-right:55px;
	padding-left: 20px;
	line-height: 40px;
	background: #00b1f1;
	letter-spacing: 0.5px;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.btn i {
	color: #1193d4;
	background: #fff;
	height: 41px;
	padding: 0 15px;
	float: right;
	position:absolute;
	top:0px;
	right:0px;
	line-height: 41px;
	margin-left: 20px;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.btn:hover {
	background: #333333;
	color: #fff;
}
.btn:hover i {
	color: #333333;
}
.btn-1 i {
	background: #333333;
	color: #fff;
}
.btn-1:hover i {
	color: #fff;
	background: #00b1f1;
}
.btn-sm {
	height: 30px;
	line-height: 30px;
	padding-left: 10px;
	padding-right:35px;
}
.btn-sm i {
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	margin-left: 10px;
	border-radius:0 4px  4px 0;
}
.parallax-bg {
	background-size: cover !important;
}
/*=======================================================
      SECTION
========================================================*/
section {
	position: relative;
	overflow: hidden;
	background: #fff;
	margin-top:-5px;
}
/*=======================================================
      HEADING
========================================================*/
.heading {
	margin: 0 auto;
	margin-bottom: 60px;
}
.heading span {
	text-transform: uppercase;
	display: inline-block;
	color: #a5a5a5;
	margin-top: 5px;
}
.heading h4 {
	font-size: 24px;
	margin: 0px;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 1.5px;
}
.heading.white h4 {
	color: #fff;
}
.heading.white span {
	color: #fff;
}
/*=======================================================
      TOP BAR
========================================================*/
.top-bar {
}
.top-info {
	display: inline-block;
	width: 100%;
	float: left;
	background: #f9f9f9;
	height: 50px;
	padding-top: 10px;
	border-bottom:1px solid #e6e6e6;
}
.top-info .personal-info {
	color: #999999;
	float: left;
}
.top-info .personal-info p {
	color: #333333;
	margin: 0px;
	font-size: 14px;
	font-family: 'Montserrat', sans-serif;
}
.top-info .personal-info li {
	display: inline-block;
	border-right: 1px solid #ececec;
	padding: 1px 20px;
	float: left;
}
.top-info .personal-info li:nth-child(1) {
	padding-left: 0px;
}
.top-info .personal-info li:nth-last-child(1) {
	padding-right: 0px;
	border: none;
}
.top-info .personal-info li i {
	margin-right: 5px;
}
.top-info .social {
	float: right;
	margin: 0px;
	padding: 0px;
}
.top-info .social li {
	float: left;
	display: inline-block;
	margin: 0px;
	padding: 0px;
}
.top-info .social li a {
	width: 25px;
	height: 25px;
	line-height: 25px;
	padding: 0px;
	display: inline-block;
	text-align: center;
	color: #333333;
	font-size: 10px;
	border-radius: 4px;
	background: #e6e6e6;
	margin-left: 5px;
}
.top-info .social li a:hover {
	color: #fff;
	background: #1193d4;
}
.right-sec {
	float: right;
}
.right-sec .bootstrap-select {
	width: 90px !important;
	margin: 0px;
	margin-right: 15px;
}
.right-sec .bootstrap-select .btn {
	height: 25px;
	line-height: 22px;
}
.right-sec .bootstrap-select .filter-option {
	color: #333333;
}
.right-sec select {
	background: #e6e6e6;
	color: #333333;
	padding-left: 10px;
	text-transform: none;
	height: 26px;
	line-height: 26px;
}
.right-sec .dropdown-menu>li>a {
	padding: 5px 10px;
	font-size: 12px;
}
.right-sec .btn {
	background: #e6e6e6;
	color: #333333;
	padding-left: 10px;
	text-transform: none;
	height: 26px;
	line-height: 26px;
}
.right-sec .dropdown-menu {
	padding: 0px;
	border-radius: 0px;
	border: none;
}
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
	width: auto;
}
/*=======================================================
      HEADER
========================================================*/
header {
	position: relative;
	width: 100%;
	z-index: 9999;
	display: inline-block;
}
header .logo {
	float: left;
	position: absolute;
}
header .ownmenu {
	float: right;
	padding: 10px;
	padding-top: 0px;
	padding-right: 40px;
	padding-bottom: 0px;
	position: relative;
	z-index: 99;
}
header .navbar {
	float: left;
	width: 100%;
	margin: 0px;
	padding: 20px 0;
	border: none;
	display: inline-block;
	background: #fff;
	box-shadow:0 1px 3px rgba(0,0,0,0.2);
}
header .navbar li {
	display: inline-block;
	float: left;
	margin: 0 5px;
	position: relative;
	z-index: 999;
}
.ownmenu .indicator {
	display: none;
}
header .navbar li a {
	font-size: 12px;
	color: #333333;
	font-weight: bold;
	padding: 8px 12px;
	border-radius: 4px;
	letter-spacing: 0.5px;
	font-weight: 700;
	position: relative;
	z-index: 999;
	text-transform: uppercase;
}
header .navbar li a:hover {
	background: #1193d4;
	color: #fff;
}
header .navbar li.active a {
	background: #1193d4;
	color: #fff;
}
.ownmenu ul.dropdown {
	background: #333333;
	padding: 0px;
	top: 40px;
	border-radius: 4px;
}
.ownmenu ul.dropdown li ul.dropdown li a {
	letter-spacing: 0px;
	font-size: 13px;
}
.ownmenu ul.dropdown:before {
	position: absolute;
	content: "\f0d8";
	top: -25px;
	font-family: 'FontAwesome';
	font-size: 30px;
	color: #333333;
	left: 20px;
	height: 1px;
	width: 1px;
	right: 0px;
	z-index: -1;
}
.ownmenu ul.dropdown li {
	margin: 0px;
}
.ownmenu ul.dropdown li a {
	padding: 15px 20px 15px 20px;
	/* padding: 0px 20px; */
	font-size: 14px;
	font-weight: normal;
	line-height: 45px;
	border-radius: 0px;
	background:none;
	    color: #999;
	text-transform: none;
	border-bottom: 1px solid rgba(255,255,255,0.1);
	line-height: 20px;
}
.ownmenu ul.dropdown li a:hover{
	
}
.sticky-wrapper {
	height: auto !important;
}
.sticky-wrapper .sticky {
	width: 100%;
}
.is-sticky .sticky .logo {
	margin-top: 0px;
}
.is-sticky .sticky {
	padding-top: 15px;
	padding-bottom: 15px;
	background: rgba(255,255,255,0.9);
	box-shadow:0 1px 3px rgba(0,0,0,0.2);
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.search-icon {
	position: relative;
	z-index: 888;
}
.search-icon a {
	height: 32px;
	width: 32px;
	line-height: 32px;
	text-align: center;
	color: #333333;
	position: absolute;
	right: 0px;
	background: #e6e6e6;
	border-radius: 4px;
	display: inline-block;
}
.search-icon form {
	position: absolute;
	left: 0px;
	top: -12px;
	width: 0px;
	right: 0px;
	overflow: hidden;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.search-icon form input {
	height: 60px;
}
.search-icon form button {
	float: right;
	border: none;
	background: none;
	font-size: 20px;
	margin-top: -60px;
	z-index: 99;
	color: rgba(0,0,0,0.3);
	line-height: 60px;
	margin-right: 20px;
}
.search-icon:hover form {
	width: 100%;
}
.search-icon a:hover {
	background: #1193d4;
	color: #fff;
}
/*=======================================================
      HOME SLIDER
========================================================*/
.home-slide {
	position: relative;
	height: 460px;
}
.home-slide li {
	height: 460px;
	text-align: center;
	position: relative;
}
.home-slide li h1 {
	margin-bottom: 30px;
	font-size: 60px;
	font-family: 'montserratlight';
	letter-spacing:5px;
}
.home-slide li h5 {
	font-size: 18px;
	font-family: 'montserratlight';
	letter-spacing:9px;
}
.home-slide .slide-img-1 {
	background: url(../../../assets/images/slides/slide-1.jpg) no-repeat;
	background-size: cover;
}
.home-slide .slide-img-2 {
	background: url(../../../assets/images/slides/slide-2.jpg) center center no-repeat;
	background-size: cover;
}
.home-slide .flex-control-nav {
	position: absolute;
    z-index: 99;
    bottom: 10px;
    left: 0px;
    right: 0px;
    width: 100px;
    margin: 0 auto;
}
.home-slide .flex-control-nav li {
	height: auto;
}
.home-slide .flex-direction-nav {
	display: none;
}
.home-slide .position-center-center {
	width: 100%;
}
/*=======================================================
      SUB BANNER
========================================================*/
.sub-bnr {
	background: url(../../../assets/images/bg/sub-bnr-bg.jpg) no-repeat;
	background-size: cover;
	min-height: 380px;
	text-align: center;
}
.sub-bnr .position-center-center{
	width:100%;
}
.sub-bnr hr {
	margin: 20px auto;
}
.sub-bnr h1 {
	color: #fff;
	font-size: 52px;
	font-family: 'montserratlight';
	letter-spacing: 2px;
	margin: 0px;
}
.sub-bnr .breadcrumb {
	background: none;
	margin: 0px;
	padding: 0px;
	margin-top: 30px;
}
.sub-bnr .breadcrumb li {
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
}
.sub-bnr .breadcrumb li a {
	text-transform: uppercase;
	color: #1193d4;
	font-size: 12px;
}
.sub-bnr .breadcrumb>li+li:before {
	color: #fff;
}
.sub-bnr.bnr-2 {
	background: url(../../../assets/images/bg/sub-bnr-bg-1.jpg) no-repeat;
	background-size: cover;
	min-height: 155px;
	text-align: center;
}
.sub-bnr.bnr-2 h4 {
	color: #fff;
	margin: 0px;
	font-weight: normal;
}
.sub-bnr.bnr-2 .breadcrumb {
	margin-top: 10px;
}
/*=======================================================
      SERVICES
========================================================*/
.services .icon {
	height: 60px;
	width: 60px;
	border: 2px solid #fff;
	background: #00b1f1;
	line-height: 54px;
	text-align: center;
	margin-right: 10px;
}
.services article a {
	background: #f7f7f7;
	padding: 60px 45px;
	border-bottom: 2px solid #e6e6e6;
	text-align: center;
	display: inline-block;
	width: 100%;
	margin-bottom: 30px;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.services article i {
	font-size: 60px;
	color: #1193d4;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.services article h5 {
	font-weight: normal;
	margin: 0px;
	margin-top: 20px;
	margin-bottom: 20px;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.services li article p {
	font-size: 14px;
	line-height: 24px;
	margin: 0px;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.services h6 {
	color: #fff;
	font-size: 14px;
	margin-top: 0px;
	font-weight: 600;
	margin-bottom: 7px;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.services li:hover article a {
	background: #1193d4;
	color: #fff;
}
.services li:hover article a i {
	color: #fff;
}
.services li:hover article a p {
	color: #fff;
}
.services li:hover article a h5 {
	color: #fff;
}
.colio-active-item {
}
.services li.colio-active-item article a {
	background: #1193d4;
	color: #fff;
}
.services li.colio-active-item article a i {
	color: #fff;
}
.services li.colio-active-item article a p {
	color: #fff;
}
.services li.colio-active-item article a h5 {
	color: #fff;
}
.services li.colio-active-item:before {
	position: absolute;
	content: "\f0d7";
	bottom: -5px;
	font-family: 'FontAwesome';
	font-size: 50px;
	color: #1193d4;
	left: 0px;
	right: 0px;
	width: 100%;
	text-align: center;
}
.services-in .icon {
	height: 80px;
	width: 80px;
	display: inline-block;
	border-radius: 50%;
	text-align: center;
	color: #fff;
	font-size: 30px;
	line-height: 80px;
	background: #1193d4;
}
.services-in li {
	text-align: center;
}
.services-in li p {
	line-height: 24px;
}
.services-in li h5 {
	font-weight: normal;
	margin: 20px 0;
}
.services-in a.btn {
	color: #fff;
	font-style: normal;
	margin-top: 30px;
}
.services .col-md-3 article a {
	padding: 40px 25px;
}
.inside-colio .nav-stacked {
	background: #fff;
}
.inside-colio .nav-stacked li {
	font-style: normal;
	border: none;
	margin: 0px;
	padding: 0px;
}
.inside-colio .nav-stacked a {
	font-style: normal;
	border: none;
	margin: 0px;
	padding: 0px;
	font-size: 12px;
	padding: 10px 20px;
	border-radius: 0px;
	font-size: 12px;
	text-transform: uppercase;
	color: #333333;
	display: inline-block;
	width: 100%;
}
.inside-colio .nav-stacked a:hover {
	background: #1193d4;
	color: #fff;
}
.inside-colio .nav-stacked>li.active {
	background: #1193d4;
}
/* Colio - Basic Styles
================================================== */
.colio {
	position: relative;
	height: 0;
	clear: both;
	width: 100% !important;
	left: 0px !important;
	overflow: hidden;
	margin-bottom: 30px;
}
.colio .colio-container{
	width: 100%;
	position:relative;
	margin-top: 53px;
	background: url(../../../assets/images/bg/ser-bg.jpg) center center repeat-y;
	background-size: cover;
}
#demo_1{
}
.colio-container {
	height: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.colio-content {
	display: none;
	overflow: hidden;
}
.colio-close, .colio-prev, .colio-next {
	outline: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.colio-no-prev, .colio-no-next {
	cursor: default;
	opacity: 0.2;
}
/* navigation, close */
.colio-close {
	position: absolute;
	top: 10px;
	right: 10px;
}
.colio-navigation {
	position: absolute;
	top: 10px;
	left: 10px;
}
.colio-navigation a {
	margin-right: 5px;
}
/* placement */
.colio-placement-inside, .colio-placement-before {
	margin-bottom: 20px;
}
.colio-placement-after {
	margin-top: 20px;
	margin-bottom: 20px;
}
.colio-container {
}
.inside-colio {
	padding: 60px 0px;
}
.colio-theme-black .colio-navigation {
	left: 30px;
	top: 85px;
}
.colio-theme-black .colio-close{
	top: 85px;
}
/*=======================================================
      checkout
========================================================*/
.check-out {
}
.check-out h5 {
	text-transform: none;
	font-weight: normal;
	margin-top: 30px;
	font-size: 20px;
}
.check-out form {
}
.check-out li {
	margin-bottom: 10px;
}
.check-out label {
	width: 100%;
	font-weight: bold;
	margin-top: 10px;
}
.check-out label textarea {
	height: 190px;
	margin-top: 10px;
	font-weight: normal;
	border: 1px solid #d9d9d9;
}
.check-out label input {
	margin-top: 10px;
	box-shadow: none;
	height: 35px;
	font-weight: normal;
	border: 1px solid #d9d9d9;
}
.bootstrap-select {
	width: 100% !important;
	margin-top: 10px;
}
.bootstrap-select {
	width: 100%;
}
.bootstrap-select .btn {
	height: 35px;
	line-height: 33px;
	background: none;
	border: 1px solid #d9d9d9;
	color: #a9a9a9;
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
	font-size: 12px;
}
.bootstrap-select.btn-group .dropdown-menu {
	padding: 0px;
	border: none;
}
.check-out .panel-default {
	border-radius: 0px;
	margin-top: 20px;
}
.place-order {
	background: #f7f7f7;
	padding: 30px;
	margin-top: 72px;
	border-bottom: 2px solid #e6e6e6;
	display: inline-block;
	width: 100%;
}
.place-order img {
	margin-top: -10px;
}
.place-order hr {
	margin-top: 50px;
}
.check-out .radio label {
	font-size: 20px;
	font-weight: normal;
	margin-top: 0px;
}
.radio label::before {
	margin-top: 6px;
}
.radio {
	margin-bottom: 20px;
	margin-top: 0px;
}
.radio label::after {
	margin-top: 6px;
}
.check-out .note-bank {
	background: #eaeaea;
	padding: 20px;
	padding-bottom: 10px;
	margin-bottom: 20px;
}
.check-out .panel-default .panel-body {
	padding-bottom: 0px;
}
.check-out .panel-default li {
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 10px;
	color: #989898;
	font-family: 'Open Sans', sans-serif;
}
.check-out .panel-default li strong {
	color: #666666;
}
.check-out .panel-default li:nth-last-child(1) {
	padding-bottom: 0px;
	border: none;
}
.bootstrap-select.btn-group .dropdown-menu li {
	margin-bottom: 0px;
}
.coupen {
	background: #ffffcf;
	padding: 10px 20px;
	border: 1px dashed #ffd9b4;
}
.coupen p {
	margin: 0px;
}
.coupen a {
	color: #ff6600;
	font-weight: bold;
}
.coupen img {
	margin-right: 10px;
}
.oder-received {
}
.oder-received h6 {
	text-transform: none;
	color: #666666;
}
.oder-received .order-date {
}
.oder-received .order-date {
	background: #f7f7f7;
	padding: 30px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.oder-received .order-date p {
	margin-bottom: 0px;
}
.oder-received .order-date span {
	font-weight: bold;
	color: #333333;
	font-size: 20px;
	text-transform: uppercase;
}
/*=======================================================
      Presentation
========================================================*/
.presentation {
}
.presentation .heading h4 {
	margin: 20px 0;
}
.presentation .single-slide {
	background: url(../../../assets/images/bg/prestation-bg.png) center top no-repeat;
	padding: 30px;
	text-align: center;
}
.presentation .single-slide {
	max-width: 974px;
	margin: 0 auto;
	margin-bottom: 150px;
}
.presentation .owl-controls {
	background: #444444;
	display: inline-block;
	width: 100%;
	border-radius: 10px;
	margin-top: 50px;
	position: absolute;
	left: 0px;
	right: 0px;
	width: 100%;
	bottom: -50px;
}
.presentation .owl-dots {
	margin: 10px 0;
	margin-top: 12px;
}
.presentation .owl-dots span {
	border: none;
	background: rgba(255,255,255,0.3);
}
.presentation .owl-nav div {
	display: inline-block !important;
	position: absolute;
	color: #fff;
	width: 150px;
	margin: 0 auto;
	left: 0px;
	line-height: 42px;
	right: 0px;
}
.presentation .owl-prev {
	display: block !important;
	width: 150px;
	text-align: left;
}
.presentation .owl-next {
	display: block !important;
	width: 150px;
	text-align: right;
}
/*=======================================================
      WHO WE ARE
========================================================*/
.who-we {
}
.who-we h3 {
	margin-top: 0px;
	font-size: 24px;
}
.who-we h6 {
	font-size: 18px;
	font-family: 'Open Sans', sans-serif;
	color: #666666;
	margin-top: 30px;
	text-transform: none;
	font-weight: 600;
	font-style: italic;
	margin-bottom: 20px;
}
.who-we li {
	margin-bottom: 20px;
}
.who-we li h5 {
	font-weight: normal;
}
.who-we li i {
	font-size: 26px;
	color: #1193d4;
	margin-bottom: 10px;
}
/*=======================================================
      PORTFOLIO
========================================================*/
.portfolio {
}
.portfolio .col-3 .cbp-item {
	width: 33.3333%;
	float: left;
}
.portfolio .cbp-item article {
	position: relative;
	overflow: hidden;
}
.portfolio .cbp-item article:hover img {
	transform: scale(1.1);
}
.portfolio .cbp-item img {
	width: 100%;
}
.portfolio .top-detail {
	position: absolute;
	top: -100%;
	right: 30px;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.portfolio .bottom-detail {
	position: absolute;
	bottom: -100%;
	left: 30px;
	width: 38%;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.portfolio .bottom-detail span {
	font-weight: bold;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
}
.portfolio .bottom-detail h3 {
	color: #fff;
	font-size:30px;
}
.portfolio .top-detail a {
	display: inline-block;
	height: 60px;
	width: 60px;
	background: #fff;
	color: #d0d0d0;
	margin-left: 10px;
	line-height: 62px;
	text-align: center;
	border-bottom: 1px solid #136690;
	font-size: 16px;
}
.portfolio .top-detail a:hover {
	background: #1193d4;
	color: #fff;
}
.portfolio .over-detail {
	background: rgba(17,147,212,0.5);
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	opacity: 0;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.portfolio article:hover .top-detail {
	top: 30px;
}
.portfolio article:hover .bottom-detail {
	bottom: 30px;
}
.portfolio .cbp-item article:hover .over-detail {
	opacity: 1;
}
/*=======================================================
      PORTFOLIO COL
========================================================*/
.portfolio .col-3 .cbp-item {
	width: 33.3333%;
	float: left;
}
/*=======================================================
      FILTER STYLE
========================================================*/
#ajax-work-filter .cbp-filter-item {
	font-size: 12px;
	font-weight: bold;
	color: #1193d4;
	display: inline-block;
	text-transform: uppercase;
	padding: 5px 10px;
	border: 2px solid #1193d4;
	border-radius: 4px;
}
#ajax-work-filter .cbp-filter-item:hover {
	background: #1193d4;
	color: #fff;
}
#ajax-work-filter .cbp-filter-item-active {
	background: #1193d4;
	color: #fff;
}
.cbp-l-filters-buttonCenter .cbp-filter-counter {
	background: #1193d4;
}
.cbp-popup-singlePage .cbp-popup-close {
	background: none;
}
.cbp-popup-singlePage .cbp-popup-close:before {
	content: '\f2d7';
	font-family:"Ionicons";
	color: #333333;
	font-size: 30px;
}
.cbp-popup-singlePage .cbp-popup-prev {
	background: none;
}
.cbp-popup-singlePage .cbp-popup-prev:before {
	content: '\f3d2';
	font-family:"Ionicons";
	color: #333333;
	font-size: 30px;
}
.cbp-popup-singlePage .cbp-popup-next {
	background: none;
}
.cbp-popup-singlePage .cbp-popup-next:before {
	content: '\f3d3';
	font-family:"Ionicons";
	color: #333333;
	font-size: 30px;
}
.cbp-popup-lightbox .cbp-popup-prev:before {
    content: '\f3d2';
    font-family: "Ionicons";
    color: #fff;
    font-size: 30px;
}
.cbp-popup-lightbox .cbp-popup-next:before {
    content: '\f3d3';
    font-family: "Ionicons";
    color: #fff;
    font-size: 30px;
}
.cbp-popup-lightbox .cbp-popup-close:before {
	content: '\f2d7';
	font-family:"Ionicons";
	color: #fff;
	font-size: 30px;
}
.cbp-popup-singlePage .cbp-popup-navigation {
	position: relative;
	width: auto;
	height: 100%;
	max-width: 1170px;
	margin: 0 auto;
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
	background: #fff;
	box-shadow: 0 0 2px rgba(0,0,0,0.5);
}
.cbp-popup-singlePage .cbp-popup-next {
}
.cbp-popup-singlePage .cbp-popup-prev {
}
.cbp-popup-singlePage-counter {
	left: 0px;
	right: 0px;
	top: 35px;
	font-size:10px;
	color: #333333;
	font-family: 'Montserrat', sans-serif;
	opacity: 0.3;
}
/*=======================================================
      PLAN
========================================================*/
.plan {
}
.plan .price {
	font-size: 45px;
	padding: 0px;
	background: #1193d4;
	border-bottom: 1px solid #ececec;
	text-align: center;
	padding: 40px 0;
	color: #fff;
	font-weight: bold;
}
.plan .price h6 {
	color: #fff;
	margin-bottom: 20px;
}
.plan .price .currency {
	font-size: 20px;
	padding-right: 5px;
	vertical-align: top;
	color: #fff;
	font-weight: normal;
}
.plan .price .period {
	font-size: 14px;
	color: #bfc4c9;
	padding-left: 5px;
}
.plan article {
	background: #f4f4f4;
	border-bottom: 2px solid #e6e6e6;
	padding-bottom: 30px;
	text-align: center;
	margin-bottom: 30px;
}
.plan article p {
	margin: 0px;
	color: #999999;
	line-height: 40px;
	text-align: center;
}
.plan article p.light {
	background: #f9f9f9;
}
.plan article p span {
	color: #34495e;
}
/*=======================================================
      BLOG
========================================================*/
.blog {
}
.blog-slide{
	margin:0px;
}
.blog-pages .pagination{
	text-align:center;
}
.blog article {
	position: relative;
}
.blog iframe{
	border:none;
	width:100%;
	min-height:330px;
}
.blog .date {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 60px;
	color: #fff;
	font-size: 30px;
	background: #1193d4;
	float: left;
	text-align: center;
	font-weight: bold;
	line-height: 60px;
}
.blog .date span {
	background: #333333;
	display: inline-block;
	width: 100%;
	line-height: 18px;
	font-size: 12px;
	float: left;
	text-transform: uppercase;
}
.post-detail {
	text-align: center;
	padding: 20px 0;
}
.post-detail span {
	color: #d0d0d0;
	display: inline-block;
	width: 100%;
	font-size: 12px;
	margin: 10px 0;
	text-transform:uppercase;
}
.post-tittle {
	font-size: 18px;
	letter-spacing: 1px;
	display: inline-block;
	width: 100%;
	text-transform: uppercase;
}
.blog-pages .post-detail {
	text-align: left;
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 50px;
}
.blog-pages .post-detail .tags {
	margin-top: 20px;
}
.blog-pages .simple-text .post-detail {
	padding-top: 0px;
}
.blog-pages .simp-tittle {
	padding-left: 80px;
	margin-bottom: 30px;
	display: inline-block;
	width: 100%;
	padding-top: 15px;
} 
.blog .owl-nav{
	display:none;
}
.blog .owl-dot.active span{
	border-color:#1193d4;
}
/*=======================================================
      Features
========================================================*/
.features {
	box-shadow: inset 0 0px 4px rgba(0,0,0,0.2);
}
.list-style-featured .icon {
	height: 70px;
	width: 70px;
	line-height: 70px;
	text-align: center;
	color: #fff;
	background: #1193d4;
	border-radius: 50%;
	font-size: 20px;
	margin-right: 10px;
}
.list-style-featured p {
	line-height: 24px;
}
.list-style-featured li h6 {
	text-transform: none;
	font-size: 18px;
}
.list-style-featured li {
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
}
.list-style-featured .media-right .icon {
	margin-right: 0px;
	margin-left: 10px;
}
/*=======================================================
      TESTI
========================================================*/
.testi-bg {
	background: url(../../../assets/images/bg/bg-testi.jpg) center center fixed no-repeat;
	background-size: cover;
}
.testi {
	width: 83%;
	margin: 0 auto;
	text-align: center;
}
.testi p {
	font-size: 30px;
	font-style: italic;
	color: #333333;
	line-height: 48px;
	letter-spacing: 0px;
	font-family: 'Libre Baskerville', serif;
}
.testi h6 {
	text-transform: none;
	margin-top: 30px;
}
.testi h6 span {
	font-size: 12px;
}
/*=======================================================
      PAGINATION
========================================================*/
.pagination {
	border: none !important;
	display: inline-block;
	width: 100%;
	text-align: right;
	margin: 0 !important;
	text-align: right;
}
.pagination ul li {
	border: none;
	float: none;
	display: inline-block;
}
.pagination>li {
	display: inline-block;
}
.pagination>li>a, .pagination>li>span {
	border: none;
	padding: 0px;
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 30px;
	color: #777777;
	font-weight: normal;
	margin: 0px;
	background: none;
	border-radius: 4px;
	font-size: 14px;
}
.pagination>li>a:hover {
	background: #1193d4;
	color: #fff;
	border-color: #1193d4;
}
.pagination>li>a.current {
	background: #1193d4;
	color: #fff;
	border-color: #1193d4;
}
/*=======================================================
      JOB SECRION
========================================================*/
.job-sider-bar {
	background: #f7f7f7;
	padding: 35px;
	border-bottom: 2px solid #e6e6e6;
}
.job-sider-bar label {
	display: inline-block;
	width: 100%;
	font-weight: normal;
	font-size: 13px;
	margin-bottom: 10px;
	color: #999999;
	font-family: 'Open Sans', sans-serif;
}
.job-sider-bar label input {
	border: 1px solid #e6e6e6;
	height: 40px;
	padding: 0 20px;
	border-radius: 0px;
	width: 100%;
	color: #999999;
	border-radius: 4px;
	background: #fff;
	box-shadow: none !important;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.job-sider-bar h6 {
	text-transform: none;
	margin-top: 20px;
	font-size: 14px;
	color: #666666;
	font-family: 'Open Sans', sans-serif;
}
.admin-job {
	background: #f7f7f7;
	padding: 20px 35px;
	border-bottom: 2px solid #e6e6e6;
}
.admin-job h5 {
	margin-bottom: 0px;
	font-weight: 500;
	text-transform: none;
	font-weight: normal;
	margin-top: 0px;
	margin-bottom: 10px;
}
.admin-job p {
	margin: 0px;
	font-size: 12px;
	line-height: 18px;
	margin-top: 15px;
}
.admin-job .admin-img {
	width: 100px;
	display: inline-block;
	margin-right: 10px;
}
.job-sider-bar #map {
	height: 245px;
	width: 100%;
}
.job-info {
}
.job-info li {
	display: inline-block;
	width: 100%;
	margin-bottom: 5px;
}
.job-info p {
	text-align: right;
}
.job-info p strong {
	text-align: left;
	float: left;
	margin-right: 20px;
}
.job-content {
	background: #f7f7f7;
	padding: 35px;
	position: relative;
	border-bottom: 2px solid #e6e6e6;
}
.star-save {
	position: absolute;
	right: 0px;
	top: 0px;
}
.star-save a {
	height: 32px;
	width: 32px;
	color: #fff;
	display: inline-block;
	background: #cccccc;
	text-align: center;
	line-height: 32px;
	border-radius: 4px;
	margin-left: 3px;
}
.star-save a:hover {
	background: #1a1a1a;
}
.grid-layout {
	float: left;
	border: 1px solid #e6e6e6;
	padding: 2px;
	border-radius: 4px;
}
.grid-layout a {
	display: inline-block;
	height: 30px;
	width: 30px;
	color: #333333;
	text-align: center;
	line-height: 30px;
	border-radius: 4px;
}
.grid-layout a:hover {
	background: #333333;
	color: #fff;
}
.grid-layout a.current {
	background: #333333;
	color: #fff;
}
.short-by {
	width: 70%;
	float: right;
}
.short-by .bootstrap-select {
	margin-top: 0px;
}
.short-by label {
	width: 100%;
}
.filter-sidebar {
}
.filter-sidebar li {
	position: relative;
}
.filter-sidebar li {
}
.cate.result li {
	line-height: 32px;
}
.cate.result li:before {
	color: #1193d4;
}
.cate.result li a {
	color: #666666;
}
.job {
}
.job-content h5 {
	text-transform: none;
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 20px;
}
.job-content p {
	margin-bottom: 0px;
}
.job-skills ul {
}
.job-skills li {
	margin-top: 10px;
}
.job-skills h6 {
	text-transform: none;
	font-weight: normal;
	margin: 0px;
	font-size: 14px;
	letter-spacing: 0px;
	font-weight: bold;
}
.job-content .share-info h6 {
	font-size: 18px;
	display: inline-block;
	float: left;
	margin-top: 10px
}
.job-content .share-info .social {
	margin-top: 1px;
	float: left;
	margin-left: 10px;
}
.job-content .share-info .social li {
	float: left;
	display: inline-block;
	margin: 0px;
	padding: 0px;
}
.job-content .share-info .social li a {
	width: 25px;
	height: 25px;
	line-height: 25px;
	padding: 0px;
	display: inline-block;
	text-align: center;
	color: #333333;
	font-size: 10px;
	border-radius: 4px;
	background: #e6e6e6;
	margin-left: 5px;
}
.job-content .share-info .social li a:hover {
	color: #fff;
	background: #1193d4;
}
.job-skills .progress {
	height: 14px;
	box-shadow: none;
	background: #e5e5e5;
	margin-top: 5px;
	margin-bottom: 10px;
}
.job-skills .progress-bar {
	background: #31a8ec;
	box-shadow: none;
}
.job-skills li h6 i {
	color: #fff;
	margin-right: 5px;
	display: inline-block;
	height: 22px;
	width: 22px;
	border-radius: 4px;
	background: #31a8ec;
	color: #fff;
	text-align: center;
	line-height: 22px;
	font-size: 14px;
}
.job-tittle {
	margin-bottom: 0px;
	position: relative;
}
.job-tittle p {
	margin-top: 30px;
}
.job-tittle h5 {
	font-weight: bold;
	margin-bottom: 3px;
	margin-top: 5px;
	font-size: 18px;
	letter-spacing: 0px;
}
.job-tittle span {
	color: #999999;
	font-weight: bold;
	font-size: 12px;
	text-transform: uppercase;
}
.job-tittle .date {
}
.job-tittle .date {
	position: relative;
	top: 0px;
	left: 0px;
	width: 38px;
	color: #fff;
	font-size: 18px;
	background: #1193d4;
	float: left;
	text-align: center;
	font-weight: bold;
	line-height: 33px;
}
.job-tittle .date span {
	background: #333333;
	display: inline-block;
	width: 100%;
	line-height: 18px;
	font-size: 8px;
	float: left;
	color: #fff;
	text-transform: uppercase;
}
/*=======================================================
      PRICING RANGE
========================================================*/
.cost-price-content {
}
.cost-price-content h5 {
	margin-bottom: 30px;
}
.noUi-background {
	margin-bottom: 10px;
}
.cost-price-content .btn {
	padding: 6px 20px;
	font-size: 12px;
	font-weight: normal;
	color: #fff;
}
.cost-price-content {
	display: inline-block;
	position: relative;
	margin-top: 15px;
	width: 100%;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
}
.cost-price-content span {
	font-size: 12px;
	letter-spacing: 0px;
}
.price-min {
	position: absolute;
}
.price-max {
	position: absolute;
}
.price-min, .price-max {
	margin-top: 0px;
}
/*=======================================================
      social
========================================================*/
.social {
}
.social li {
	display: inline-block;
	margin: 0px;
	padding: 0px;
}
.social li a {
	width: 25px;
	height: 25px;
	line-height: 25px;
	padding: 0px;
	display: inline-block;
	text-align: center;
	color: #333333;
	font-size: 10px;
	border-radius: 4px;
	background: #e6e6e6;
	margin-left: 5px;
}
.social li a:hover {
	color: #fff;
	background: #1193d4;
}
/*=======================================================
      TEAM
========================================================*/
.team article {
	text-align: center;
	margin-bottom: 50px;
}
.team article span {
	color: #adadad;
	font-size: 14px;
	margin-bottom: 15px;
	display: inline-block;
}
.team article h5 {
	margin-top: 30px;
	margin-bottom: 5px;
	text-transform: none;
}
.team article .social li {
	display: inline-block;
	width: auto;
	float: none;
	display: inline-block;
}
.team article .social {
	margin-top: 30px;
}
.team article .social li a {
	color: #333333;
	padding: 0 5px;
	background: none;
	font-size: 14px;
	width: auto;
}
.team article .social li a:hover {
	color: #1193d4;
}
.team-list article {
	text-align: left;
	margin-bottom: 50px;
}
.team-list article h5 {
	margin-top: 0px;
}
.team-filter {
	margin-bottom: 50px;
}
.team-filter li {
	display: inline-block;
	width:auto !important;
}
.team-filter li a {
	display: inline-block;
	padding: 5px 10px;
	text-transform: uppercase;
	font-size: 12px;
	color: #cccccc;
	text-transform: uppercase;
	border-radius: 4px;
}
.team-filter li a:hover {
	background: #1193d4;
	color: #fff;
}
.team-filter li a.active {
	background: #1193d4;
	color: #fff;
}
.add img{
	width:auto;
}
/*=======================================================
      COUNTER
========================================================*/
.counter {
	background: #1193d4;
	text-align: center;
}
.counter .number {
	font-size: 60px;
	color: #fff;
	font-weight: bold;
}
.counter li {
	position: relative;
	border-right: 1px solid rgba(255,255,255,0.3);
	padding-top: 30px;
	padding-bottom: 30px;
}
.counter .count:before {
	content: "";
	position: absolute;
	height: 11px;
	width: 11px;
	background: #59b4e1;
	right: -6px;
	top: 50%;
	margin-top: -5px;
}
.counter li:nth-last-child(1){
	border:none;
}
.counter li:nth-last-child(1) .count:before{
	display:none;
}
.counter h5 {
	font-size: 18px;
}
/*=======================================================
      CLIENTS
========================================================*/
.clients {
	background: url(../../../assets/images/bg/client-bg.jpg) fixed no-repeat;
	background-size: cover;
}
.clients .col-5 li {
	text-align: center;
	float: left;
	width: 20%;
	padding: 0 5px;
}
.clients li a {
	border-left: none;
	background: #fff;
	display: inline-block;
	width: 100%;
	padding: 30px 0;
	border-bottom: 2px solid rgba(0,0,0,0.2);
}
.owl-dots {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin-top: 20px;
}
.owl-dots div {
	display: inline-block;
	margin: 0 4px;
}
.owl-dots span {
	height: 12px;
	width: 12px;
	border-radius: 50%;
	border: 1px solid #c1c1c1;
	display: inline-block;
}
.owl-dots div.active span {
	background: #fff;
}
.owl-dots div span:hover {
	background: #fff;
}
.clients .owl-nav {
	display: none;
}
.clients .owl-nav div.owl-prev {
	display: inline-block !important;
	color: #344248;
	margin: 0 15px;
	height: 62px;
	width: 32px;
	border-radius: 4px;
	border: 1px solid #ececec;
	line-height: 58px;
	text-align: center;
	position: absolute;
	top: 50%;
	left: -100px;
	margin-top: -30px;
}
.clients .owl-nav div.owl-next {
	display: inline-block !important;
	color: #344248;
	margin: 0 15px;
	height: 62px;
	width: 32px;
	border-radius: 4px;
	border: 1px solid #ececec;
	line-height: 58px;
	text-align: center;
	position: absolute;
	top: 50%;
	right: -100px;
	margin-top: -30px;
}
.clients .owl-nav div.owl-next:hover {
	background: #344248;
	border-color: #344248;
	color: #fff;
}
.clients .owl-nav div.owl-prev:hover {
	background: #344248;
	border-color: #344248;
	color: #fff;
}
/*=======================================================
      FOOTER
========================================================*/
footer {
	background: #343434;
	padding: 50px 0;
}
footer .about-foot {
	margin-top: 20px;
	background: url(../../../assets/images/footer-map.jpg) center center no-repeat;
}
footer .about-foot i {
	margin-right: 10px;
}
footer h6 {
	color: #fff;
	font-size: 14px;
	margin-top: 0px;
	margin-bottom: 30px;
}
footer p {
	color: #999999;
	font-weight: 600;
}
footer .tweet a {
	color: #28a9e2;
}
footer .tweet li p {
	font-size: 13px;
	margin: 0px;
	line-height: 18px;
}
footer .tweet li {
	padding-left: 20px;
	margin-bottom: 10px;
}
footer .tweet li:before {
	content: "\f099";
	font-family: FontAwesome;
	padding: 0px;
	color: #55b0ed;
	float: left;
	position: absolute;
	left: 0px;
	font-size: 14px;
	padding: 5px 15px;
	font-weight: 100;
}
footer .tweet span {
	text-transform: uppercase;
	color: #b2b2b2;
	font-size: 12px;
}
footer .photo-steam {
	margin: 0 -5px;
}
footer .photo-steam li {
	padding: 0 5px;
	float: left;
	width: 33.3333%;
	margin-bottom: 10px;
}
footer .photo-steam li a {
	display: inline-block;
	width: 100%;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}
footer .photo-steam li a:hover {
	opacity: 0.5;
}
.tags {
	display: inline-block;
	width: 100%;
}
.tags li {
	display: inline-block;
	float: left;
	margin-right: 5px;
	margin-bottom: 7px;
}
.tags li a {
	display: inline-block;
	padding: 5px 8px;
	background: #1193d4;
	border: none !important;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
}
.sm-tags {
	display: inline-block;
	padding: 5px 8px;
	background: #1193d4;
	border: none !important;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
}
a.sm-tags:hover {
	background: #1a1a1a;
	color: #fff;
}
.tags.dall li a {
	background: #cccccc;
}
.tags li a:hover {
	background: #1a1a1a;
}
.rights {
	padding: 15px 0;
	background: #1a1a1a;
}
.rights p {
	color: #fff;
	font-weight: 700;
	margin: 0px;
}
.rights a{
	color:#999999;
	margin-left:30px;
}
.rights a:hover{
	color:#fff;
}
.chart-page .payment_steps {
	margin: 30px 0;
	text-align: center;
}
.chart-page .payment_steps i {
	font-size: 24px;
	border: 1px solid #ebebeb;
	height: 73px;
	width: 73px;
	border-radius: 50%;
	line-height: 71px;
	position: relative;
	color: #b8b8b8;
	margin-bottom: 20px;
}
.chart-page .payment_steps h6:before {
	content: "";
	background: #ebebeb;
	height: 18px;
	width: 2px;
	position: absolute;
	top: 85px;
	left: 0px;
	right: 0px;
	margin: 0 auto;
}
.chart-page .payment_steps h6 {
	font-size: 12px;
	margin: 20px 0;
	font-weight: normal;
	color: #272727;
}
.chart-page .payment_steps .current i {
	color: #af5875;
}
.chart-page .payment_steps .current h6 {
	color: #af5875;
}
.chart-page .shopping-cart {
}
.shopping-cart .item-img {
	width: 70px;
	display: inline-block;
}
.shopping-cart img {
	width: 100%;
}
.shopping-cart li {
	position: relative;
}
.shopping-cart li span {
	color: #747474;
}
.shopping-cart li h6 {
	color: #747474;
}
.shopping-cart li input {
	display: inline-block;
	width: 100%;
}
.shopping-cart li {
	font-size: 12px;
	color: #af5875;
	padding-left: 0px;
	line-height: 50px;
}
.shopping-cart {
	border: 1px solid #e6e6e6;
	display: inline-block;
	width: 100%;
	border-bottom: none;
}
.shopping-cart ul {
	position: relative;
}
.shopping-cart li input {
	border: 1px solid #ebebeb;
	width: 60px;
	height: 44px;
	float: left;
	line-height: 44px;
	display: inline-block;
	text-align: center;
	margin-top: 10px;
}
.shopping-cart .remove-pro {
	position: absolute;
	left: 15px;
	margin-top: 10px;
}
.shopping-cart li .remove-pro a {
	color: #272727;
	font-size: 16px;
	position: absolute;
	left: 0px;
}
.shopping-cart .cart-details {
}
.shopping-cart .cart-details {
	padding-left: 50px;
	padding-top: 15px;
	padding-bottom: 10px;
	display: inline-block;
	width: 100%;
	margin: 0px;
}
.shopping-cart .capun input {
	width: 60%;
	text-align: left;
	padding: 0 20px;
}
.shopping-cart .cart-details {
	float: left;
	border-bottom: 1px solid #e6e6e6;
}
.shopping-cart .cart-details h6 {
	font-weight: normal;
	font-size: 14px;
	text-transform: none;
}
.shopping-cart .cart-head {
	border-bottom: 1px solid #e6e6e6;
	display: inline-block;
	width: 100%;
	background: #f7f7f7;
	float: left;
	padding-top: 15px;
	padding-bottom: 10px;
	padding-left: 50px;
}
.shopping-cart .cart-head h6 {
	margin: 0px;
	font-size: 12px;
}
.shopping-cart .btn-sec {
	display: inline-block;
	width: 100%;
}
.shopping-cart .btn-sec .btn {
	padding: 15px 30px;
	float: left;
	font-size: 12px;
	margin-right: 20px;
}
.shopping-cart .btn-sec .btn i {
	margin-right: 10px;
	font-size: 16px;
}
.shopping-cart .btn.right-btn {
	float: right;
	margin-right: 0px;
}
.shopping-cart .cart-ship-info {
	text-align: left;
	margin-top: 50px;
}
.shopping-cart .cart-ship-info h6 {
	margin-top: 0px;
	margin-bottom: 30px;
	font-weight: normal;
	padding-left: 15px;
}
.shopping-cart .cart-ship-info h6:before {
	content: '';
	height: 6px;
	width: 6px;
	border: 1px solid #000;
	position: absolute;
	top: 5px;
	left: 15px;
	-ms-transform: rotate(45deg); /* IE 9 */
	-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
	transform: rotate(45deg);
}
.shopping-cart .cart-ship-info input {
	border: 1px solid #ebebeb;
	width: 60px;
	height: 44px;
	float: left;
	width: 100%;
	font-size: 11px;
	padding: 0 10px;
	letter-spacing: 1px;
	display: inline-block;
	font-weight: normal;
	text-align: left;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.shopping-cart .cart-ship-info button {
}
.shopping-cart .bootstrap-select .btn {
	margin-top: 10px;
	background: #fff !important;
	border: 1px solid #ebebeb;
	color: #b8b8b8;
	font-size: 10px;
	letter-spacing: 1px;
}
.cart-total {
	padding-left: 100px;
}
.cart-total h5 {
	margin-bottom: 50px;
}
.cart-total .btn {
	width: 100%;
}
.cart-total li span {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}
.cart-total li {
	padding-bottom: 20px;
	margin-bottom: 20px !important;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid #e6e6e6;
}
/*=======================================================
			PRODUCTS
========================================================*/
.products {
}
.products article {
	background: #f7f7f7;
	border-bottom: 2px solid #e6e6e6;
	padding-bottom: 30px;
	text-align: center;
	margin-bottom: 30px;
	transition:  all ease .4s;
	border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}
.products article .item-img {
	position: relative;
	overflow: hidden;
}
.products article .item-img .contnt-img {
	display: block;
    padding: 20px;
    background: #fff;
}
.products article:hover {
	border-left-color: #dedede;
	border-right-color: #dedede;
}
.products article:hover .item-img img {
	transform: scale(1.1);
}
.products article img {
	width: 100%;
}
.products article span {
	display: inline-block;
	width: 100%;
	color: #666666;
	margin-top: 10px;
	margin-bottom: 5px;
}
.products article a.tittle {
	color: #666666;
	margin-top: 10px;
	display: inline-block;
	width: 100%;
	margin-bottom: 0px;
	font-weight: bold;
}
.products article .stars {
	margin-top: 10px;
	color: #ffcc00;
}
.stars i {
	color: #ffcc00;
}
.shop-single h4 {
	font-weight: normal;
	margin-top: 0px;
	margin-bottom: 30px;
}
.items-side-bar li {
	margin-bottom: 20px;
}
.items-side-bar li .stars {
	color: #ffcc00;
}
.items-side-bar li a {
	font-weight: bold;
	color: #666666;
}
.items-side-bar li span {
	font-weight: normal;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #999999;
}
.items-side-bar li .item-img {
	position: relative;
	overflow: hidden;
	height: 50px;
	width: 50px;
	margin-right: 10px;
	border-radius: 50%;
	display: inline-block;
}
.shop-single .flexslider {
	margin-bottom: 0px;
	border: none;
}
.shop-single span.priz {
	font-size: 30px;
	display: inline-block;
	width: 100%;
	margin: 15px 0;
	color: #666666;
}
.shop-single .qty {
	width: 50px;
	text-align: center;
	display: inline-block;
	margin-right: 30px;
	margin-top: 20px;
}
#carousel {
	width: 80%;
	margin: 0 auto;
	margin-top: 5px;
}
#carousel li {
	opacity: 0.2;
}
#carousel li.flex-active-slide {
	opacity: 1;
}
.shop-single .flex-direction-nav {
	display: none;
}
.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover{
	background:none;
}
.my-tabs.style-1 {
}
.nav-pills>li>a {
	border-radius: 0px;
	background: #1193d4;
	color: #fff;
	padding: 10px 30px;
}
.nav-pills>li>a:hover {
	color: #333;
}
.nav-pills>li.active>a {
	background: #f7f7f7 !important;
	color: #666666 !important;
}
.nav-pills>li.active>a:hover {
	background: #f7f7f7;
	color: #666666;
}
.my-tabs.style-1 .tab-content {
	background: #f7f7f7;
	padding: 20px 40px;
	border-bottom:2px solid #e6e6e6;
}
.my-tabs.style-1 .tab-content h5 {
	text-transform: none;
	font-weight: normal;
	margin-bottom: 20px;
}
.instagram-feed{
	margin:0 -2.5px;
}
.instagram-feed li{
	padding:0 2.5px;
	float:left;
	width:20%;
}
.instagram-feed li a{
}
.instagram-feed li a:hover{
	opacity:0.8;
}
.inst-feed{
	display:inline-block;
	width:100%;
}
/*=======================================================
			List Style
========================================================*/
.list-w-icon {
}
.list-w-icon li .icon {
	height: 69px;
	width: 69px;
	text-align: center;
	line-height: 67px;
	border: 1px solid #909090;
	border-radius: 50%;
	display: inline-block;
	margin-right: 20px;
	font-size: 24px;
}
.list-w-icon li {
	vertical-align: middle;
	margin-bottom: 30px;
}
.list-w-icon li h6 {
	display: inline-block;
}
.list-w-bullets li {
	padding: 4.5px 0;
	line-height:35px;
	padding-left: 40px;
}
.list-w-bullets.check li:before {
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	background: #eeb013;
	color: #fff;
	position: absolute;
	float: left;
	left: 0px;
	margin-top: 0px;
	font-size: 10px;
	border-radius: 50%;
	content: "\f00c";
	font-family: 'FontAwesome';
}
.list-w-bullets li:before {
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	background: #909090;
	color: #fff;
	position: absolute;
	float: left;
	left: 0px;
	margin-top: 5px;
	padding-left: 2px;
	font-size: 12px;
	border-radius: 50%;
	content: "\f105";
	font-family: 'FontAwesome';
}
.list-w-bullets p {
	text-transform: none;
	font-size: 12px;
	margin-bottom: 0px;
}
.list-w-bullets.check li {
	padding: 15px 0;
	padding-left: 40px;
}
.list-w-bullets p {
	text-transform: none;
	font-size: 12px;
	margin-bottom: 0px;
}
.list-style p {
	text-transform: none;
	font-size: 12px;
}
.list-heading {
	border-top: 1px solid #ececec;
	border-bottom: 1px solid #ececec;
	padding: 20px 0;
	margin-bottom: 20px;
}
.list-heading h6 {
	font-weight: normal;
	letter-spacing: 1px;
	margin: 0px;
}
.list-heading i {
	margin-right: 10px;
}
.list-w-icon-small li i {
	color: #999999;
	margin-right: 10px;
	font-size: 16px;
}
.list-w-number {
	padding-left: 15px;
	font-size: 12px;
}
.list-w-number li {
	list-style: decimal !important;
}


/*=======================================================
			CONTACT FORM
========================================================*/
.revenues {
}
.side-heading {
	font-size: 20px;
	font-weight: normal;
	margin-top: 0px;
	text-transform: none;
	padding-bottom: 10px;
	letter-spacing: 0px;
}
.side-bar-revenues {
}
.head a {
	color: #666666;
	display: inline-block;
	width: 100%;
}
.head {
	background: #f2f2f2;
	padding: 20px 20px;
	text-transform: none;
	color: #666666;
	font-weight: normal;
	margin: 0px;
	height: 56px;
	margin-top: 5px;
}
.side-bar-revenues .well {
	box-shadow: none;
	border: none;
	margin: 0px;
	border-radius: 0px;
	padding: 15px 25px;
}
.side-bar-revenues .well li a {
	font-size: 16px;
	font-weight: 600;
}
.cart-histori {
	min-width: 100%;
	height: 400px;
}
.broc {
	position: relative;
	overflow: hidden;
	border-radius: 0px 20px 20px 20px;
	margin-bottom: 30px;
}
.broc .icon-down {
	height: 35px;
	width: 35px;
	position: absolute;
	top: 20px;
	right: 20px;
	text-align: center;
	line-height: 35px;
	background: #fff;
	color: #d0d0d0;
	font-size: 16px;
}
.broc .icon-file {
	position: absolute;
	bottom: 20px;
	left: 20px;
	color: #fff;
}
.books article {
	background: -moz-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(240,240,240,1) 0%, rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(240,240,240,1) 0%, rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f0f0', endColorstr='#00ffffff', GradientType=0 ); /* IE6-9 */
	margin-bottom: 30px;
}
.books {
	margin-top: 50px;
}
.books .btn {
	padding-left: 10px;
}
.books .btn i {
	margin-left: 10px;
}
.books h3 {
	margin-bottom: 0px;
	margin-top: 30px;
	font-size: 30px;
}
.books h6 {
	margin-top: 0px;
	text-transform: none;
}
.book-1 h3 {
	color: #ff9900;
}
.book-2 h3 {
	color: #cc00ff;
}
.book-3 h3 {
	color: #33cc33;
}
.book-4 h3 {
	color: #3399cc;
}
.book-5 h3 {
	color: #ff6666;
}
.book-6 h3 {
	color: #996633;
}
.revenues .portfolio .cbp-item {
	padding: 2.5px;
}
.revenues .portfolio .bottom-detail {
	width: 90%;
}
.story {
	background: #f7f7f7;
	border-bottom: 2px solid #e6e6e6;
	margin-bottom: 40px;
}
.story article span {
	display: inline-block;
	width: 100%;
	color: #a8a8a8;
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 10px;
	text-transform: uppercase;
}
.story article {
	padding: 40px 20px;
	padding-right: 70px;
}
.story article h5 {
	font-weight: normal;
	line-height: 22px;
	letter-spacing: 1px;
	margin-bottom: 20px;
}
/*=======================================================
		CONTACT FORM
========================================================*/
.contact-info {
	background: url(../../../assets/images/bg/contact-info-bg.jpg) no-repeat;
}
.contact-info li h5 {
	font-size: 18px;
	font-weight: normal;
	margin-bottom: 30px;
}
.contact-info li article {
	background: rgba(255,255,255,0.8);
	padding: 50px;
	text-align: center;
}
.contact-info li p {
	margin: 0px;
}
.contact-info li span {
	font-weight: bold;
	display: inline-block;
	width: 100%;
	color: #666666;
	margin-bottom: 5px;
}
#map {
	height: 380px;
}
.project-info li span {
	font-weight: normal;
}
.project-info li {
	margin-bottom: 20px;
}
.project-info p {
	font-weight: bold;
	margin-bottom: 0px;
	text-transform: uppercase;
}
.center-auto {
	float: none;
	margin: 0 auto;
}
.contact-form .error {
	border: 1px solid red;
}
.contact-form .error:before {
	content: "\f071";
	font-family: 'FontAwesome';
	position: absolute;
	color: red;
}
.success-msg {
	background: #fff;
	border: 1px solid #ABD0A8;
	color: #589051;
	margin-bottom: 30px;
	width: 100%;
	display: inline-block;
	text-align: center;
	padding: 11px 16px;
	border-radius: 4px;
	display: none;
}
.success-msg i {
	padding: 10px;
	border-radius: 50%;
	border: 1px solid #ABD0A8;
	margin-right: 10px;
}
.contact-form label {
	display: inline-block;
	width: 100%;
	font-weight: normal;
	font-size: 13px;
	margin-bottom: 20px;
	color: #999999;
	font-family: 'Open Sans', sans-serif;
}
.contact-form label input {
	border: 1px solid #e6e6e6;
	height: 40px;
	padding: 0 20px;
	border-radius: 0px;
	width: 100%;
	color: #999999;
	border-radius: 4px;
	background: #fff;
	box-shadow: none !important;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.contact-form textarea {
	display: inline-block;
	width: 100%;
	color: #999999;
	position: relative;
	padding: 10px 20px;
	border: 1px solid #ececec;
	height: 200px;
	border-radius: 4px;
	background: #fff;
	box-shadow: none !important;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.contact-form input:focus {
	border: 1px solid #1193d4 !important;
}
.contact-form textarea:focus {
	border: 1px solid #1193d4 !important;
}
/*=======================================================
			ACCORDION
========================================================*/
#accordion {
}
#accordion .panel-default > .panel-heading .panel-title a:before {
	content: "\f077";
	font-family: FontAwesome;
	padding: 0px;
	color: #fff;
	float: right;
	padding: 0px 15px;
	display: none;
}
#accordion .panel-default > .panel-heading .panel-title a.collapsed:before {
	content: "\f078";
	color: #222222;
	font-family: FontAwesome;
	display: none;
}
#accordion .icon-accor {
	background: #344248;
	color: #fff;
	display: inline-block;
	height: 49px;
	width: 49px;
	font-size: 18px;
	text-align: center;
	line-height: 45px;
	margin-right: 15px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
#accordion .panel-default {
	border: none;
}
#accordion .panel-default > .panel-heading {
	background: none;
	padding: 0px;
	color: #333333;
	border-radius: 0px;
	overflow: hidden;
	border: none !important;
}
#accordion .panel-default > .panel-heading a {
	text-decoration: none;
	font-size: 12px;
	width: 100%;
	font-weight: 400;
	padding: 0px;
	display: inline-block;
	padding-left: 0px;
	border: none;
}
#accordion .panel {
	background: none;
	box-shadow: none;
}
#accordion .panel-group .panel {
	border-radius: 0px;
}
#accordion .panel-body {
	border: none;
	padding: 0px;
}
#accordion.style-2 .panel-default > .panel-heading a {
	font-weight: bold;
	font-size: 14px;
	color: #555555;
}
.job-post-page {
	padding-bottom: 0px;
}
.job-post-page .star-save {
	margin-top: 35px;
	margin-right: 35px;
}
.job-post-page .job-tittle {
	margin-bottom: 10px;
}
.side-tittle {
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 20px;
	margin-top: 0px;
	text-transform: none;
	border-bottom: 1px dotted #cccccc;
	padding-bottom: 15px;
	margin-bottom: 20px;
	letter-spacing: 0px;
}
.cate li {
	padding-left: 15px;
	position: relative;
	line-height: 42px;
}
.cate li:before {
	content: "\f054 ";
	position: absolute;
	left: 0px;
	font-size: 10px;
	color: #9a9a9a;
	font-family: 'FontAwesome';
}
.cate li a {
	color: #333333;
	font-family: 'Open Sans', sans-serif;
}
.cate li a:hover {
	color: #1193d4;
}
/* TIMELINE */
.history {
}
.history .heading > h4 {
	margin-top: 0;
}
.history #timeline {
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	float: none;
}
.history #timeline #dates {
	margin-top: 40px;
	height: 80px;
}
.history #timeline #dates:before {
	position: absolute;
	top: 34px;
	left: 0px;
	right: 15px;
	height: 3px;
	background-color: #333333;
	content: "";
	width: 100%;
}
.history #timeline #dates:after {
	position: absolute;
	top: 30px;
	left: 0px;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: #333333;
	content: "";
}
.history #timeline #dates li {
	position: relative;
	width: 100px;
	float: left;
}
.history #timeline #dates li a {
	position: relative;
	margin-top: 15px;
	display: inline-block;
	width: 30px;
	color: #000000;
	font-size: 10px;
	text-transform: none;
}
.history #timeline #dates li a span {
	position: absolute;
	top: -50px;
	left: -40px;
}
.history #timeline #dates li a:before {
	position: absolute;
	top: -29px;
	background-color: #333333;
	width: 10px;
	height: 10px;
	content: "";
	border-radius: 50%;
	margin-top: 4px;
}
.history #timeline #dates li a {
}
.history #timeline #dates li a:after {
	position: absolute;
	top: -34px;
	background-color: #333333;
	width: 2px;
	height: 10px;
	content: "";
	border-radius: 50%;
	margin-top: 4px;
	left: -30px;
	margin-left: 0px;
}
.history #timeline #dates li a.selected:before {
	top: -33px;
	width: 16px;
	height: 16px;
	background-color: #1193d4;
}
.history #timeline #issues {
	width: 100%;
	height: 285px;
	overflow: hidden;
}
.history #timeline #issues li {
	float: left;
	width: 920px;
}
.history #timeline #issues li .history-thumbnail {
	height: 285px;
	overflow: hidden;
}
.history #timeline #issues li .history-thumbnail img {
	width: 100%;
	height: 100%;
}
@media (max-width: 768px) {
.history #timeline #issues li .history-thumbnail {
	text-align: center;
	height: auto;
}
.history #timeline #issues li .history-thumbnail img {
	width: auto;
	max-width: 100%;
	height: auto;
}
}
.history #timeline #issues li .history-content {
	padding-right: 80px;
	margin-top: 45px;
}
.history #timeline #issues li .history-content .title {
	margin: 5px 0 20px 0;
	font-size: 18px;
}
@media (max-width: 1200px) {
.history #timeline #issues li {
	width: 915px;
}
}
@media (max-width: 992px) {
.history #timeline #issues li {
	width: 705px;
}
}
@media (max-width: 992px) {
.history #timeline #issues li {
	width: 705px;
}
}
.history #timeline #prev {
	position: absolute;
	left: 15px;
	top: 50%;
}
.history #timeline #prev i {
	color: #666666;
	font-size: 64px;
}
.history #timeline #next {
	position: absolute;
	right: 15px;
	top: 50%;
}
.history #timeline #next i {
	color: #666666;
	font-size: 64px;
}
@media (max-width: 768px) {
}
/*=======================================================
			PAPULAR POST
========================================================*/
.papu-post {
}
.papu-post .media-left {
	margin-right: 0px;
	padding-right: 20px;
}
.papu-post .media-left span {
	background: #333333;
	color: #fff;
	display: inline-block;
	width: 100%;
	padding: 3px 0;
	text-align: center;
	margin-top: 0px;
	float: left;
}
.papu-post .media-left img {
	width: 100%;
}
.papu-post .media-left a {
	width: 60px;
	display: inline-block;
}
.papu-post span {
	color: #ffbe00;
	letter-spacing: 0px;
	font-size: 11px;
}
.papu-post li {
	padding: 10px 0;
	margin: 0px;
}
.papu-post .media-heading {
	display: inline-block;
	width: 100%;
	margin-top: 0px;
	font-size: 18px;
	letter-spacing: 0px;
}
.search input {
	display: inline-block;
	width: 100%;
	height: 35px;
	background: #fff;
	border: 1px solid #ebebeb;
}
.search button {
	float: right;
	margin-top: 10px;
	line-height: 35px;
	margin-right: 0px;
	margin-top: -35px;
	position: relative;
	z-index: 1;
	border-radius: 50%;
	border: none;
	color: #313f46;
	width: 35px;
	height: 35px;
	font-size: 16px;
	background: none;
}
.comments .media-list li {
	margin-bottom: 40px;
}
.comments .media-list li .media-left a {
	display: inline-block;
	width: 100px;
	margin-right: 10px;
}
.comments .media-heading {
	font-weight: normal;
	text-transform: none;
	color: #666666;
	font-size: 14px;
	margin-top: 15px;
	margin-bottom: 15px;
}
.comments .media-heading a {
	color: #1193d4;
}
.comments .media-heading span {
	color: #b3b3b3;
}
.comments form label {
	text-transform: uppercase;
	font-weight: normal;
	margin-top: 20px;
	display: inline-block;
	width: 100%;
	font-size: 12px;
}
.comments form .form-control {
	display: inline-block;
	width: 100%;
	height: 40px;
	margin-top: 5px;
	border-radius: 0px;
	box-shadow: none;
	background: none;
	border: 1px solid #e8e8e8;
}
.comments form textarea.form-control {
	display: inline-block;
	width: 100%;
	height: 150px;
}
.promo {
	background: url(../../../assets/images/bg/promo-bg.jpg) no-repeat;
	background-size: cover;
}
.promo .overlay {
	background: rgba(52,66,72,0.95);
	padding: 100px 0;
}
.promo .overlay:before {
	content: "";
	position: absolute;
	right: 0px;
	left: 100%;
	width: 100%;
	height: 100%;
	top: 0px;
	background: rgba(52,66,72,0.95);
}
.promo h4 {
	color: #fff;
	font-weight: normal;
	display: inline-block;
	margin: 0px;
	margin-bottom: 15px;
}
.promo .overlay:after {
	position: absolute;
	top: 0px;
	left: -340px;
	width: 0;
	content: "";
	height: 0;
	border-bottom: 340px solid rgba(52,66,72,0.95);
	border-left: 340px solid transparent;
}
.promo p {
	color: #fff;
	margin-bottom: 30px;
}
/*=======================================================
			Alert Boxes
========================================================*/
.alert {
	color: #fff;
	text-transform: uppercase;
	font-size: 11px;
	border: none;
	border-radius: 0px;
	padding: 20px 30px;
	position: relative;
	font-weight: bold;
	letter-spacing: 1.5px;
}
.alert .close {
	color: #fff;
	opacity: 1;
	font-size: 14px;
	top: inherit;
	right: 30px;
	    margin-top: 2px;
	position: absolute;
}
.alert .fa-2x {
	font-size: 16px;
	margin-right: 20px;
}
.alert.alert-success {
	background: #2fba87;
}
.alert.alert-info {
	background: #6dcff6;
}
.alert.alert-danger {
	background: #ffc11b;
}
.alert.alert-warning {
	background: #eb5020;
}
.alert.alert-success.style-2 {
	background: #e7f6f0;
	border: 1px solid #2fba87;
	color: #2fba87;
}
.alert.alert-success.style-2 i {
	color: #2fba87;
}
.alert.alert-info.style-2 {
	background: #effaff;
	border: 1px solid #6dcff6;
	color: #6dcff6;
}
.alert.alert-info.style-2 i {
	color: #6dcff6;
}
.alert.alert-danger.style-2 {
	background: #fffbf0;
	border: 1px solid #ffc11b;
	color: #ffc11b;
}
.alert.alert-danger.style-2 i {
	color: #ffc11b;
}
.alert.alert-warning.style-2 {
	background: #fff0ec;
	border: 1px solid #eb5020;
	color: #eb5020;
}
.alert.alert-warning.style-2 i {
	color: #eb5020;
}
.alert.alert-large {
	font-size: 14px;
	letter-spacing: 1px;
}
.alert.alert-large .fa-2x {
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 50px;
	color: #141414;
	border-radius: 50%;
	background: #fff;
	float: left;
	margin-top: 10px;
	font-size: 24px;
}
.alert.alert-large p {
	color: #fff;
	font-size: 11px;
	text-transform: none;
	line-height: 22px;
	margin-top: 10px;
}
.alert.alert-large .close {
	top: 20px;
	right: 20px;
}
.alert.x-large {
	font-size: 14px;
}
.alert.x-large .fa-border {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	line-height: 38px;
	text-align: center;
	border-radius: 50%;
	font-size: 18px;
	padding-left: 8px;
	margin-right: 20px;
}
.alert.alert-success.x-large {
	border: 2px solid #2fba87;
}
.alert.alert-info.x-large {
	border: 2px solid #6dcff6;
}
.alert.alert-danger.x-large {
	border: 2px solid #ffc11b;
}
.alert.alert-warning.x-large {
	border: 2px solid #eb5020;
}
.alert.alert-success.x-large .fa-border {
	border: 2px solid #2fba87;
}
.alert.alert-info.x-large .fa-border {
	border: 2px solid #6dcff6;
}
.alert.alert-danger.x-large .fa-border {
	border: 2px solid #ffc11b;
}
.alert.alert-warning.x-large .fa-border {
	border: 2px solid #eb5020;
}
#wrap.boxed-layout {
	max-width: 1280px;
	margin: 0 auto;
	background: #fff;
	box-shadow: 0 0 20px rgba(0,0,0,0.5);
}
#wrap.boxed-layout .is-sticky .sticky {
	left: 0px;
}
.bg1-body {
	background: url(../../../assets/images/bg/boxed-bg.jpg) fixed no-repeat;
	background-size: cover;
}
#wrap.boxed-layout .contact.style-2 .contact-right {
	padding-right: 20px;
}
#wrap.boxed-layout .more-feature ul li {
	width: 33.3333%;
}
#wrap.boxed-layout .more-feature ul li:nth-last-child(1) {
	width: 50%;
}
#wrap.boxed-layout .more-feature ul li:nth-last-child(2) {
	width: 50%;
}
#wrap.boxed-layout .more-feature ul li h6 {
	width: 100%;
}
#wrap.boxed-layout .content-we-are {
	padding-right: 20px;
	padding-left: 20px;
}
#wrap.boxed-layout .sticky-wrapper .sticky {
	max-width: 1280px;
	width: auto;
	margin: 0 auto;
	left: 0px;
	right: 0px;
}
.bg1-body .colio{
    max-width: 1280px;
    width: 100% !important;
    margin: 0 auto;
    left: 0!important;
    right: 0 !important;
}
#wrap.boxed-layout header .sticky {
	padding-left: 20px;
	padding-right: 20px;
}
#wrap.boxed-layout .portfolio article:hover .bottom-detail{
	width:100%;
	padding-right:50px;
}
#wrap.boxed-layout .portfolio .bottom-detail h3{
	font-size:20px;
}
/*=======================================================
			GO TO TOP
========================================================*/
.dark-version .footer {
	background: #252525;
}
.dark-version .portfolio-in:before {
	background: #252525;
}
.dark-version section {
	background: #202020;
}
.dark-version h1 {
	color: #b3b3b3;
}
.dark-version h2 {
	color: #b3b3b3;
}
.dark-version h3 {
	color: #b3b3b3;
}
.dark-version h4 {
	color: #b3b3b3;
}
.dark-version h5 {
	color: #b3b3b3;
}
.dark-version h6 {
	color: #b3b3b3;
}
.dark-version .post-tittle {
	color: #b3b3b3;
}
.dark-version .blog-post-home .date .huge {
	color: #b3b3b3;
}
.dark-version p {
	color: #959595;
}
.dark-version .heading-block hr {
	background: #b3b3b3;
}
.dark-version .post-content hr {
	background: #b3b3b3;
}
.dark-version .more-feature ul li:hover {
	background: #000;
}
.dark-version .more-feature ul li article {
	border-color: #000;
}
.dark-version .light-gray-bg {
	background: #252525;
}
.dark-version .blog:before {
	background: #252525;
}
.dark-version {
	color: #959595;
}
.dark-version .sub-bnr h3 {
	color: #fff;
}
.dark-version .sub-bnr p {
	color: #fff;
}
.dark-version hr {
	border-color: #4d4d4d;
}
.dark-version .paging li a {
	color: #959595;
}
.dark-version .filter-style-1 .cbp-filter-item:hover:before {
	color: #202020;
}
.dark-version .filter-style-1 .cbp-filter-item.cbp-filter-item-active:before {
	color: #202020;
}
.dark-version .filter-style-1 {
	border: 1px solid rgba(255,255,255,0.1);
}
.dark-version .filter-style-1 .cbp-filter-item {
	border-right: 1px solid rgba(255,255,255,0.1) !important;
}
.dark-version .filter-style-1 .cbp-filter-item:hover:before {
	text-shadow: 0 1px 0px rgba(255,255,255,0.1) !important;
}
/*.dark-version .cbp-l-filters-buttonCenter .cbp-filter-item {
	background: #202020;
}*/
.dark-version .filter-style-1 .cbp-filter-item.cbp-filter-item-active:before {
	text-shadow: 0 1px 0px rgba(255,255,255,0.1) !important;
}
.dark-version .filter-style-2 .cbp-filter-item {
	background: #333333;
}
.dark-version .filter-style-2 .cbp-filter-item {
	border-right: 1px solid rgba(255,255,255,0.1) !important;
}
.dark-version .filter-style-2 .cbp-filter-item.cbp-filter-item-active {
	background: #f6772d;
	color: #fff;
}
.dark-version .filter-style-2 .cbp-filter-item:hover {
	background: #f6772d;
	color: #fff;
}
.dark-version .portfolio .port-item {
	background: none;
}
.dark-version .filter-style-4 .cbp-filter-item.cbp-filter-item-active {
	color: #fff;
}
.dark-version .portfolio .cbp-item .port-detail h6 a {
	color: #b3b3b3 !important;
}
.dark-version .portfolio .cbp-item .port-detail h6 a:hover {
	color: #f6772c !important;
}
.dark-version .filter-style-4 {
	border-bottom: 1px solid rgba(255,255,255,0.2);
}
.dark-version .cbp-l-filters-buttonCenter .cbp-filter-item:hover {
	color: #fff;
}
.dark-version .portfolio-details .project-info li p {
	color: #b3b3b3;
}
.dark-version .filter-style-5 {
	border: 1px solid rgba(255,255,255,0.2);
}
.dark-version .filter-style-5 .cbp-filter-item {
	background: rgba(255,255,255,0.02);
}
.dark-version .filter-style-5 .cbp-filter-item:hover {
	background: #f6772c;
	color: #fff;
}
.dark-version .filter-style-5 .cbp-filter-item.cbp-filter-item-active {
	background: #f6772c;
	color: #fff;
}
.dark-version  .filter-style-4 .cbp-filter-item{
	background:none !important;
}
.dark-version .who-we-are{
	background:url(../../../assets/images/bg/bg-who-we-dark.jpg) fixed no-repeat;
}
.dark-version section.port-item-up{
	background:none;
}
.dark-version .portfolio .portfolio-in .port-item{
	background:rgba(255,255,255,0.05);
}